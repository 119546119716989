import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Button,
    Box,
    Flex,
    useToast,
    NumberInput,
    NumberInputField,
    VStack,
    HStack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
} from '@chakra-ui/react';
import { MdOutlineChevronRight } from 'react-icons/md';

const PredMpsEpidermis = () => {
    const [inputs, setInputs] = useState({
        cpg1: '',
        cpg2: '',
        cpg3: '',
        cpg4: '',
        cpg5: '',
        cpg6: '',
        cpg7: '',
        cpg8: '',
        cpg9: '',
        cpg10: '',
        cpg11: '',
        cpg12: '',
        cpg13: '',
        cpg14: '',
        cpg15: '',
        cpg16: '',
        cpg17: '',
        cpg18: '',
        cpg19: '',
        cpg20: '',
        cpg21: '',
    });

    const [results, setResults] = useState({
        mEpi: null,
    });

    const [showResults, setShowResults] = useState(false);
    const [warningMessage1, setWarningMessage1] = useState(false);
    const [warningMessage2, setWarningMessage2] = useState(false);

    const toast = useToast();

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = [
            'cpg1',
            'cpg2',
            'cpg3',
            'cpg4',
            'cpg5',
            'cpg6',
            'cpg7',
            'cpg8',
            'cpg9',
            'cpg10',
            'cpg11',
            'cpg12',
            'cpg13',
            'cpg14',
            'cpg15',
            'cpg16',
            'cpg17',
            'cpg18',
            'cpg19',
            'cpg20',
            'cpg21',
        ];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setInputs((prev) => ({ ...prev, [id]: value }));
    };

    const handleReset = () => {
        setInputs({});
        setResults({});
        setShowResults(false);
    };

    const tablerow = [
        { name: 'CpG01', var1: 'cpg1' },
        { name: 'CpG02', var1: 'cpg2' },
        { name: 'CpG03', var1: 'cpg3' },
        { name: 'CpG04', var1: 'cpg4' },
        { name: 'CpG05', var1: 'cpg5' },
        { name: 'CpG06', var1: 'cpg6' },
        { name: 'CpG07', var1: 'cpg7' },
        { name: 'CpG08', var1: 'cpg8' },
        { name: 'CpG09', var1: 'cpg9' },
        { name: 'CpG10', var1: 'cpg10' },
        { name: 'CpG11', var1: 'cpg11' },
        { name: 'CpG12', var1: 'cpg12' },
        { name: 'CpG13', var1: 'cpg13' },
        { name: 'CpG14', var1: 'cpg14' },
        { name: 'CpG15', var1: 'cpg15' },
        { name: 'CpG16', var1: 'cpg16' },
        { name: 'CpG17', var1: 'cpg17' },
        { name: 'CpG18', var1: 'cpg18' },
        { name: 'CpG19', var1: 'cpg19' },
        { name: 'CpG20', var1: 'cpg20' },
        { name: 'CpG21', var1: 'cpg21' },
    ];

    const handleCalculate = () => {
        const {
            cpg1,
            cpg2,
            cpg3,
            cpg4,
            cpg5,
            cpg6,
            cpg7,
            cpg8,
            cpg9,
            cpg10,
            cpg11,
            cpg12,
            cpg13,
            cpg14,
            cpg15,
            cpg16,
            cpg17,
            cpg18,
            cpg19,
            cpg20,
            cpg21,
        } = inputs;

        const isEmpty = [
            cpg1,
            cpg2,
            cpg3,
            cpg4,
            cpg5,
            cpg6,
            cpg7,
            cpg8,
            cpg9,
            cpg10,
            cpg11,
            cpg12,
            cpg13,
            cpg14,
            cpg15,
            cpg16,
            cpg17,
            cpg18,
            cpg19,
            cpg20,
            cpg21,
        ].some((val) => val === '');

        if (isEmpty) {
            setResults({
                mEpi: NaN,
            });
        } else {
            const mEpi =
                4.22294 +
                2.132074 * cpg1 +
                9.032906 * cpg2 +
                8.078809 * cpg3 +
                25.84197 * cpg4 +
                4.588226 * cpg5 +
                19.28089 * cpg6 +
                4.261869 * cpg7 +
                1.440763 * cpg8 +
                4.427093 * cpg9 +
                4.920393 * cpg10 +
                34.80794 * cpg11 +
                22.60009 * cpg12 +
                20.04374 * cpg13 +
                7.259228 * cpg14 +
                7.20245 * cpg15 +
                3.938722 * cpg16 +
                1.476395 * cpg17 +
                6.993089 * cpg18 +
                5.670816 * cpg19 +
                9.798238 * cpg20 +
                2.163972 * cpg21;

            setResults({
                mEpi: mEpi.toFixed(4),
            });
        }
        setShowResults(true);
    };

    return (
        <Box p={4}>
            {/* Header */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={3}
            >
                <VStack
                    spacing="7px"
                    align="left"
                    justifyContent="space-between"
                    mb={4}
                >
                    <Breadcrumb
                        fontSize="s"
                        spacing="6px"
                        separator={<MdOutlineChevronRight color="gray.500" />}
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink>MPS</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Epidermis</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading as="h1" size="lg">
                        Epidermis
                    </Heading>
                </VStack>
            </VStack>
            {/* Calculator */}
            <HStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                {/* Inputs & Methyl Results */}
                <Flex
                    bg="gray.20"
                    direction="row"
                    height="1600px"
                    width="600px"
                >
                    <Box flex="6.5" onPaste={handlePaste}>
                        {/* Table Left */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr borders="0">
                                    <Th>
                                        <Button
                                            colorScheme="gray"
                                            onClick={handleReset}
                                            m={2}
                                        >
                                            Reset All
                                        </Button>
                                    </Th>
                                    <Th>
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculate}
                                                m={2}
                                                w="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.name}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var1}
                                                    value={
                                                        inputs[item.var1] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var1,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Box>
                        {/* Table Right */}
                        <Table
                            colorScheme="blue"
                            bg="gray.20"
                            // variant="striped"
                        >
                            <Tbody backgroundColor="gray.20">
                                <Tr>
                                    <Td>
                                        <Box
                                            height="32px"
                                            whiteSpace="pre-line"
                                        >
                                            Estimated age (years)
                                        </Box>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Box height="40.5px" textAlign="center">
                                            {showResults ? results.mEpi : ''}
                                        </Box>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
            </HStack>
            <VStack></VStack>
        </Box>
    );
};

export default PredMpsEpidermis;
