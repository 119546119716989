'use client';

import {
    Box,
    Container,
    SimpleGrid,
    Stack,
    Text,
    Flex,
    Badge,
    useColorModeValue,
    Image,
} from '@chakra-ui/react';

const Logo = (props) => {
    return (
        <Image
            boxSize={{
                base: '170px',
                sm: '200px',
                md: '220px',
            }}
            objectFit="scale-down"
            height={{
                base: 10,
                sm: 20,
                md: 32,
            }}
            src="../images/snucmfm.png"
        />
    );
};

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

export default function Footer() {
    return (
        <Box
            color={useColorModeValue('gray.700', 'gray.200')}
            border="1px solid"
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            pb={10}
            px={10}
        >
            <Container as={Stack} maxW={'3xl'} py={10}>
                <SimpleGrid
                    columns={{ base: 1, sm: 1, md: 2 }}
                    spacing={{ base: 10, sm: 10, md: 0 }}
                >
                    <Stack align={'flex-start'}>
                        <ListHeader>Menu</ListHeader>

                        <Box as="a" href={'/'}>
                            Home
                        </Box>
                        <Box as="a" href={'research'}>
                            Research
                        </Box>
                        <Stack direction={'row'} align={'center'} spacing={2}>
                            <Box as="a" href={'portal/methyl-snapshot-bsb'}>
                                Portal
                            </Box>
                            <Badge ml="1">Updated</Badge>
                        </Stack>
                        <Box as="a" href={'updates'}>
                            Updates
                        </Box>
                        <Box as="a" href={'contact'}>
                            Contact Us
                        </Box>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Partners</ListHeader>
                        <SimpleGrid
                            columns={{ base: 1, sm: 2, md: 2 }}
                            spacing={{ base: 8, sm: 6, md: 2 }}
                        >
                            <Image
                                boxSize={{
                                    base: '170px',
                                    sm: '200px',
                                    md: '250px',
                                }}
                                objectFit="scale-down"
                                height={{
                                    base: 8,
                                    sm: 8,
                                    md: 16,
                                }}
                                src="../images/snu-p-500.png"
                                px={3}
                                align="left"
                            />
                            <Image
                                boxSize={{
                                    base: '170px',
                                    sm: '200px',
                                    md: '250px',
                                }}
                                objectFit="scale-down"
                                height={{
                                    base: 8,
                                    sm: 8,
                                    md: 16,
                                }}
                                src="../images/dna_link.png"
                                px={3}
                                align="left"
                            />
                            <Image
                                boxSize={{
                                    base: '170px',
                                    sm: '230px',
                                    md: '250px',
                                }}
                                objectFit="scale-down"
                                height={{
                                    base: 12,
                                    sm: 12,
                                    md: 16,
                                }}
                                src="../images/skku-p-500.jpg"
                                px={3}
                                align="left"
                            />
                            <Image
                                boxSize={{
                                    base: '170px',
                                    sm: '200px',
                                    md: '250px',
                                }}
                                objectFit="scale-down"
                                height={{
                                    base: 8,
                                    sm: 10,
                                    md: 16,
                                }}
                                src="../images/kribb-logo.png"
                                ObjectPosition="auto"
                                px={3}
                                align="left"
                            />
                        </SimpleGrid>
                    </Stack>
                </SimpleGrid>
            </Container>
            <Box py={1}>
                <Flex
                    align={'center'}
                    _before={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: useColorModeValue('gray.200', 'gray.700'),
                        flexGrow: 1,
                        mr: 8,
                    }}
                    _after={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: useColorModeValue('gray.200', 'gray.700'),
                        flexGrow: 1,
                        ml: 8,
                    }}
                >
                    <Logo />
                </Flex>
                <Text
                    pt={{
                        base: 5,
                        sm: 1,
                        md: 1,
                    }}
                    fontSize={'sm'}
                    textAlign={'center'}
                >
                    © SNUCM Department of Forensic Medicine. All rights
                    reserved.
                </Text>
            </Box>
        </Box>
    );
}
