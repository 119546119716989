import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Flex,
    Text,
    Container,
    Heading,
    VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

export default function SimpleAccordion() {
    return (
        <Flex minH={'60vh'} align={'center'} justify={'center'}>
            <VStack spacing={10} mb={5}>
                <Heading>Updates</Heading>
                <Container>
                    <Accordion allowMultiple width="100%" rounded="lg">
                        <AccordionItem>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                            >
                                <Text
                                    fontSize="md"
                                    whiteSpace="pre-line"
                                    px={2}
                                >
                                    서울대학교 의과대학 법의학교실 차세대
                                    법유전학 시스템 연구 홈페이지 오픈 {'\n'}-
                                    2023. 12. 05
                                </Text>
                                <ChevronDownIcon fontSize="24px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text
                                    color="gray.600"
                                    whiteSpace="pre-line"
                                    textAlign="center"
                                >
                                    2023년 12월 5일, 차세대 법유전학 시스템 연구
                                    홈페이지를 오픈합니다.{'\n'}연구 분야 소개,
                                    DNA 마커 정보 조회 및 연령 예측 기능 등을
                                    제공합니다.{'\n'}연구 성과 및 후속 기능들이
                                    지속 업데이트 될 예정입니다.{'\n'}많은
                                    이용과 관심 부탁드립니다. 감사합니다.
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Container>
            </VStack>
        </Flex>
    );
}
