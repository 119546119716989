import { Box, Button, Heading, Text, Stack } from '@chakra-ui/react';

export default function Protocols() {
    return (
        <Box pl={4} align="left">
            <Heading my="3">Protocols</Heading>
            <Text my="3">Click to download protocols</Text>
            <Box w="700px" align="left">
                <Stack direction="column" spacing={4} align="left">
                    <Button
                        as="a"
                        colorScheme="blue"
                        _hover={{ color: 'white', bg: '#2b6cb0' }}
                        height="50px"
                        variant="outline"
                        href="../documents/1_Guideline_for_forensic_research.pdf"
                    >
                        <Text>Guideline for forensic research .pdf</Text>
                    </Button>
                    <Button
                        as="a"
                        colorScheme="blue"
                        _hover={{ color: 'white', bg: '#2b6cb0' }}
                        height="50px"
                        variant="outline"
                        href="../documents/2_Body_fluid_identification_using_DNA_methylation.pdf"
                    >
                        <Box textAlign="left">
                            <Text>
                                Body fluid identification using DNA methylation
                                .pdf
                            </Text>
                        </Box>
                    </Button>
                    <Button
                        as="a"
                        colorScheme="blue"
                        _hover={{ color: 'white', bg: '#2b6cb0' }}
                        height="50px"
                        variant="outline"
                        href="../documents/3_Age-prediction-using-DNA-methylation-for-blood-saliva-buccal-cells.pdf"
                    >
                        <Text>
                            Age prediction using DNA methylation for blood,
                        </Text>
                        saliva &amp; buccal cells .pdf
                    </Button>
                    <Button
                        as="a"
                        colorScheme="blue"
                        _hover={{ color: 'white', bg: '#2b6cb0' }}
                        height="50px"
                        variant="outline"
                        href="../documents/4_Age_prediction_using_DNA_methylation_for_semen.pdf"
                    >
                        <Text>
                            Age prediction using DNA methylation for semen .pdf
                        </Text>
                    </Button>
                    <Button
                        as="a"
                        colorScheme="blue"
                        _hover={{ color: 'white', bg: '#2b6cb0' }}
                        height="50px"
                        variant="outline"
                        href="/documents/5_Age_prediction_using_sjTREC.pdf"
                    >
                        <Text>Age prediction using sjTREC .pdf</Text>
                    </Button>
                    <Button
                        as="a"
                        colorScheme="blue"
                        _hover={{ color: 'white', bg: '#2b6cb0' }}
                        height="50px"
                        variant="outline"
                        href="../documents/6_Ancestry_classification_using_Y-STRs.pdf"
                    >
                        <Text>Ancestry classification using Y-STRs .pdf</Text>
                    </Button>
                    <Button
                        as="a"
                        colorScheme="blue"
                        _hover={{ color: 'white', bg: '#2b6cb0' }}
                        height="50px"
                        variant="outline"
                        href="../documents/7_Prediction_of_alcohol_flushing_syndrome.pdf"
                    >
                        <Text>
                            Prediction of alcohol flushing syndrome .pdf
                        </Text>
                    </Button>
                    <Button
                        as="a"
                        colorScheme="blue"
                        _hover={{ color: 'white', bg: '#2b6cb0' }}
                        height="50px"
                        variant="outline"
                        href="../documents/8_Protocol_Organ_Tissue_identification_18CpGs.pdf"
                    >
                        <Text>
                            Protocol Organ Tissue identification 18CpGs .pdf
                        </Text>
                    </Button>
                    <Button
                        as="a"
                        colorScheme="blue"
                        _hover={{ color: 'white', bg: '#2b6cb0' }}
                        height="50px"
                        variant="outline"
                        href="../documents/9_Protocol_Semen_Y-CpGs.pdf"
                    >
                        <Text>Protocol Semen Y-CpGs .pdf</Text>
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}
