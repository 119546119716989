import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Button,
    Box,
    Flex,
    HStack,
    useToast,
    NumberInput,
    NumberInputField,
    VStack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
} from '@chakra-ui/react';
import { MdOutlineChevronRight } from 'react-icons/md';

const PredMpsSemen = () => {
    const [inputs, setInputs] = useState({
        cpgL01: '',
        cpgL02: '',
        cpgL03: '',
        cpgL04: '',
        cpgL05: '',
        cpgL06: '',
        cpgL07: '',
        cpgS01: '',
        cpgS03: '',
        cpgS05: '',
        cpgS06: '',
        cpgS07: '',
    });

    const [results, setResults] = useState({
        mps_semL_result: null,
        mps_semS_result: null,
    });

    const [showResultsA, setShowResultsA] = useState(false);
    const [showResultsB, setShowResultsB] = useState(false);
    const [isCalculateBDisabled, setIsCalculateBDisabled] = useState(false);
    const [warningMessage1, setWarningMessage1] = useState(false);
    const [warningMessage2, setWarningMessage2] = useState(false);

    const toast = useToast();

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = [
            'cpgL01',
            'cpgL02',
            'cpgL03',
            'cpgL04',
            'cpgL05',
            'cpgL06',
            'cpgL07',
        ];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handlePasteMethyl = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = ['cpgS01', 'cpgS03', 'cpgS05', 'cpgS06', 'cpgS07'];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setInputs((prev) => ({ ...prev, [id]: value }));
    };

    const handleReset = () => {
        setInputs({});
        setResults({});
        setShowResultsA(false);
        setShowResultsA(false);
    };

    const tablerow = [
        {
            name: 'CpG01',
            var1: 'cpgL01',
            var2: 'cpgS01',
        },
        {
            name: 'CpG02',
            var1: 'cpgL02',
            var2: 'None',
        },
        {
            name: 'CpG03',
            var1: 'cpgL03',
            var2: 'cpgS03',
        },
        {
            name: 'CpG04',
            var1: 'cpgL04',
            var2: 'None',
        },
        {
            name: 'CpG05',
            var1: 'cpgL05',
            var2: 'cpgS05',
        },
        {
            name: 'CpG06',
            var1: 'cpgL06',
            var2: 'cpgS06',
        },
        {
            name: 'CpG07',
            var1: 'cpgL07',
            var2: 'cpgS07',
        },
    ];

    const handleCalculateLasso = () => {
        const { cpgL01, cpgL02, cpgL03, cpgL04, cpgL05, cpgL06, cpgL07 } =
            inputs;

        const isEmpty = [
            cpgL01,
            cpgL02,
            cpgL03,
            cpgL04,
            cpgL05,
            cpgL06,
            cpgL07,
        ].some((val) => val === '');

        const resultA = {
            mps_semL_result: 'Value Result',
        };

        if (isEmpty) {
            setResults({
                ...resultA,
                mps_semL_result: NaN,
            });
        } else {
            const mps_semL_result =
                165.836 +
                cpgL01 * -27.0722 +
                cpgL02 * -16.0319 +
                cpgL03 * -57.3222 +
                cpgL04 * 0.2386 +
                cpgL05 * 72.7607 +
                cpgL06 * 78.3258 +
                cpgL07 * -183.3343;

            setResults({
                ...resultA,
                mps_semL_result: mps_semL_result.toFixed(4),
            });
        }
        setShowResultsA(true);
        setShowResultsB(false);
        setIsCalculateBDisabled(true);
    };

    const handleCalculateStepwise = () => {
        const { cpgS01, cpgS03, cpgS05, cpgS06, cpgS07 } = inputs;

        const isEmpty = [cpgS01, cpgS03, cpgS05, cpgS06, cpgS07].some(
            (val) => val === ''
        );

        const resultB = {
            mps_semS_result: 'Value ElasticNet',
        };

        if (isEmpty) {
            setResults({
                ...resultB,
                mps_semS_result: NaN,
            });
        } else {
            const mps_semS_result =
                223.4165 +
                cpgS01 * -58.4591 +
                cpgS03 * -56.023 +
                cpgS05 * 84.3844 +
                cpgS06 * 85.7487 +
                cpgS07 * -246.4761;

            setResults({
                ...results,
                mps_semS_result: mps_semS_result.toFixed(4),
            });
        }

        setShowResultsA(false);
        setShowResultsB(true);
    };

    return (
        <Box p={4}>
            {/* Header */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                <VStack
                    spacing="7px"
                    align="left"
                    justifyContent="space-between"
                    mb={4}
                >
                    <Breadcrumb
                        fontSize="s"
                        spacing="6px"
                        separator={<MdOutlineChevronRight color="gray.500" />}
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink>MPS</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Semen</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading as="h1" size="lg">
                        Semen
                    </Heading>
                </VStack>
                <HStack>
                    <Button
                        as="a"
                        variant="link"
                        href="../documents/9_Protocol_Semen_Y-CpGs.pdf"
                        download
                        color="#2b6cb0"
                        mb={4}
                    >
                        Download Protocol (PDF)
                    </Button>
                </HStack>
            </VStack>
            {/* Calculator */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                {/* Inputs & Methyl Results */}
                <Flex
                    // backgroundColor="gray.20"
                    direction="row"
                    height="500px"
                    width="600px"
                >
                    <Box flex="6" onPaste={handlePaste}>
                        {/* Table Left */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr borders="0">
                                    <Th>
                                        <Button
                                            colorScheme="gray"
                                            onClick={handleReset}
                                            m={2}
                                        >
                                            Reset All
                                        </Button>
                                    </Th>
                                    <Th>
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculateLasso}
                                                m={2}
                                                w="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td></Td>
                                    <Td textAlign="center">
                                        <Text fontSize="md">Lasso Model</Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.name}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var1}
                                                    value={
                                                        inputs[item.var1] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var1,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    // mt="2px" // Top margin (adjust as needed)
                                                    // mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="4" onPaste={handlePasteMethyl}>
                        {/* Table Right */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr>
                                    <Th alignItems="center">
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={
                                                    handleCalculateStepwise
                                                }
                                                disabled={isCalculateBDisabled}
                                                m={2}
                                                width="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td textAlign="center">
                                        <Text fontSize="md">
                                            Stepwise Model
                                        </Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            {item.var2 === 'None' ? (
                                                <Td>
                                                    <Box
                                                        height="40.5px"
                                                        fontSize="md"
                                                        textAlign="center"
                                                    >
                                                        None
                                                    </Box>
                                                </Td>
                                            ) : (
                                                <Td>
                                                    <NumberInput
                                                        // height="29.25px"
                                                        width="100%"
                                                        id={item.var2}
                                                        min={0}
                                                        value={
                                                            inputs[item.var2] ||
                                                            ''
                                                        }
                                                        onChange={(
                                                            valueString
                                                        ) =>
                                                            handleInputChange({
                                                                target: {
                                                                    id: item.var2,
                                                                    value: valueString,
                                                                },
                                                            })
                                                        }
                                                        m={0} // Remove all margins
                                                        // mt={1} // Top margin (adjust as needed)
                                                        // mb={0.5}
                                                    >
                                                        <NumberInputField />
                                                    </NumberInput>
                                                </Td>
                                            )}
                                        </Tr>
                                        <Tr></Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
                {/* Age Est. Results */}
                <Flex
                    direction="row"
                    // height="auto"
                    width="600px"
                    bg="gray.50"
                >
                    <Box flex="6">
                        {/* Table left */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td colSpan="2">
                                        <Text
                                            whiteSpace="pre-line"
                                            textAlign="center"
                                        >
                                            Estimated age (years)
                                        </Text>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="4">
                        {/* Table right */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.mps_semL_result
                                            : showResultsB
                                            ? results.mps_semS_result
                                            : ''}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
            </VStack>
            {/* CpG Info */}
            <Flex
                // backgroundColor="gray.20"
                direction="row"
                width="600px"
            >
                <VStack
                    spacing="12px"
                    align="left"
                    justifyContent="space-between"
                    // mb={6}
                    mt={6}
                    pt={6}
                >
                    <Box>
                        <Text fontSize="xl" fontWeight="bold" color="#2b6cb0">
                            CpG Marker Information
                        </Text>
                    </Box>
                    <Box>
                        <Table colorScheme="blue">
                            <Thead bg="gray.50">
                                <Tr>
                                    <Th
                                        rowSpan="2"
                                        textTransform="none"
                                        fontSize={'sm'}
                                    >
                                        CpG ID
                                    </Th>
                                    <Th
                                        rowSpan="2"
                                        textTransform="none"
                                        fontSize={'sm'}
                                    >
                                        Position
                                    </Th>
                                    <Th
                                        colSpan="3"
                                        textTransform="none"
                                        fontSize={'sm'}
                                    >
                                        Coefficient
                                    </Th>
                                </Tr>
                                <Tr>
                                    <Th textTransform="none" fontSize={'sm'}>
                                        Lasso
                                    </Th>
                                    <Th textTransform="none" fontSize={'sm'}>
                                        Stepwise
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>marker2</Td>
                                    <Td>chrY:9400045</Td>
                                    <Td textAlign="right">-27.0722</Td>
                                    <Td textAlign="right">-58.4591</Td>
                                </Tr>
                                <Tr>
                                    <Td>marker3</Td>
                                    <Td>chrY:9400019</Td>
                                    <Td textAlign="right">-16.0319</Td>
                                    <Td textAlign="right"></Td>
                                </Tr>
                                <Tr>
                                    <Td>cg15810474</Td>
                                    <Td>chrY:9468841</Td>
                                    <Td textAlign="right">-57.3222</Td>
                                    <Td textAlign="right">-56.023</Td>
                                </Tr>
                                <Tr>
                                    <Td>cg13372258</Td>
                                    <Td>chrY:10093006</Td>
                                    <Td textAlign="right">0.2386</Td>
                                    <Td textAlign="right"></Td>
                                </Tr>
                                <Tr>
                                    <Td>cg14446584</Td>
                                    <Td>chrY:12710685</Td>
                                    <Td textAlign="right">72.7607</Td>
                                    <Td textAlign="right">84.3844</Td>
                                </Tr>
                                <Tr>
                                    <Td>cg00061679</Td>
                                    <Td>chrY:23168025</Td>
                                    <Td textAlign="right">78.3258</Td>
                                    <Td textAlign="right">85.7487</Td>
                                </Tr>
                                <Tr>
                                    <Td>marker1</Td>
                                    <Td>chrY:23168085</Td>
                                    <Td textAlign="right">-183.3343</Td>
                                    <Td textAlign="right">-246.4761</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </VStack>
            </Flex>
        </Box>
    );
};

export default PredMpsSemen;
