import { Tabs, TabList, Tab } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export function AncestryYstrTabs() {
    return (
        <Tabs mt={5}>
            <TabList>
                <Tab as={Link} to="/portal/ancestry-ystr/pretrained">
                    Use Pretrained Model
                </Tab>
                <Tab as={Link} to="/portal/ancestry-ystr/new">
                    Train New Model
                </Tab>
            </TabList>
        </Tabs>
    );
}

export function AncestrySnpTabs() {
    return (
        <Tabs mt={5}>
            <TabList>
                <Tab as={Link} to="/portal/ancestry-snp/pretrained">
                    Use Pretrained Model
                </Tab>
                <Tab as={Link} to="/portal/ancestry-snp/new">
                    Train New Model
                </Tab>
            </TabList>
        </Tabs>
    );
}
