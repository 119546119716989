import {
    Box,
    Button,
    Container,
    Heading,
    Icon,
    SimpleGrid,
    Stack,
    Text,
    VStack,
    HStack,
    Image,
} from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';

const Card = ({ heading, description }) => {
    return (
        <Box
            height={{ base: '200px', sm: '160px' }}
            w={{ base: 'full', md: '1xl' }}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={5}
            bg="white"
        >
            <Stack align={'start'} spacing={2}>
                <Box mt={2}>
                    <Heading size="sm">{heading}</Heading>
                    <Text mt={2} fontSize={'sm'}>
                        {description}
                    </Text>
                </Box>
            </Stack>
        </Box>
    );
};

export default function Research() {
    return (
        <Stack spacing={0}>
            <Box p={10} bg="gray.50">
                <Stack
                    spacing={4}
                    as={Container}
                    maxW={'4xl'}
                    textAlign={'left'}
                    my={10}
                >
                    <Heading>진행중인 연구</Heading>
                    <Text
                        color={'gray.600'}
                        fontSize={{ base: 'sm', sm: 'lg' }}
                        whiteSpace="pre-line"
                    >
                        피의자가 특정되지 않은 사건에서 용의자의 외형 및 연령,
                        생활습관 등을 추정할 수 있는
                        {'\n'}용의자 추정기술 개발과 신속한 분석을 위한 인공지능
                        기반 DNA 분석 시스템 구축
                    </Text>
                </Stack>
            </Box>
            <Container as={Stack} maxW={'6xl'} pt={20} pb={10}>
                <SimpleGrid
                    columns={{ base: 1, sm: 1, md: 2 }}
                    spacing={10}
                    px={{ base: 5, sm: 10 }}
                >
                    <Stack align="center">
                        <Button
                            variant="outline"
                            w="65%"
                            h="full"
                            alignItems="center"
                            _disabled={{ color: 'black', bg: 'white' }}
                            _hover={{ color: 'black', bg: 'white' }}
                        >
                            <Image
                                boxSize="100px"
                                objectFit="scale-down"
                                src="../images/Genetics_made.png"
                                mr={1}
                            />
                            <VStack align="left" spacing={0}>
                                <Text fontSize="xl" fontWeight={'bold'}>
                                    Genetics
                                </Text>
                                <Text fontSize="xl">유전학</Text>
                            </VStack>
                        </Button>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <Heading
                            mb={1}
                            fontSize={{ base: 'xl', sm: 'xl' }}
                            fontWeight={'bold'}
                            // textAlign="left"
                        >
                            아시아 지역 민족 구분
                        </Heading>
                        <Box>
                            <HStack>
                                <Icon as={FaCheck} />
                                <Text>
                                    부계로 유전되는 Y 염색체와 민족 구분 연구에
                                    유용한 상 염색체 SNP와 Microhaplotype을 이용
                                </Text>
                            </HStack>
                        </Box>
                        <Box>
                            <HStack>
                                <Icon as={FaCheck} />
                                <Text>
                                    데이터 마이닝 기법을 이용한 Y-STR 자료 기반
                                    아시아 지역 민족 분류 모델 제공
                                </Text>
                            </HStack>
                        </Box>
                        <Box>
                            <HStack>
                                <Icon as={FaCheck} />
                                <Text>
                                    머신 러닝을 이용한 상 염색체 SNP 분류 분석
                                    모델 제공
                                </Text>
                            </HStack>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Container>
            <Container as={Stack} maxW={'6xl'} pt={10} pb={20}>
                <SimpleGrid
                    columns={{ base: 1, sm: 1, md: 2 }}
                    spacing={10}
                    px={{ base: 5, sm: 10 }}
                >
                    <Stack align="center">
                        <Button
                            variant="outline"
                            // border="1px"
                            // borderRadius="25"
                            w="65%"
                            h="full"
                            alignItems="center"
                            _disabled={{ color: 'black', bg: 'white' }}
                            _hover={{ color: 'black', bg: 'white' }}
                        >
                            <Image
                                boxSize="95px"
                                objectFit="scale-down"
                                src="../images/Epigenetics_made.png"
                                pr={1}
                                mr={1}
                            />
                            <VStack align="left" spacing={0}>
                                <Text fontSize="xl" fontWeight={'bold'}>
                                    Epigenetics
                                </Text>
                                <Text fontSize="xl">후성유전학</Text>
                            </VStack>
                        </Button>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <Heading
                            mb={1}
                            fontSize={{ base: 'xl', sm: 'xl' }}
                            fontWeight={'bold'}
                            // textAlign="left"
                        >
                            한국인 특이적 연령, 외형, 생활 습관 추정
                        </Heading>
                        <Box>
                            <HStack>
                                <Icon as={FaCheck} />
                                <Text>
                                    연령, 흡연, 음주, BMI 와 연관성을 보이는 DNA
                                    methylation 마커를 이용한 모델 제공
                                </Text>
                            </HStack>
                        </Box>
                        <Box>
                            <HStack>
                                <Icon as={FaCheck} />
                                <Text>
                                    EPIC array, SNaPshot, MPS, pyrosequencing
                                    데이터 활용
                                </Text>
                            </HStack>
                        </Box>
                        <Box>
                            <HStack>
                                <Icon as={FaCheck} />
                                <Text>머신 러닝을 이용한 추정 모델 제공</Text>
                            </HStack>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Container>
            <Box bg="gray.50" pt={75} pb={70}>
                <Stack
                    spacing={4}
                    as={Container}
                    maxW={'4xl'}
                    textAlign={'center'}
                >
                    <Heading
                        fontSize={{ base: '2xl' }}
                        fontWeight={'bold'}
                        textAlign="center"
                        px={{ base: 10 }}
                    >
                        여러 분석 시스템과 조직에 따른 DNA Methylation 데이터
                        제공
                    </Heading>
                </Stack>

                <Container maxW={'4xl'} mt={12} mb={5} px={10}>
                    <SimpleGrid
                        columns={{ base: 1, sm: 1, md: 2 }}
                        spacing={6}
                        alignItems="center"
                    >
                        <Card
                            heading={
                                <Text
                                    whiteSpace="pre-line"
                                    fontSize={{ base: 'lg' }}
                                >
                                    분석 시스템
                                    {'\n'}(Methylation analysis platforms)
                                </Text>
                            }
                            description={
                                <Text
                                    whiteSpace="pre-line"
                                    fontSize={{ base: 'md' }}
                                >
                                    SNaPshot(3130, 3500, SeqStudio), MPS,
                                    pyrosequencing
                                </Text>
                            }
                        />
                        <Card
                            heading={
                                <Text
                                    whiteSpace="pre-line"
                                    fontSize={{ base: 'lg' }}
                                >
                                    조직 (Tissue)
                                </Text>
                            }
                            description={
                                <Text
                                    whiteSpace="pre-line"
                                    fontSize={{ base: 'md' }}
                                >
                                    혈액, 타액, 정액, 구강상피, 질액, 생리혈 등
                                </Text>
                            }
                        />
                        <Card
                            heading={
                                <Text
                                    whiteSpace="pre-line"
                                    fontSize={{ base: 'lg' }}
                                >
                                    프로토콜 (Protocol)
                                </Text>
                            }
                            description={
                                <Text
                                    whiteSpace="pre-line"
                                    fontSize={{ base: 'md' }}
                                >
                                    다양한 DNA 메틸화 분석 표준 프로토콜 및 해석
                                    가이드라인
                                </Text>
                            }
                            href={'#'}
                        />
                        <Card
                            heading={
                                <Text
                                    whiteSpace="pre-line"
                                    fontSize={{ base: 'lg' }}
                                >
                                    Calculator
                                </Text>
                            }
                            description={
                                <Text
                                    whiteSpace="pre-line"
                                    fontSize={{ base: 'md' }}
                                >
                                    연령 추정이 가능한 calculator 기능
                                </Text>
                            }
                        />
                    </SimpleGrid>
                    <Stack alignItems="center" pt={12}>
                        <Box to={'/portal/methyl-snapshot-bsb'} isExternal>
                            <Button
                                as="a"
                                colorScheme="blue"
                                _hover={{ color: 'white', bg: '#2b6cb0' }}
                                height="50px"
                                w="200px"
                                href="/portal/methyl-snapshot-bsb"
                            >
                                <Text fontSize="lg">Visit Portal</Text>
                            </Button>
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </Stack>
    );
}
