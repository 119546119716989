import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Button,
    Box,
    Flex,
    useToast,
    NumberInput,
    NumberInputField,
    VStack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
} from '@chakra-ui/react';
import { MdOutlineChevronRight } from 'react-icons/md';

const PredSnapEpidermis = () => {
    const [inputs, setInputs] = useState({
        epi01y: '',
        epi01r: '',
        epi02b: '',
        epi02g: '',
        epi03y: '',
        epi03r: '',
        epi04b: '',
        epi04g: '',
        epi05y: '',
        epi05r: '',
        epi06y: '',
        epi06r: '',
        epi07y: '',
        epi07r: '',
        epi08y: '',
        epi08r: '',
        epi09y: '',
        epi09r: '',
        epi01_methyl: '',
        epi02_methyl: '',
        epi03_methyl: '',
        epi04_methyl: '',
        epi05_methyl: '',
        epi06_methyl: '',
        epi07_methyl: '',
        epi08_methyl: '',
        epi09_methyl: '',
    });

    const [results, setResults] = useState({
        epi01_result: null,
        epi02_result: null,
        epi03_result: null,
        epi04_result: null,
        epi05_result: null,
        epi06_result: null,
        epi07_result: null,
        epi08_result: null,
        epi09_result: null,

        snap_epiLR_result: null,
        snap_epiLR_result_methyl: null,
        snap_epiSR_result: null,
        snap_epiSR_result_methyl: null,
    });

    const [showResultsA, setShowResultsA] = useState(false);
    const [showResultsB, setShowResultsB] = useState(false);
    const [isCalculateBDisabled, setIsCalculateBDisabled] = useState(false);
    const [warningMessage1, setWarningMessage1] = useState(false);
    const [warningMessage2, setWarningMessage2] = useState(false);

    const toast = useToast();

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = [
            'epi01y',
            'epi01r',
            'epi02b',
            'epi02g',
            'epi03y',
            'epi03r',
            'epi04b',
            'epi04g',
            'epi05y',
            'epi05r',
            'epi06y',
            'epi06r',
            'epi07y',
            'epi07r',
            'epi08y',
            'epi08r',
            'epi09y',
            'epi09r',
        ];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handlePasteMethyl = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = [
            'epi01_methyl',
            'epi02_methyl',
            'epi03_methyl',
            'epi04_methyl',
            'epi05_methyl',
            'epi06_methyl',
            'epi07_methyl',
            'epi08_methyl',
            'epi09_methyl',
        ];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setInputs((prev) => ({ ...prev, [id]: value }));
    };

    const handleReset = () => {
        setInputs({});
        setResults({});
        setShowResultsA(false);
        setShowResultsA(false);
    };

    const tablerow = [
        {
            name: 'EPI_AGE01',
            first: 'Yellow',
            second: 'Red',
            var1: 'epi01y',
            var2: 'epi01r',
            result: results.epi01_result,
            input: 'epi01_methyl',
        },
        {
            name: 'EPI_AGE02',
            first: 'Blue',
            second: 'Green',
            var1: 'epi02b',
            var2: 'epi02g',
            result: results.epi02_result,
            input: 'epi02_methyl',
        },
        {
            name: 'EPI_AGE03',
            first: 'Yellow',
            second: 'Red',
            var1: 'epi03y',
            var2: 'epi03r',
            result: results.epi03_result,
            input: 'epi03_methyl',
        },
        {
            name: 'EPI_AGE04',
            first: 'Blue',
            second: 'Green',
            var1: 'epi04b',
            var2: 'epi04g',
            result: results.epi04_result,
            input: 'epi04_methyl',
        },
        {
            name: 'EPI_AGE05',
            first: 'Yellow',
            second: 'Red',
            var1: 'epi05y',
            var2: 'epi05r',
            result: results.epi05_result,
            input: 'epi05_methyl',
        },
        {
            name: 'EPI_AGE06',
            first: 'Yellow',
            second: 'Red',
            var1: 'epi06y',
            var2: 'epi06r',
            result: results.epi06_result,
            input: 'epi06_methyl',
        },
        {
            name: 'EPI_AGE07',
            first: 'Yellow',
            second: 'Red',
            var1: 'epi07y',
            var2: 'epi07r',
            result: results.epi07_result,
            input: 'epi07_methyl',
        },
        {
            name: 'EPI_AGE08',
            first: 'Yellow',
            second: 'Red',
            var1: 'epi08y',
            var2: 'epi08r',
            result: results.epi08_result,
            input: 'epi08_methyl',
        },
        {
            name: 'EPI_AGE09',
            first: 'Yellow',
            second: 'Red',
            var1: 'epi09y',
            var2: 'epi09r',
            result: results.epi09_result,
            input: 'epi09_methyl',
        },
    ];

    const handleCalculate = () => {
        const {
            epi01y,
            epi01r,
            epi02b,
            epi02g,
            epi03y,
            epi03r,
            epi04b,
            epi04g,
            epi05y,
            epi05r,
            epi06y,
            epi06r,
            epi07y,
            epi07r,
            epi08y,
            epi08r,
            epi09y,
            epi09r,
        } = inputs;

        const epi01_result =
            parseFloat(epi01y) / (parseFloat(epi01y) + parseFloat(epi01r));
        const epi02_result =
            parseFloat(epi02b) / (parseFloat(epi02b) + parseFloat(epi02g));
        const epi03_result =
            parseFloat(epi03y) / (parseFloat(epi03y) + parseFloat(epi03r));
        const epi04_result =
            parseFloat(epi04b) / (parseFloat(epi04b) + parseFloat(epi04g));
        const epi05_result =
            parseFloat(epi05y) / (parseFloat(epi05y) + parseFloat(epi05r));
        const epi06_result =
            parseFloat(epi06y) / (parseFloat(epi06y) + parseFloat(epi06r));
        const epi07_result =
            parseFloat(epi07y) / (parseFloat(epi07y) + parseFloat(epi07r));
        const epi08_result =
            parseFloat(epi08y) / (parseFloat(epi08y) + parseFloat(epi08r));
        const epi09_result =
            parseFloat(epi09y) / (parseFloat(epi09y) + parseFloat(epi09r));

        const snap_epiLR_result =
            -19.314 +
            35.808 * epi01_result +
            3.144 * epi02_result +
            29.513 * epi03_result +
            -1.752 * epi04_result +
            -18.954 * epi05_result +
            19.929 * epi06_result +
            43.994 * epi07_result +
            84.57 * epi08_result +
            44.476 * epi09_result;
        const snap_epiSR_result =
            -27.2 +
            35.72 * epi03_result +
            53.28 * epi07_result +
            84.26 * epi08_result +
            57.87 * epi09_result;

        const resultA = {
            epi01_result: 'Value epi01',
            epi02_result: 'Value epi02',
            epi03_result: 'Value epi03',
            epi04_result: 'Value epi04',
            epi05_result: 'Value epi05',
            epi06_result: 'Value epi06',
            epi07_result: 'Value epi07',
            epi08_result: 'Value epi08',
            epi09_result: 'Value epi09',
            snap_epiLR_result: 'Value epiLR',
            snap_epiSR_result: 'Value epiSR',
        };

        setResults({
            ...resultA,
            epi01_result: epi01_result.toFixed(4),
            epi02_result: epi02_result.toFixed(4),
            epi03_result: epi03_result.toFixed(4),
            epi04_result: epi04_result.toFixed(4),
            epi05_result: epi05_result.toFixed(4),
            epi06_result: epi06_result.toFixed(4),
            epi07_result: epi07_result.toFixed(4),
            epi08_result: epi08_result.toFixed(4),
            epi09_result: epi09_result.toFixed(4),
            snap_epiLR_result: snap_epiLR_result.toFixed(4),
            snap_epiSR_result: snap_epiSR_result.toFixed(4),
        });

        setShowResultsA(true);
        setShowResultsB(false);
        setIsCalculateBDisabled(true);
    };

    const handleCalculateMethyl = () => {
        const {
            epi01_methyl,
            epi02_methyl,
            epi03_methyl,
            epi04_methyl,
            epi05_methyl,
            epi06_methyl,
            epi07_methyl,
            epi08_methyl,
            epi09_methyl,
        } = inputs;

        const isEmpty = [
            epi01_methyl,
            epi02_methyl,
            epi03_methyl,
            epi04_methyl,
            epi05_methyl,
            epi06_methyl,
            epi07_methyl,
            epi08_methyl,
            epi09_methyl,
        ].some((val) => val === '');

        const resultB = {
            snap_epiLR_result_methyl: 'Value Lasso',
            snap_epiSR_result_methyl: 'Value Stepwise',
        };

        if (isEmpty) {
            setWarningMessage2(true);
            setResults({
                ...resultB,
                snap_epiLR_result_methyl: NaN,
                snap_epiSR_result_methyl: NaN,
            });
        } else {
            setWarningMessage2(false);

            const snap_epiLR_result_methyl =
                -19.314 +
                35.808 * epi01_methyl +
                3.144 * epi02_methyl +
                29.513 * epi03_methyl +
                -1.752 * epi04_methyl +
                -18.954 * epi05_methyl +
                19.929 * epi06_methyl +
                43.994 * epi07_methyl +
                84.57 * epi08_methyl +
                44.476 * epi09_methyl;
            const snap_epiSR_result_methyl =
                -27.2 +
                35.72 * epi03_methyl +
                53.28 * epi07_methyl +
                84.26 * epi08_methyl +
                57.87 * epi09_methyl;

            setResults({
                ...results,
                snap_epiLR_result_methyl: snap_epiLR_result_methyl.toFixed(4),
                snap_epiSR_result_methyl: snap_epiSR_result_methyl.toFixed(4),
            });
        }

        setShowResultsA(false);
        setShowResultsB(true);
    };

    return (
        <Box p={4}>
            {/* Header */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                <VStack
                    spacing="7px"
                    align="left"
                    justifyContent="space-between"
                    mb={4}
                >
                    <Breadcrumb
                        fontSize="s"
                        spacing="6px"
                        separator={<MdOutlineChevronRight color="gray.500" />}
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink>SNapShot</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Epidermis</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading as="h1" size="lg">
                        Epidermis
                    </Heading>
                </VStack>
            </VStack>
            {/* Calculator */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                {/* Inputs & Methyl Results */}
                <Flex
                    // backgroundColor="gray.20"
                    direction="row"
                    height="1200px"
                    width="800px"
                >
                    <Box flex="6.5" onPaste={handlePaste}>
                        {/* Table Left */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr borders="0">
                                    <Th>
                                        <Button
                                            colorScheme="gray"
                                            onClick={handleReset}
                                            m={2}
                                        >
                                            Reset All
                                        </Button>
                                    </Th>
                                    <Th></Th>
                                    <Th>
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculate}
                                                m={2}
                                                w="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td></Td>
                                    <Td></Td>
                                    <Td textAlign="center">
                                        <Text fontSize="md">Input (rfu)</Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td rowSpan="2">
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.name}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.first}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var1}
                                                    value={
                                                        inputs[item.var1] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var1,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.second}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var2}
                                                    value={
                                                        inputs[item.var2] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var2,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="3.5" onPaste={handlePasteMethyl}>
                        {/* Table Right */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr>
                                    <Th alignItems="center">
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculateMethyl}
                                                disabled={isCalculateBDisabled}
                                                m={2}
                                                width="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td textAlign="center">
                                        <Text fontSize="md">
                                            Methylation (0-1)
                                        </Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td rowSpan={2}>
                                                {showResultsA ? (
                                                    <Text
                                                        fontSize="md"
                                                        align="center"
                                                    >
                                                        {item.result}
                                                    </Text>
                                                ) : (
                                                    <NumberInput
                                                        width="100%"
                                                        id={item.input}
                                                        min={0}
                                                        max={1}
                                                        value={
                                                            inputs[
                                                                item.input
                                                            ] || ''
                                                        }
                                                        onChange={(
                                                            valueString
                                                        ) =>
                                                            handleInputChange({
                                                                target: {
                                                                    id: item.input,
                                                                    value: valueString,
                                                                },
                                                            })
                                                        }
                                                        m={0} // Remove all margins
                                                        mt={1} // Top margin (adjust as needed)
                                                        mb={1}
                                                    >
                                                        <NumberInputField />
                                                    </NumberInput>
                                                )}
                                            </Td>
                                        </Tr>
                                        <Tr></Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
                {/* Age Est. Results */}
                <Flex
                    direction="row"
                    // height="auto"
                    width="800px"
                    bg="gray.50"
                >
                    <Box flex="6.5">
                        {/* Table left */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td colSpan="2" rowSpan="2">
                                        <Text whiteSpace="pre-line">
                                            Estimated age (years)
                                        </Text>
                                    </Td>
                                    <Td>Linear regression</Td>
                                </Tr>
                                <Tr>
                                    <Td>Stepwise regression</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="3.5">
                        {/* Table right */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.snap_epiLR_result
                                            : showResultsB
                                            ? results.snap_epiLR_result_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.snap_epiSR_result
                                            : showResultsB
                                            ? results.snap_epiSR_result_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
            </VStack>
        </Box>
    );
};

export default PredSnapEpidermis;
