import {
    Box,
    VStack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
} from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { AncestrySnpTabs } from './AncestryTabs';

export default function AncestryYSTR() {
    return (
        <Box py={6} px={2}>
            <VStack align="left" justifyContent="space-between" mb={6}>
                <VStack align="left" mb={4}>
                    <Breadcrumb>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Ancestry Prediction</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>SNP</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading as="h1" size="lg">
                        SNP
                    </Heading>

                    {/* 탭 컴포넌트 */}
                    <AncestrySnpTabs />
                </VStack>
                {/* URL에 따라 컴포넌트 변경 */}
                <Outlet />
            </VStack>
        </Box>
    );
}
