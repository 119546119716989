export const DATA_SNAP_BSB = [
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '19',
        evl2: 0.2778827977,
        f2: 0.2190152801,
        k14: 0.03596127248,
        m29: 0.918469218,
        t59: 0.2219451372,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.2895174709,
        f2: 0.1691542289,
        k14: 0.03698501873,
        m29: 0.8267477204,
        t59: 0.2389830508,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.2793614595,
        f2: 0.3264,
        k14: 0.04890055793,
        m29: 0.8924137931,
        t59: 0.2297979798,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.3150807899,
        f2: 0.3155619597,
        k14: 0.03822544643,
        m29: 0.868338558,
        t59: 0.2295258621,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.28245476,
        f2: 0.2690140845,
        k14: 0.03916866507,
        m29: 0.8558823529,
        t59: 0.226993865,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '25',
        evl2: 0.3252595156,
        f2: 0.2823984526,
        k14: 0.04841802493,
        m29: 0.8575803982,
        t59: 0.2605633803,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '25',
        evl2: 0.3253546099,
        f2: 0.3304347826,
        k14: 0.08137108793,
        m29: 0.926056338,
        t59: 0.2203592814,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '27',
        evl2: 0.3206018519,
        f2: 0.3258706468,
        k14: 0.04740338164,
        m29: 0.7976190476,
        t59: 0.243697479,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '27',
        evl2: 0.3193403298,
        f2: 0.2972972973,
        k14: 0.0424691358,
        m29: 0.873015873,
        t59: 0.240942029,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '31',
        evl2: 0.3182256509,
        f2: 0.2602996255,
        k14: 0.03578299346,
        m29: 0.8427835052,
        t59: 0.2051282051,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '31',
        evl2: 0.3328550933,
        f2: 0.2960199005,
        k14: 0.06324972737,
        m29: 0.8511326861,
        t59: 0.2611806798,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '33',
        evl2: 0.387049301,
        f2: 0.3263157895,
        k14: 0.05780063679,
        m29: 0.8183139535,
        t59: 0.2802359882,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '34',
        evl2: 0.3573113208,
        f2: 0.3963815789,
        k14: 0.04682926829,
        m29: 0.8222222222,
        t59: 0.2575163399,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '35',
        evl2: 0.4269992663,
        f2: 0.361516035,
        k14: 0.06593406593,
        m29: 0.8028169014,
        t59: 0.2769556025,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.3940643034,
        f2: 0.3413940256,
        k14: 0.0628641521,
        m29: 0.8127147766,
        t59: 0.2646720368,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '37',
        evl2: 0.3926432849,
        f2: 0.3839869281,
        k14: 0.07811550152,
        m29: 0.820415879,
        t59: 0.2928118393,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '39',
        evl2: 0.4448398577,
        f2: 0.4626506024,
        k14: 0.06892922915,
        m29: 0.9006993007,
        t59: 0.31799591,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.439333862,
        f2: 0.3088235294,
        k14: 0.05808782657,
        m29: 0.8056951424,
        t59: 0.280334728,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.4384678077,
        f2: 0.343575419,
        k14: 0.05073924731,
        m29: 0.7575757576,
        t59: 0.2736373749,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.4770992366,
        f2: 0.4088785047,
        k14: 0.04496402878,
        m29: 0.7792553191,
        t59: 0.2704918033,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.420233463,
        f2: 0.370869033,
        k14: 0.07948969578,
        m29: 0.7870090634,
        t59: 0.3268641471,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '42',
        evl2: 0.4298780488,
        f2: 0.412037037,
        k14: 0.06392251816,
        m29: 0.8067885117,
        t59: 0.3156934307,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '42',
        evl2: 0.4121355398,
        f2: 0.4562682216,
        k14: 0.0794052515,
        m29: 0.8377581121,
        t59: 0.3329979879,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.4423076923,
        f2: 0.3760162602,
        k14: 0.05530973451,
        m29: 0.8407643312,
        t59: 0.2814930016,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.4671253823,
        f2: 0.3405889885,
        k14: 0.08841295991,
        m29: 0.7702888583,
        t59: 0.2964102564,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '47',
        evl2: 0.4897595726,
        f2: 0.4207011686,
        k14: 0.1249564915,
        m29: 0.7663230241,
        t59: 0.3218997361,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '50',
        evl2: 0.5807017544,
        f2: 0.4111842105,
        k14: 0.06204951426,
        m29: 0.5858778626,
        t59: 0.295728368,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '50',
        evl2: 0.5010080645,
        f2: 0.4095394737,
        k14: 0.09067431851,
        m29: 0.7889688249,
        t59: 0.3603723404,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.4650306748,
        f2: 0.4493670886,
        k14: 0.1350510884,
        m29: 0.8833333333,
        t59: 0.3350970018,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.5224416517,
        f2: 0.458259325,
        k14: 0.07292589312,
        m29: 0.6776315789,
        t59: 0.3137673426,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.5043763676,
        f2: 0.4909090909,
        k14: 0.1181359454,
        m29: 0.768558952,
        t59: 0.2729658793,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '55',
        evl2: 0.5178571429,
        f2: 0.4456521739,
        k14: 0.1136192626,
        m29: 0.8235294118,
        t59: 0.3273703041,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.497005988,
        f2: 0.4515539305,
        k14: 0.1326224592,
        m29: 0.7,
        t59: 0.347129506,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.511820331,
        f2: 0.4982078853,
        k14: 0.05953134896,
        m29: 0.7345454545,
        t59: 0.3635097493,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.5074380165,
        f2: 0.5082644628,
        k14: 0.1216216216,
        m29: 0.6928213689,
        t59: 0.3186915888,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.5266106443,
        f2: 0.45951417,
        k14: 0.1191176471,
        m29: 0.7158590308,
        t59: 0.3167808219,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '59',
        evl2: 0.4909284952,
        f2: 0.5123966942,
        k14: 0.09828956878,
        m29: 0.7003610108,
        t59: 0.3205417607,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.5394190871,
        f2: 0.4712389381,
        k14: 0.1030181087,
        m29: 0.7894736842,
        t59: 0.3536121673,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.4917444365,
        f2: 0.4558139535,
        k14: 0.1069277108,
        m29: 0.5726495726,
        t59: 0.3519553073,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.5213523132,
        f2: 0.5151006711,
        k14: 0.152231747,
        m29: 0.7274472169,
        t59: 0.336196319,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.5528301887,
        f2: 0.650273224,
        k14: 0.1238404453,
        m29: 0.7658862876,
        t59: 0.3256351039,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '61',
        evl2: 0.4301848049,
        f2: 0.4735883424,
        k14: 0.1545535449,
        m29: 0.7764705882,
        t59: 0.34125,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '62',
        evl2: 0.5633293124,
        f2: 0.4991394148,
        k14: 0.141554824,
        m29: 0.7707006369,
        t59: 0.3527653214,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '63',
        evl2: 0.5244299674,
        f2: 0.4904371585,
        k14: 0.114332476,
        m29: 0.7140992167,
        t59: 0.3413516609,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '63',
        evl2: 0.5884086444,
        f2: 0.4442675159,
        k14: 0.1151586369,
        m29: 0.7159468439,
        t59: 0.3324968632,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '64',
        evl2: 0.5145089286,
        f2: 0.530224525,
        k14: 0.1377038896,
        m29: 0.5420974889,
        t59: 0.3868194842,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '64',
        evl2: 0.6082224909,
        f2: 0.4867075665,
        k14: 0.09234828496,
        m29: 0.7481296758,
        t59: 0.3018018018,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '18',
        evl2: 0.3636363636,
        f2: 0.2291666667,
        k14: 0.03174603175,
        m29: 0.905362776,
        t59: 0.3273137698,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '21',
        evl2: 0.3804878049,
        f2: 0.2298850575,
        k14: 0.03418339664,
        m29: 0.94375,
        t59: 0.2757973734,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '23',
        evl2: 0.3178484108,
        f2: 0.25,
        k14: 0.0409694172,
        m29: 0.9360189573,
        t59: 0.3127659574,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.4183908046,
        f2: 0.1775456919,
        k14: 0.06111411574,
        m29: 0.8924050633,
        t59: 0.3286713287,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.5073431242,
        f2: 0.1036585366,
        k14: 0.05588133839,
        m29: 0.9132947977,
        t59: 0.3632653061,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '26',
        evl2: 0.3168,
        f2: 0.267822736,
        k14: 0.03344917463,
        m29: 0.8830715532,
        t59: 0.277027027,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '26',
        evl2: 0.3467336683,
        f2: 0.2565130261,
        k14: 0.02258529553,
        m29: 0.8660377358,
        t59: 0.2632375189,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '27',
        evl2: 0.3051470588,
        f2: 0.2102272727,
        k14: 0.05078125,
        m29: 0.8821656051,
        t59: 0.3047445255,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '28',
        evl2: 0.375,
        f2: 0.295532646,
        k14: 0.09469964664,
        m29: 0.8117048346,
        t59: 0.2773333333,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '29',
        evl2: 0.396039604,
        f2: 0.2433862434,
        k14: 0.05326876513,
        m29: 0.8044692737,
        t59: 0.3103448276,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '32',
        evl2: 0.3943089431,
        f2: 0.3954918033,
        k14: 0.07725321888,
        m29: 0.8217636023,
        t59: 0.3347280335,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.3356401384,
        f2: 0.3282442748,
        k14: 0.04191616766,
        m29: 0.6197183099,
        t59: 0.3829268293,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.4263363755,
        f2: 0.2496050553,
        k14: 0.06712878511,
        m29: 0.8474074074,
        t59: 0.3226205192,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.4720670391,
        f2: 0.2912912913,
        k14: 0.08680555556,
        m29: 0.8571428571,
        t59: 0.2946859903,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.4161358811,
        f2: 0.2759295499,
        k14: 0.05980427691,
        m29: 0.8172043011,
        t59: 0.3504273504,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.41995842,
        f2: 0.3299232737,
        k14: 0.05592957017,
        m29: 0.7867298578,
        t59: 0.3027522936,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.3681506849,
        f2: 0.4021909233,
        k14: 0.04593248478,
        m29: 0.7963963964,
        t59: 0.3403141361,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.3443298969,
        f2: 0.4698795181,
        k14: 0.0595084088,
        m29: 0.8342342342,
        t59: 0.3073089701,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.3743078627,
        f2: 0.3567318757,
        k14: 0.08517173974,
        m29: 0.8301630435,
        t59: 0.2728146013,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.4423676012,
        f2: 0.3424657534,
        k14: 0.1143682907,
        m29: 0.8328025478,
        t59: 0.3838235294,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.5004659832,
        f2: 0.3724637681,
        k14: 0.05542168675,
        m29: 0.7077798861,
        t59: 0.271446863,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '41',
        evl2: 0.4829931973,
        f2: 0.2727272727,
        k14: 0.1282051282,
        m29: 0.7822966507,
        t59: 0.4092827004,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '41',
        evl2: 0.4579025111,
        f2: 0.3946037099,
        k14: 0.1206747405,
        m29: 0.7490774908,
        t59: 0.35,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.4316666667,
        f2: 0.3919156415,
        k14: 0.108365019,
        m29: 0.8221288515,
        t59: 0.3662420382,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '44',
        evl2: 0.4330413016,
        f2: 0.389632107,
        k14: 0.07859949982,
        m29: 0.8186046512,
        t59: 0.3849493488,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '45',
        evl2: 0.6234817814,
        f2: 0.1507936508,
        k14: 0.1404662283,
        m29: 0.8707037643,
        t59: 0.5349794239,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '47',
        evl2: 0.4618973561,
        f2: 0.2959830867,
        k14: 0.1013110846,
        m29: 0.7831632653,
        t59: 0.4063205418,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '49',
        evl2: 0.5026041667,
        f2: 0.3656387665,
        k14: 0.1132275132,
        m29: 0.7250803859,
        t59: 0.4016,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.5328467153,
        f2: 0.3582474227,
        k14: 0.02557856273,
        m29: 0.5676567657,
        t59: 0.3141993958,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.5506241331,
        f2: 0.2807377049,
        k14: 0.08798283262,
        m29: 0.7446351931,
        t59: 0.3542857143,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.4853896104,
        f2: 0.3863636364,
        k14: 0.1094057458,
        m29: 0.7678883072,
        t59: 0.3543091655,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.4920634921,
        f2: 0.3075170843,
        k14: 0.06225059856,
        m29: 0.577689243,
        t59: 0.4838709677,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.5253218884,
        f2: 0.3996937213,
        k14: 0.1304824561,
        m29: 0.697309417,
        t59: 0.4085365854,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '55',
        evl2: 0.5729166667,
        f2: 0.3812154696,
        k14: 0.1528301887,
        m29: 0.6642857143,
        t59: 0.3248730964,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '56',
        evl2: 0.4153661465,
        f2: 0.46105919,
        k14: 0.1112244898,
        m29: 0.6907630522,
        t59: 0.4567062818,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '56',
        evl2: 0.5244328098,
        f2: 0.4409221902,
        k14: 0.1049382716,
        m29: 0.6571428571,
        t59: 0.398540146,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '58',
        evl2: 0.4968944099,
        f2: 0.5041551247,
        k14: 0.1604680318,
        m29: 0.7020933977,
        t59: 0.4321533923,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '59',
        evl2: 0.5830212235,
        f2: 0.3510638298,
        k14: 0.1556277936,
        m29: 0.6574257426,
        t59: 0.4826989619,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '59',
        evl2: 0.4259763851,
        f2: 0.5109261186,
        k14: 0.1116446579,
        m29: 0.6020942408,
        t59: 0.4133489461,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.6070222822,
        f2: 0.4781491003,
        k14: 0.1209677419,
        m29: 0.5537190083,
        t59: 0.4311377246,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.5618066561,
        f2: 0.3353221957,
        k14: 0.1603888214,
        m29: 0.7731958763,
        t59: 0.4655647383,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4057084608,
        f2: 0.3932432432,
        k14: 0.1524609844,
        m29: 0.5758928571,
        t59: 0.4222222222,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.5402439024,
        f2: 0.4352517986,
        k14: 0.2035069075,
        m29: 0.6695464363,
        t59: 0.432160804,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.577540107,
        f2: 0.6405529954,
        k14: 0.1256015399,
        m29: 0.7168367347,
        t59: 0.5241935484,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '61',
        evl2: 0.4439834025,
        f2: 0.4006888634,
        k14: 0.04609475032,
        m29: 0.5882352941,
        t59: 0.4465240642,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '61',
        evl2: 0.5051958433,
        f2: 0.43359375,
        k14: 0.188336933,
        m29: 0.7261613692,
        t59: 0.5019505852,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '63',
        evl2: 0.5708661417,
        f2: 0.4696969697,
        k14: 0.1553341149,
        m29: 0.5828571429,
        t59: 0.4052532833,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '65',
        evl2: 0.4982698962,
        f2: 0.3874425727,
        k14: 0.1643956044,
        m29: 0.5778985507,
        t59: 0.4315789474,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '69',
        evl2: 0.609375,
        f2: 0.438172043,
        k14: 0.0783919598,
        m29: 0.5202702703,
        t59: 0.503875969,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '18',
        evl2: 0.2247043364,
        f2: 0.210180624,
        k14: 0.04948045522,
        m29: 0.882183908,
        t59: 0.2278481013,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.2699115044,
        f2: 0.2751322751,
        k14: 0.03401360544,
        m29: 0.8457943925,
        t59: 0.2248908297,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.2557510149,
        f2: 0.2351973684,
        k14: 0.045884579,
        m29: 0.8411552347,
        t59: 0.2180555556,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '26',
        evl2: 0.294599018,
        f2: 0.2454954955,
        k14: 0.06073153899,
        m29: 0.8233082707,
        t59: 0.2626459144,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '32',
        evl2: 0.2742175857,
        f2: 0.3359621451,
        k14: 0.04879301489,
        m29: 0.8842676311,
        t59: 0.3028485757,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '35',
        evl2: 0.3609671848,
        f2: 0.3514492754,
        k14: 0.06306818182,
        m29: 0.8068783069,
        t59: 0.2957198444,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.36,
        f2: 0.3044692737,
        k14: 0.08062015504,
        m29: 0.8378378378,
        t59: 0.25,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.3551401869,
        f2: 0.2901408451,
        k14: 0.06134453782,
        m29: 0.8546099291,
        t59: 0.2772861357,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.3535911602,
        f2: 0.3150943396,
        k14: 0.08393632417,
        m29: 0.7110481586,
        t59: 0.2696456086,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.3729032258,
        f2: 0.3395348837,
        k14: 0.1273857079,
        m29: 0.8251599147,
        t59: 0.2587719298,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.3849493488,
        f2: 0.3735177866,
        k14: 0.06856829402,
        m29: 0.7936046512,
        t59: 0.2881355932,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '46',
        evl2: 0.3611111111,
        f2: 0.3785046729,
        k14: 0.06447187929,
        m29: 0.8097014925,
        t59: 0.3233830846,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '50',
        evl2: 0.3834710744,
        f2: 0.3928571429,
        k14: 0.08106508876,
        m29: 0.7539936102,
        t59: 0.2871900826,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '51',
        evl2: 0.4156050955,
        f2: 0.4285714286,
        k14: 0.08721241306,
        m29: 0.7164634146,
        t59: 0.3409961686,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '54',
        evl2: 0.4580265096,
        f2: 0.424610052,
        k14: 0.14,
        m29: 0.7459459459,
        t59: 0.3604651163,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.4596069869,
        f2: 0.3591370558,
        k14: 0.1080882353,
        m29: 0.7085106383,
        t59: 0.3769123783,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.5431530495,
        f2: 0.5180327869,
        k14: 0.1052912881,
        m29: 0.7014084507,
        t59: 0.2801788376,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.517721519,
        f2: 0.3924914676,
        k14: 0.110617284,
        m29: 0.7201166181,
        t59: 0.3487603306,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Blood ',
        age: '65',
        evl2: 0.5462555066,
        f2: 0.5257142857,
        k14: 0.1351197263,
        m29: 0.7003154574,
        t59: 0.3611111111,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '18',
        evl2: 0.2267884323,
        f2: 0.2012195122,
        k14: 0.02471678682,
        m29: 0.9071969697,
        t59: 0.2363367799,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '24',
        evl2: 0.3205357143,
        f2: 0.150055991,
        k14: 0.0687521485,
        m29: 0.8599105812,
        t59: 0.256381798,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.2528301887,
        f2: 0.2681388013,
        k14: 0.06179321486,
        m29: 0.852233677,
        t59: 0.2376237624,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '30',
        evl2: 0.3150289017,
        f2: 0.1956124314,
        k14: 0.08261339093,
        m29: 0.8227848101,
        t59: 0.3202054795,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '32',
        evl2: 0.2858895706,
        f2: 0.2745625841,
        k14: 0.06757313556,
        m29: 0.8759791123,
        t59: 0.2871736663,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '36',
        evl2: 0.3189066059,
        f2: 0.2903225806,
        k14: 0.09670487106,
        m29: 0.7458745875,
        t59: 0.2772727273,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.540765391,
        f2: 0.3304347826,
        k14: 0.0568122041,
        m29: 0.6485148515,
        t59: 0.300872093,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.3635014837,
        f2: 0.3561320755,
        k14: 0.1196581197,
        m29: 0.6876355748,
        t59: 0.3074792244,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '44',
        evl2: 0.3884673748,
        f2: 0.3590604027,
        k14: 0.1012295082,
        m29: 0.7575057737,
        t59: 0.3461928934,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '46',
        evl2: 0.4270072993,
        f2: 0.377672209,
        k14: 0.08773630635,
        m29: 0.7378640777,
        t59: 0.3366533865,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '51',
        evl2: 0.4517857143,
        f2: 0.3640054127,
        k14: 0.11880216,
        m29: 0.6301369863,
        t59: 0.4077253219,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.3579881657,
        f2: 0.3406593407,
        k14: 0.1767406274,
        m29: 0.4519774011,
        t59: 0.4186320755,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '57',
        evl2: 0.4426433915,
        f2: 0.3488372093,
        k14: 0.1128928801,
        m29: 0.5340909091,
        t59: 0.3637931034,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4903677758,
        f2: 0.3528183716,
        k14: 0.1208141825,
        m29: 0.5481481481,
        t59: 0.351039261,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4940357853,
        f2: 0.285202864,
        k14: 0.1723689909,
        m29: 0.7219251337,
        t59: 0.3905817175,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '63',
        evl2: 0.5245231608,
        f2: 0.3694158076,
        k14: 0.1518691589,
        m29: 0.6077348066,
        t59: 0.408839779,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Saliva ',
        age: '64',
        evl2: 0.4902173913,
        f2: 0.3925811437,
        k14: 0.1158097069,
        m29: 0.5563218391,
        t59: 0.3821263482,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '19',
        evl2: 0.2202734839,
        f2: 0.1663179916,
        k14: 0.02226890756,
        m29: 0.8743455497,
        t59: 0.2009345794,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.2342026078,
        f2: 0.1300287356,
        k14: 0.02391725921,
        m29: 0.7736318408,
        t59: 0.2189001602,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.2274578243,
        f2: 0.2632890365,
        k14: 0.03026113671,
        m29: 0.8448108632,
        t59: 0.2121794872,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.2564102564,
        f2: 0.2459546926,
        k14: 0.02199710564,
        m29: 0.8077226162,
        t59: 0.2004255319,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.2195273632,
        f2: 0.2092485549,
        k14: 0.02455494168,
        m29: 0.7967479675,
        t59: 0.1932324132,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '25',
        evl2: 0.2666666667,
        f2: 0.2278787879,
        k14: 0.02947688901,
        m29: 0.7763496144,
        t59: 0.2416413374,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '25',
        evl2: 0.2705941088,
        f2: 0.2650793651,
        k14: 0.04838221953,
        m29: 0.8894736842,
        t59: 0.2037914692,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '27',
        evl2: 0.2539200809,
        f2: 0.249197861,
        k14: 0.0245545415,
        m29: 0.7424242424,
        t59: 0.2222811671,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '27',
        evl2: 0.2605561277,
        f2: 0.2347988775,
        k14: 0.02634880803,
        m29: 0.8391248391,
        t59: 0.2304609218,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '31',
        evl2: 0.2526054591,
        f2: 0.1988582303,
        k14: 0.01344187808,
        m29: 0.7596491228,
        t59: 0.1806407635,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '31',
        evl2: 0.2649918963,
        f2: 0.244870041,
        k14: 0.04242764822,
        m29: 0.7579908676,
        t59: 0.2399588054,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '33',
        evl2: 0.3254882323,
        f2: 0.2665075577,
        k14: 0.03580208974,
        m29: 0.7421052632,
        t59: 0.2501845018,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '34',
        evl2: 0.291327264,
        f2: 0.314171123,
        k14: 0.02959001783,
        m29: 0.7507919747,
        t59: 0.2318594104,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '35',
        evl2: 0.3546037296,
        f2: 0.2947719689,
        k14: 0.0431884058,
        m29: 0.73676132,
        t59: 0.2664262821,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.3280885781,
        f2: 0.2732855681,
        k14: 0.04142011834,
        m29: 0.7285714286,
        t59: 0.2706185567,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '37',
        evl2: 0.3251915709,
        f2: 0.3165340407,
        k14: 0.04802386279,
        m29: 0.7692307692,
        t59: 0.2669441141,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '39',
        evl2: 0.3792270531,
        f2: 0.387966805,
        k14: 0.0442735398,
        m29: 0.8555729984,
        t59: 0.2874418605,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.3585386576,
        f2: 0.2420091324,
        k14: 0.0385126162,
        m29: 0.7331118494,
        t59: 0.2619589977,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.359970127,
        f2: 0.2701711491,
        k14: 0.03114421124,
        m29: 0.6697848457,
        t59: 0.2666666667,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.3969309463,
        f2: 0.3231323132,
        k14: 0.02902979374,
        m29: 0.6902887139,
        t59: 0.2550554468,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.3391025641,
        f2: 0.3034482759,
        k14: 0.05470852018,
        m29: 0.7100775194,
        t59: 0.3285106383,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '42',
        evl2: 0.3554529717,
        f2: 0.3330472103,
        k14: 0.0411582886,
        m29: 0.7493946731,
        t59: 0.294279807,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '42',
        evl2: 0.3486590038,
        f2: 0.3744680851,
        k14: 0.04909038406,
        m29: 0.7574552684,
        t59: 0.2844827586,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.374595843,
        f2: 0.3241140882,
        k14: 0.03458164642,
        m29: 0.7771084337,
        t59: 0.2550287356,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.3945008966,
        f2: 0.2826718296,
        k14: 0.05822387767,
        m29: 0.6846254928,
        t59: 0.2721030043,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '47',
        evl2: 0.4217657343,
        f2: 0.3541167066,
        k14: 0.08083785881,
        m29: 0.6767241379,
        t59: 0.2887844191,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '50',
        evl2: 0.5137254902,
        f2: 0.3475670308,
        k14: 0.03835425384,
        m29: 0.4793956044,
        t59: 0.2494545455,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '50',
        evl2: 0.4166094715,
        f2: 0.3248618785,
        k14: 0.05689088708,
        m29: 0.68359375,
        t59: 0.3523639607,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.3935372187,
        f2: 0.3659022931,
        k14: 0.08954071787,
        m29: 0.8314321926,
        t59: 0.3002680965,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.4455066922,
        f2: 0.377267231,
        k14: 0.05015009712,
        m29: 0.5975609756,
        t59: 0.3043780403,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.4263848397,
        f2: 0.4093137255,
        k14: 0.07749524304,
        m29: 0.6785714286,
        t59: 0.2913043478,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '55',
        evl2: 0.4380804954,
        f2: 0.3608888889,
        k14: 0.07469852613,
        m29: 0.7292954265,
        t59: 0.3221436985,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.42578356,
        f2: 0.3661844485,
        k14: 0.09109979023,
        m29: 0.6002344666,
        t59: 0.3367626886,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.4260273973,
        f2: 0.4213793103,
        k14: 0.03724928367,
        m29: 0.6390728477,
        t59: 0.3524634543,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.4237888933,
        f2: 0.4211903205,
        k14: 0.07705597789,
        m29: 0.5896057348,
        t59: 0.3340318525,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.4461142121,
        f2: 0.3786698622,
        k14: 0.07956564659,
        m29: 0.6258012821,
        t59: 0.3297190734,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '59',
        evl2: 0.4098469727,
        f2: 0.4279877426,
        k14: 0.06205314987,
        m29: 0.6033376123,
        t59: 0.3158995816,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.4576976422,
        f2: 0.3853820598,
        k14: 0.06947669227,
        m29: 0.6934306569,
        t59: 0.3747628083,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.4087848669,
        f2: 0.3861977439,
        k14: 0.07211818076,
        m29: 0.4673337257,
        t59: 0.3542420587,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.4411860541,
        f2: 0.4367050273,
        k14: 0.09627862595,
        m29: 0.6297169811,
        t59: 0.3333333333,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.4703891709,
        f2: 0.5599489796,
        k14: 0.08354850609,
        m29: 0.6583629893,
        t59: 0.3433609959,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '61',
        evl2: 0.3533834586,
        f2: 0.3933121019,
        k14: 0.1018346493,
        m29: 0.6842560554,
        t59: 0.3224043716,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '62',
        evl2: 0.4883490332,
        f2: 0.4145150035,
        k14: 0.09265526388,
        m29: 0.6924290221,
        t59: 0.352665495,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '63',
        evl2: 0.442206655,
        f2: 0.4087959625,
        k14: 0.07648162454,
        m29: 0.6075533662,
        t59: 0.3593281344,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '63',
        evl2: 0.5025673941,
        f2: 0.3650952859,
        k14: 0.07479846703,
        m29: 0.6142857143,
        t59: 0.3296296296,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '64',
        evl2: 0.4362380446,
        f2: 0.4454775993,
        k14: 0.08914728682,
        m29: 0.4177310293,
        t59: 0.4035785288,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '64',
        evl2: 0.5344105326,
        f2: 0.4099804305,
        k14: 0.05995629609,
        m29: 0.6421636616,
        t59: 0.3256784969,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '18',
        evl2: 0.2715517241,
        f2: 0.1418367347,
        k14: 0.01826139451,
        m29: 0.7883435583,
        t59: 0.2658137882,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '21',
        evl2: 0.2923076923,
        f2: 0.1612903226,
        k14: 0.0193455671,
        m29: 0.8739583333,
        t59: 0.1845528455,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '23',
        evl2: 0.2197545295,
        f2: 0.1677559913,
        k14: 0.02223137093,
        m29: 0.8768171385,
        t59: 0.2516674077,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.3067010309,
        f2: 0.1115973742,
        k14: 0.03187635837,
        m29: 0.7460992908,
        t59: 0.2742155525,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.3891472868,
        f2: 0.07264397906,
        k14: 0.03179477915,
        m29: 0.8174665618,
        t59: 0.3336820084,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '26',
        evl2: 0.2209856916,
        f2: 0.1819772528,
        k14: 0.01885614386,
        m29: 0.7692307692,
        t59: 0.2244456463,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '26',
        evl2: 0.258097166,
        f2: 0.1890547264,
        k14: 0.01415151885,
        m29: 0.7381889764,
        t59: 0.228962818,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '27',
        evl2: 0.2317380353,
        f2: 0.1495535714,
        k14: 0.02585285256,
        m29: 0.7563559322,
        t59: 0.2125360231,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '28',
        evl2: 0.2799495586,
        f2: 0.2181208054,
        k14: 0.0551232166,
        m29: 0.7077045274,
        t59: 0.2063854048,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '29',
        evl2: 0.3153526971,
        f2: 0.1635087719,
        k14: 0.03272771026,
        m29: 0.6898470097,
        t59: 0.2461155998,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '32',
        evl2: 0.3047285464,
        f2: 0.2869440459,
        k14: 0.04299226139,
        m29: 0.7061105723,
        t59: 0.2934076137,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.2703719181,
        f2: 0.2161434978,
        k14: 0.01810766721,
        m29: 0.5623471883,
        t59: 0.3544072948,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.3259532595,
        f2: 0.1751445087,
        k14: 0.03813071453,
        m29: 0.7214870825,
        t59: 0.2656507414,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.3510324484,
        f2: 0.1994408201,
        k14: 0.05617977528,
        m29: 0.7267573696,
        t59: 0.284082798,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.305529522,
        f2: 0.1939871152,
        k14: 0.03457790808,
        m29: 0.7151238592,
        t59: 0.3129649309,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.3199541284,
        f2: 0.2388235294,
        k14: 0.03544376161,
        m29: 0.6503667482,
        t59: 0.2567463377,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.2787418655,
        f2: 0.3012048193,
        k14: 0.02904921166,
        m29: 0.7122153209,
        t59: 0.3090741594,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.2668136714,
        f2: 0.3617021277,
        k14: 0.03234631477,
        m29: 0.6897214217,
        t59: 0.2685240071,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.2914012739,
        f2: 0.2650680493,
        k14: 0.05536770921,
        m29: 0.7228915663,
        t59: 0.24650913,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.336996337,
        f2: 0.2466898955,
        k14: 0.06215542633,
        m29: 0.7161878066,
        t59: 0.3434635794,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.4200373367,
        f2: 0.2868852459,
        k14: 0.03134392443,
        m29: 0.6067270375,
        t59: 0.2446511628,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '41',
        evl2: 0.3765560166,
        f2: 0.1972176759,
        k14: 0.0725170783,
        m29: 0.6121495327,
        t59: 0.3501006036,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '41',
        evl2: 0.3497652582,
        f2: 0.2972677596,
        k14: 0.07892124421,
        m29: 0.6258411844,
        t59: 0.31953125,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.3408423115,
        f2: 0.2923336142,
        k14: 0.06483502375,
        m29: 0.6930596285,
        t59: 0.3279036827,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '44',
        evl2: 0.3447932237,
        f2: 0.3037809648,
        k14: 0.04791700091,
        m29: 0.7166064982,
        t59: 0.3506224066,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '45',
        evl2: 0.5006119951,
        f2: 0.09336332958,
        k14: 0.07135513058,
        m29: 0.7364085667,
        t59: 0.4880174292,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '47',
        evl2: 0.3767857143,
        f2: 0.2144522145,
        k14: 0.06184586108,
        m29: 0.6681087763,
        t59: 0.4079575597,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '49',
        evl2: 0.3891238671,
        f2: 0.2738372093,
        k14: 0.06680983059,
        m29: 0.594732992,
        t59: 0.3712784588,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.4307931571,
        f2: 0.2777777778,
        k14: 0.01349557522,
        m29: 0.4109042553,
        t59: 0.2754158965,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.4571619813,
        f2: 0.2039390089,
        k14: 0.05602923264,
        m29: 0.6140254003,
        t59: 0.3472823473,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.3969407266,
        f2: 0.3042665836,
        k14: 0.06662036542,
        m29: 0.6494040279,
        t59: 0.3241626794,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.3855682874,
        f2: 0.219840269,
        k14: 0.03941363574,
        m29: 0.4235145386,
        t59: 0.5148397309,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.4334027055,
        f2: 0.2980368828,
        k14: 0.07678289394,
        m29: 0.5466568699,
        t59: 0.3632097607,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '55',
        evl2: 0.4520958084,
        f2: 0.2829131653,
        k14: 0.09615384615,
        m29: 0.5,
        t59: 0.28,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '56',
        evl2: 0.3259464451,
        f2: 0.3654688869,
        k14: 0.0657285358,
        m29: 0.5303430079,
        t59: 0.4453531599,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '56',
        evl2: 0.4301594973,
        f2: 0.3559150657,
        k14: 0.06593894214,
        m29: 0.5009499683,
        t59: 0.3741148702,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '58',
        evl2: 0.4074642127,
        f2: 0.4035522511,
        k14: 0.1064369761,
        m29: 0.5710029791,
        t59: 0.4059167527,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '59',
        evl2: 0.5131086142,
        f2: 0.2743254293,
        k14: 0.1007986447,
        m29: 0.5058892815,
        t59: 0.4470734745,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '59',
        evl2: 0.3244353183,
        f2: 0.4111452391,
        k14: 0.06961402127,
        m29: 0.4714527982,
        t59: 0.3647881171,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.518766756,
        f2: 0.3871691341,
        k14: 0.07725711977,
        m29: 0.4307692308,
        t59: 0.4335088874,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4657190635,
        f2: 0.2494033413,
        k14: 0.1015940995,
        m29: 0.6591865358,
        t59: 0.4595336077,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.3042328042,
        f2: 0.289543058,
        k14: 0.08765808984,
        m29: 0.4342857143,
        t59: 0.3967242948,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4444444444,
        f2: 0.347826087,
        k14: 0.1227352425,
        m29: 0.5027985075,
        t59: 0.3945147679,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4761904762,
        f2: 0.5385714286,
        k14: 0.07760948691,
        m29: 0.5818014706,
        t59: 0.5103817816,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '61',
        evl2: 0.3443952802,
        f2: 0.3103833271,
        k14: 0.0263125386,
        m29: 0.468832309,
        t59: 0.4152208202,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '61',
        evl2: 0.4241225902,
        f2: 0.3419010669,
        k14: 0.1117349359,
        m29: 0.5795356836,
        t59: 0.4161162483,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '63',
        evl2: 0.4898314933,
        f2: 0.3757088847,
        k14: 0.09219071757,
        m29: 0.4322885866,
        t59: 0.3796928328,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '65',
        evl2: 0.4166094715,
        f2: 0.3071816536,
        k14: 0.1072489275,
        m29: 0.462398374,
        t59: 0.4264788732,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '69',
        evl2: 0.5011185682,
        f2: 0.3423799582,
        k14: 0.04478827362,
        m29: 0.3625866051,
        t59: 0.4640883978,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '18',
        evl2: 0.1819526627,
        f2: 0.1571428571,
        k14: 0.02789376983,
        m29: 0.8387096774,
        t59: 0.1950920245,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.2150537634,
        f2: 0.2236024845,
        k14: 0.02167810356,
        m29: 0.7789165447,
        t59: 0.1882845188,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.2048192771,
        f2: 0.1857021997,
        k14: 0.02617909828,
        m29: 0.7780548628,
        t59: 0.1907872121,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '26',
        evl2: 0.2354651163,
        f2: 0.1920052425,
        k14: 0.03411702316,
        m29: 0.759018759,
        t59: 0.2209976798,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '32',
        evl2: 0.2166874222,
        f2: 0.266934943,
        k14: 0.02957693748,
        m29: 0.8444216991,
        t59: 0.2529411765,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '35',
        evl2: 0.2902141957,
        f2: 0.2779012895,
        k14: 0.03740374037,
        m29: 0.7463952502,
        t59: 0.2834067548,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.2896703297,
        f2: 0.2341946124,
        k14: 0.04943908166,
        m29: 0.7601031814,
        t59: 0.2198297446,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.2916666667,
        f2: 0.2248734635,
        k14: 0.0348879248,
        m29: 0.7865168539,
        t59: 0.2585949178,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.2868369352,
        f2: 0.2569296375,
        k14: 0.01204668089,
        m29: 0.6264478764,
        t59: 0.2398210291,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.306353703,
        f2: 0.2741617357,
        k14: 0.07460890493,
        m29: 0.76520994,
        t59: 0.2343249428,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.3156836461,
        f2: 0.293956044,
        k14: 0.0447602885,
        m29: 0.6968698517,
        t59: 0.2591024555,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '46',
        evl2: 0.2907239819,
        f2: 0.3037843442,
        k14: 0.0354295837,
        m29: 0.7519607843,
        t59: 0.3033826638,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '50',
        evl2: 0.3231265109,
        f2: 0.319301848,
        k14: 0.04985864816,
        m29: 0.6712062257,
        t59: 0.2971845673,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '51',
        evl2: 0.352089367,
        f2: 0.3563090606,
        k14: 0.05232267732,
        m29: 0.6487279843,
        t59: 0.3358409457,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '54',
        evl2: 0.3913219789,
        f2: 0.3477851084,
        k14: 0.08366793272,
        m29: 0.6614678899,
        t59: 0.3688131985,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.3897596656,
        f2: 0.29784689,
        k14: 0.06432311144,
        m29: 0.6260869565,
        t59: 0.3987813135,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.4725956567,
        f2: 0.4383868559,
        k14: 0.05814873418,
        m29: 0.6077844311,
        t59: 0.3025974026,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.4520353982,
        f2: 0.3281033687,
        k14: 0.06663549217,
        m29: 0.6059443912,
        t59: 0.3484143763,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '65',
        evl2: 0.4794816415,
        f2: 0.45873105,
        k14: 0.07876030708,
        m29: 0.6025236593,
        t59: 0.389380531,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '18',
        evl2: 0.1472718493,
        f2: 0.1314060447,
        k14: 0.01417501418,
        m29: 0.8560767591,
        t59: 0.204238921,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '24',
        evl2: 0.2689460698,
        f2: 0.1162393162,
        k14: 0.03770510881,
        m29: 0.8076323988,
        t59: 0.2248824284,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.1700866393,
        f2: 0.1794871795,
        k14: 0.03477141017,
        m29: 0.8034447822,
        t59: 0.2126823793,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '30',
        evl2: 0.2535410765,
        f2: 0.1615120275,
        k14: 0.04861773117,
        m29: 0.7576887232,
        t59: 0.2904899135,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '32',
        evl2: 0.2318382817,
        f2: 0.2176917761,
        k14: 0.03949142747,
        m29: 0.8157129001,
        t59: 0.2565379826,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '36',
        evl2: 0.2656794425,
        f2: 0.2519596865,
        k14: 0.05512402907,
        m29: 0.6510172144,
        t59: 0.2526408451,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.3372310571,
        f2: 0.1813546977,
        k14: 0.03642326946,
        m29: 0.6337522442,
        t59: 0.3187335092,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.305306685,
        f2: 0.3062966916,
        k14: 0.07272727273,
        m29: 0.6049528302,
        t59: 0.2996183206,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '44',
        evl2: 0.3402515723,
        f2: 0.3057503506,
        k14: 0.05874499332,
        m29: 0.6563658838,
        t59: 0.3210239194,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '46',
        evl2: 0.3703971119,
        f2: 0.3125590179,
        k14: 0.05161072694,
        m29: 0.6418269231,
        t59: 0.3519685039,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '51',
        evl2: 0.3874538745,
        f2: 0.2942546584,
        k14: 0.06878172589,
        m29: 0.5271867612,
        t59: 0.382015691,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.2912019827,
        f2: 0.2938388626,
        k14: 0.1083949823,
        m29: 0.3757225434,
        t59: 0.4299516908,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '57',
        evl2: 0.3791366906,
        f2: 0.2972644377,
        k14: 0.06810497504,
        m29: 0.4787985866,
        t59: 0.3803230543,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4224941725,
        f2: 0.2899527984,
        k14: 0.07559354226,
        m29: 0.4363395225,
        t59: 0.3484504913,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4222560976,
        f2: 0.2277227723,
        k14: 0.1043661432,
        m29: 0.6293478261,
        t59: 0.425,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '63',
        evl2: 0.4592533119,
        f2: 0.3047571853,
        k14: 0.09435652434,
        m29: 0.5022381379,
        t59: 0.4305029746,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '64',
        evl2: 0.420995671,
        f2: 0.3301555444,
        k14: 0.06942701798,
        m29: 0.4414715719,
        t59: 0.3823677582,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '19',
        evl2: 0.151798415,
        f2: 0.1139950894,
        k14: 0.01734521802,
        m29: 0.8593913956,
        t59: 0.1497136085,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.1641921397,
        f2: 0.08881479236,
        k14: 0.02262178361,
        m29: 0.7380842203,
        t59: 0.1669989265,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.1627906977,
        f2: 0.1964199323,
        k14: 0.02667313288,
        m29: 0.8211143695,
        t59: 0.172118772,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.1740112994,
        f2: 0.1745932416,
        k14: 0.0174205379,
        m29: 0.775257732,
        t59: 0.1519685039,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.1593041886,
        f2: 0.1474071049,
        k14: 0.02056277056,
        m29: 0.7625063163,
        t59: 0.1625779626,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '25',
        evl2: 0.1893554426,
        f2: 0.1625911626,
        k14: 0.02745651982,
        m29: 0.7504363002,
        t59: 0.1970452801,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '25',
        evl2: 0.1824817518,
        f2: 0.1966192171,
        k14: 0.04084753167,
        m29: 0.8625803262,
        t59: 0.154451683,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '27',
        evl2: 0.1932876041,
        f2: 0.1845603272,
        k14: 0.02514105168,
        m29: 0.7027210884,
        t59: 0.1794303797,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '27',
        evl2: 0.1862721319,
        f2: 0.1641091219,
        k14: 0.02371985836,
        m29: 0.8062200957,
        t59: 0.1802292802,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '31',
        evl2: 0.181147541,
        f2: 0.1401869159,
        k14: 0.02057447152,
        m29: 0.7400169924,
        t59: 0.1493137719,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '31',
        evl2: 0.197725837,
        f2: 0.172143975,
        k14: 0.03732106339,
        m29: 0.7257824143,
        t59: 0.193232884,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '33',
        evl2: 0.2268258427,
        f2: 0.1826178747,
        k14: 0.02757561974,
        m29: 0.6840659341,
        t59: 0.2023134003,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '34',
        evl2: 0.2132616487,
        f2: 0.2415169661,
        k14: 0.024853097,
        m29: 0.7117988395,
        t59: 0.1909160893,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '35',
        evl2: 0.2753138075,
        f2: 0.2222222222,
        k14: 0.0371389271,
        m29: 0.7008086253,
        t59: 0.2156305506,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.2473265698,
        f2: 0.2090951711,
        k14: 0.03732359052,
        m29: 0.7088607595,
        t59: 0.2060978492,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '37',
        evl2: 0.2399702823,
        f2: 0.2422969188,
        k14: 0.04262093047,
        m29: 0.7481559536,
        t59: 0.2161422709,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '39',
        evl2: 0.2809806034,
        f2: 0.2871559633,
        k14: 0.03897401732,
        m29: 0.8330464716,
        t59: 0.2577269409,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.2743055556,
        f2: 0.1811535337,
        k14: 0.03064171795,
        m29: 0.6750313676,
        t59: 0.1950509461,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.2701019253,
        f2: 0.2005457026,
        k14: 0.02734753146,
        m29: 0.6311764706,
        t59: 0.2141125121,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.3035281444,
        f2: 0.2546055739,
        k14: 0.02691085778,
        m29: 0.6735990757,
        t59: 0.2024255788,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.258940112,
        f2: 0.2324797844,
        k14: 0.04550336881,
        m29: 0.6590509666,
        t59: 0.2636972803,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '42',
        evl2: 0.2670011148,
        f2: 0.2588339223,
        k14: 0.03752942806,
        m29: 0.7139534884,
        t59: 0.2452002194,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '42',
        evl2: 0.2649790989,
        f2: 0.2873166523,
        k14: 0.0452071163,
        m29: 0.7341288783,
        t59: 0.2647758826,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.2756426572,
        f2: 0.2452830189,
        k14: 0.03014378513,
        m29: 0.739476678,
        t59: 0.2315233786,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.3051212938,
        f2: 0.20500205,
        k14: 0.05172090699,
        m29: 0.6466028272,
        t59: 0.2475479744,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '47',
        evl2: 0.3139952153,
        f2: 0.2562111801,
        k14: 0.06562911726,
        m29: 0.6334758705,
        t59: 0.2510121457,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '50',
        evl2: 0.4111638088,
        f2: 0.2558983666,
        k14: 0.03338440448,
        m29: 0.462,
        t59: 0.2308703655,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '50',
        evl2: 0.3163756773,
        f2: 0.2338262477,
        k14: 0.04706167748,
        m29: 0.6244995997,
        t59: 0.2776704389,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.2926898915,
        f2: 0.2908917501,
        k14: 0.08043320346,
        m29: 0.7816938453,
        t59: 0.2739760875,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.3487442922,
        f2: 0.3023858921,
        k14: 0.04467392524,
        m29: 0.5409252669,
        t59: 0.2405202418,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.3386706949,
        f2: 0.3278443114,
        k14: 0.07046516568,
        m29: 0.6413356362,
        t59: 0.2305882353,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '55',
        evl2: 0.3453149002,
        f2: 0.2853375527,
        k14: 0.0683557132,
        m29: 0.7106879607,
        t59: 0.2573048408,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.3248693055,
        f2: 0.2800453515,
        k14: 0.0749461487,
        m29: 0.5465191932,
        t59: 0.2618150188,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.3205794948,
        f2: 0.3287981859,
        k14: 0.03198847262,
        m29: 0.5726062143,
        t59: 0.2777777778,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.3330396476,
        f2: 0.3434492968,
        k14: 0.071213263,
        m29: 0.557660626,
        t59: 0.2659153214,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.3606374056,
        f2: 0.295583239,
        k14: 0.07301631964,
        m29: 0.5826740506,
        t59: 0.2602339181,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '59',
        evl2: 0.3132619136,
        f2: 0.3359594823,
        k14: 0.05728965251,
        m29: 0.5654862282,
        t59: 0.2473622509,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.3548235294,
        f2: 0.3083032491,
        k14: 0.06378111274,
        m29: 0.6690290574,
        t59: 0.2971836906,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.3295324971,
        f2: 0.2939282429,
        k14: 0.06446821153,
        m29: 0.4292727831,
        t59: 0.2888116518,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.3249778826,
        f2: 0.3224073079,
        k14: 0.08298133261,
        m29: 0.553593947,
        t59: 0.2621208193,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.3601429848,
        f2: 0.4587988827,
        k14: 0.07292962809,
        m29: 0.6273516643,
        t59: 0.2760581175,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '61',
        evl2: 0.2693679092,
        f2: 0.2955082742,
        k14: 0.09229208925,
        m29: 0.6444917509,
        t59: 0.2657324167,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '62',
        evl2: 0.3811456924,
        f2: 0.3263094521,
        k14: 0.08184158534,
        m29: 0.6507311586,
        t59: 0.2784090909,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '63',
        evl2: 0.3494623656,
        f2: 0.3366255144,
        k14: 0.06904062885,
        m29: 0.5621662853,
        t59: 0.2811403509,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '63',
        evl2: 0.4094218415,
        f2: 0.2863354037,
        k14: 0.06660208283,
        m29: 0.5774818402,
        t59: 0.2698822854,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '64',
        evl2: 0.3346760658,
        f2: 0.3512778684,
        k14: 0.07400248516,
        m29: 0.3727506427,
        t59: 0.3106131341,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '64',
        evl2: 0.4123769339,
        f2: 0.2991150442,
        k14: 0.0491101717,
        m29: 0.6112759644,
        t59: 0.2346481398,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '18',
        evl2: 0.2203579418,
        f2: 0.123518403,
        k14: 0.01809909999,
        m29: 0.8482549317,
        t59: 0.2319014719,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '21',
        evl2: 0.2230408573,
        f2: 0.1227526348,
        k14: 0.01753694581,
        m29: 0.8796680498,
        t59: 0.1585447263,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '23',
        evl2: 0.1795275591,
        f2: 0.1311380705,
        k14: 0.01920176938,
        m29: 0.8767123288,
        t59: 0.1921463973,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.2548387097,
        f2: 0.1001344086,
        k14: 0.02997483864,
        m29: 0.8305509182,
        t59: 0.2151061608,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.354679803,
        f2: 0.05659472422,
        k14: 0.02859297861,
        m29: 0.8739539749,
        t59: 0.2663394472,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '26',
        evl2: 0.1863195508,
        f2: 0.1496467566,
        k14: 0.01597215112,
        m29: 0.8036253776,
        t59: 0.1844106464,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '26',
        evl2: 0.2099002302,
        f2: 0.1479736098,
        k14: 0.01138312802,
        m29: 0.7558962264,
        t59: 0.1688910459,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '27',
        evl2: 0.1808383234,
        f2: 0.109800363,
        k14: 0.02449135787,
        m29: 0.8097165992,
        t59: 0.1856362873,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '28',
        evl2: 0.2237673831,
        f2: 0.1696156193,
        k14: 0.05341987019,
        m29: 0.692195122,
        t59: 0.1672150763,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '29',
        evl2: 0.2399425287,
        f2: 0.1234424804,
        k14: 0.0294904118,
        m29: 0.672004745,
        t59: 0.1922206215,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '32',
        evl2: 0.2579681275,
        f2: 0.2561983471,
        k14: 0.04174272849,
        m29: 0.6873496873,
        t59: 0.2388912399,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.2274484536,
        f2: 0.1770833333,
        k14: 0.01957905042,
        m29: 0.4790996785,
        t59: 0.2936351706,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.2995780591,
        f2: 0.1599219893,
        k14: 0.03673897831,
        m29: 0.7396856582,
        t59: 0.2197826569,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.2998515586,
        f2: 0.1670964415,
        k14: 0.05262310911,
        m29: 0.7379912664,
        t59: 0.2051551815,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.2583201268,
        f2: 0.166271909,
        k14: 0.02860114405,
        m29: 0.676154923,
        t59: 0.2283057851,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.274559194,
        f2: 0.2172260925,
        k14: 0.03067436778,
        m29: 0.641011236,
        t59: 0.2012544314,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.2235035695,
        f2: 0.2596259626,
        k14: 0.02529595016,
        m29: 0.6684698609,
        t59: 0.2162622393,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.2102928128,
        f2: 0.3158165894,
        k14: 0.03254575707,
        m29: 0.72,
        t59: 0.215392062,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.2390151515,
        f2: 0.2292783505,
        k14: 0.04929975507,
        m29: 0.7069383794,
        t59: 0.1804008909,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.312599681,
        f2: 0.228277635,
        k14: 0.05834841044,
        m29: 0.7080670927,
        t59: 0.2660550459,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.353956366,
        f2: 0.2357536765,
        k14: 0.02454251884,
        m29: 0.5466155811,
        t59: 0.1803405573,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '41',
        evl2: 0.3155798905,
        f2: 0.1721068249,
        k14: 0.06439073855,
        m29: 0.6154949785,
        t59: 0.3043812452,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '41',
        evl2: 0.3054441261,
        f2: 0.2650684932,
        k14: 0.06651352198,
        m29: 0.5977011494,
        t59: 0.2419825073,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.2977099237,
        f2: 0.268488746,
        k14: 0.06340677055,
        m29: 0.6803730394,
        t59: 0.2693333333,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '44',
        evl2: 0.3005637773,
        f2: 0.2685950413,
        k14: 0.04239322719,
        m29: 0.693221831,
        t59: 0.2815262913,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '45',
        evl2: 0.453276699,
        f2: 0.07392550143,
        k14: 0.07318853805,
        m29: 0.7884955752,
        t59: 0.4280845262,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '47',
        evl2: 0.3493941143,
        f2: 0.1890179514,
        k14: 0.0542903752,
        m29: 0.6679012346,
        t59: 0.3211996337,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '49',
        evl2: 0.3444584383,
        f2: 0.2309499832,
        k14: 0.06217858813,
        m29: 0.571722013,
        t59: 0.2900851162,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.3784386617,
        f2: 0.2367288379,
        k14: 0.01027449778,
        m29: 0.3115896137,
        t59: 0.1920860546,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.4234625668,
        f2: 0.1813913786,
        k14: 0.04673162426,
        m29: 0.5964696223,
        t59: 0.2722651934,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.3583354739,
        f2: 0.2703523694,
        k14: 0.06098006281,
        m29: 0.6303821964,
        t59: 0.2661647476,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.325039185,
        f2: 0.1848457073,
        k14: 0.03794428434,
        m29: 0.4116135663,
        t59: 0.407451636,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.3832136941,
        f2: 0.2682119205,
        k14: 0.07287985866,
        m29: 0.5305732484,
        t59: 0.3070527523,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '55',
        evl2: 0.3869680851,
        f2: 0.245416079,
        k14: 0.08374063213,
        m29: 0.5173010381,
        t59: 0.2207171315,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '56',
        evl2: 0.3061877667,
        f2: 0.3417180826,
        k14: 0.06177422425,
        m29: 0.5231879079,
        t59: 0.3451223106,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '56',
        evl2: 0.3442447008,
        f2: 0.2788539144,
        k14: 0.05929416922,
        m29: 0.4743967039,
        t59: 0.2925606018,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '58',
        evl2: 0.3431225652,
        f2: 0.3503744709,
        k14: 0.08841876865,
        m29: 0.5434298441,
        t59: 0.3071881607,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '59',
        evl2: 0.4459613197,
        f2: 0.2397399431,
        k14: 0.08929103366,
        m29: 0.4739173228,
        t59: 0.3693931398,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '59',
        evl2: 0.2881226054,
        f2: 0.3729056965,
        k14: 0.06407787762,
        m29: 0.4451764706,
        t59: 0.30324797,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4429941396,
        f2: 0.3244607618,
        k14: 0.06516290727,
        m29: 0.3811533052,
        t59: 0.3229076166,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4123684886,
        f2: 0.2127659574,
        k14: 0.09327513741,
        m29: 0.6387370978,
        t59: 0.3618065783,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.2573913043,
        f2: 0.2556443209,
        k14: 0.09127666026,
        m29: 0.4171717172,
        t59: 0.3292743539,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.38432,
        f2: 0.2984224365,
        k14: 0.1173124778,
        m29: 0.4918437963,
        t59: 0.3154121864,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4285246974,
        f2: 0.5078014184,
        k14: 0.06582411796,
        m29: 0.5215745647,
        t59: 0.4051148999,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '61',
        evl2: 0.2993710692,
        f2: 0.2567287785,
        k14: 0.02144607843,
        m29: 0.4351092896,
        t59: 0.3429661941,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '61',
        evl2: 0.3475908271,
        f2: 0.2875,
        k14: 0.108752086,
        m29: 0.5531295488,
        t59: 0.3785880528,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '63',
        evl2: 0.4320269443,
        f2: 0.3343140861,
        k14: 0.09097627063,
        m29: 0.4024831868,
        t59: 0.3150048876,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '65',
        evl2: 0.3511821975,
        f2: 0.2613344739,
        k14: 0.08825543571,
        m29: 0.3954002654,
        t59: 0.3200522534,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '69',
        evl2: 0.3983244478,
        f2: 0.2808098592,
        k14: 0.04047503635,
        m29: 0.3558792924,
        t59: 0.3925675676,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '18',
        evl2: 0.1305128205,
        f2: 0.1210804098,
        k14: 0.02558817258,
        m29: 0.8152101401,
        t59: 0.1627807487,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.1594098597,
        f2: 0.1706140351,
        k14: 0.02007656316,
        m29: 0.7431340872,
        t59: 0.1602096627,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.1460982299,
        f2: 0.1347878063,
        k14: 0.02356532357,
        m29: 0.7377567141,
        t59: 0.1584470094,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '26',
        evl2: 0.1708452722,
        f2: 0.1392640894,
        k14: 0.02992079789,
        m29: 0.7368881119,
        t59: 0.1822197681,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '32',
        evl2: 0.1578270653,
        f2: 0.2052431873,
        k14: 0.02418196932,
        m29: 0.8223495702,
        t59: 0.2159423553,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '35',
        evl2: 0.2204488778,
        f2: 0.2065989848,
        k14: 0.03293821462,
        m29: 0.7062992126,
        t59: 0.2258831522,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.223505631,
        f2: 0.1714579055,
        k14: 0.04202655666,
        m29: 0.7414259928,
        t59: 0.1800040725,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.2170291532,
        f2: 0.1662198391,
        k14: 0.03294995337,
        m29: 0.7577807848,
        t59: 0.2057900244,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.2163852408,
        f2: 0.1885714286,
        k14: 0.04236050248,
        m29: 0.5948717949,
        t59: 0.2028716999,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.2301953819,
        f2: 0.204874019,
        k14: 0.06723453523,
        m29: 0.7289353364,
        t59: 0.190260777,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.2335943829,
        f2: 0.2264014467,
        k14: 0.04134640869,
        m29: 0.6859688196,
        t59: 0.2234234234,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '46',
        evl2: 0.2250886811,
        f2: 0.2382075472,
        k14: 0.0352541642,
        m29: 0.7259062776,
        t59: 0.2435107376,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '50',
        evl2: 0.2390873016,
        f2: 0.252866242,
        k14: 0.04541027514,
        m29: 0.6559766764,
        t59: 0.2386363636,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '51',
        evl2: 0.266322217,
        f2: 0.2753762408,
        k14: 0.04667891032,
        m29: 0.6005154639,
        t59: 0.2649761117,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '54',
        evl2: 0.2987106017,
        f2: 0.2691069992,
        k14: 0.07471810895,
        m29: 0.626911315,
        t59: 0.291368884,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.3048648649,
        f2: 0.2218377547,
        k14: 0.05422731729,
        m29: 0.5696452037,
        t59: 0.3006204174,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.3822021735,
        f2: 0.3531409168,
        k14: 0.05245051041,
        m29: 0.5648401826,
        t59: 0.2299909393,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.3571017826,
        f2: 0.2544378698,
        k14: 0.06248244875,
        m29: 0.5869297164,
        t59: 0.2755803955,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '65',
        evl2: 0.3959260126,
        f2: 0.3710710128,
        k14: 0.07621130552,
        m29: 0.5621431708,
        t59: 0.3058061985,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '18',
        evl2: 0.1205176177,
        f2: 0.1062753036,
        k14: 0.01308739929,
        m29: 0.8404829181,
        t59: 0.1672854089,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '24',
        evl2: 0.1925925926,
        f2: 0.07985442089,
        k14: 0.03354465133,
        m29: 0.7776975821,
        t59: 0.1812142747,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.1405124099,
        f2: 0.1476814516,
        k14: 0.03275332651,
        m29: 0.7855133615,
        t59: 0.1661228122,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '30',
        evl2: 0.1858844684,
        f2: 0.114640884,
        k14: 0.04543840966,
        m29: 0.7276011561,
        t59: 0.2431954754,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '32',
        evl2: 0.1684044855,
        f2: 0.1578947368,
        k14: 0.03508373803,
        m29: 0.7932330827,
        t59: 0.2113920943,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '36',
        evl2: 0.1922863485,
        f2: 0.1766143106,
        k14: 0.05498075072,
        m29: 0.6401706088,
        t59: 0.2053630913,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.3585763982,
        f2: 0.188069594,
        k14: 0.02941812676,
        m29: 0.5172176309,
        t59: 0.2152375435,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.2231759657,
        f2: 0.2177841179,
        k14: 0.06779661017,
        m29: 0.5654147751,
        t59: 0.2406694561,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '44',
        evl2: 0.240284006,
        f2: 0.22,
        k14: 0.0534180574,
        m29: 0.647161066,
        t59: 0.2654241645,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '46',
        evl2: 0.2790197764,
        f2: 0.2261360581,
        k14: 0.04495007609,
        m29: 0.6236374068,
        t59: 0.2701704545,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '51',
        evl2: 0.3045777557,
        f2: 0.2262654537,
        k14: 0.06294398509,
        m29: 0.4790830946,
        t59: 0.3189985625,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.2213740458,
        f2: 0.1958456973,
        k14: 0.1025272832,
        m29: 0.331639136,
        t59: 0.3513356562,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '57',
        evl2: 0.2905377808,
        f2: 0.2241492865,
        k14: 0.05862068966,
        m29: 0.398488121,
        t59: 0.2935940977,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.3380493679,
        f2: 0.2202842377,
        k14: 0.06867940315,
        m29: 0.4017180394,
        t59: 0.2879542061,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.3314337816,
        f2: 0.172224869,
        k14: 0.09175617963,
        m29: 0.5945205479,
        t59: 0.3326720889,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '63',
        evl2: 0.3709499669,
        f2: 0.2289433384,
        k14: 0.08330859213,
        m29: 0.473486273,
        t59: 0.3432392273,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '64',
        evl2: 0.3380809595,
        f2: 0.2504950495,
        k14: 0.06233062331,
        m29: 0.4187919463,
        t59: 0.2993916106,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.2770711585,
        f2: 0.261010101,
        k14: 0.02923664122,
        m29: 0.8597904915,
        t59: 0.2345444685,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '26',
        evl2: 0.2936012485,
        f2: 0.2215049425,
        k14: 0.03515385703,
        m29: 0.8187194652,
        t59: 0.2567081152,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.2900232019,
        f2: 0.2445500279,
        k14: 0.02603867509,
        m29: 0.8620567376,
        t59: 0.2265168539,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '18',
        evl2: 0.2372881356,
        f2: 0.2264560099,
        k14: 0.0174565694,
        m29: 0.8809953119,
        t59: 0.2113415595,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '25',
        evl2: 0.3263943441,
        f2: 0.2705536913,
        k14: 0.02535033087,
        m29: 0.8326065891,
        t59: 0.2148038246,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '21',
        evl2: 0.2955450924,
        f2: 0.2427307206,
        k14: 0.03584661784,
        m29: 0.8974254189,
        t59: 0.2662051605,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '37',
        evl2: 0.4003407155,
        f2: 0.3518099548,
        k14: 0.04196244047,
        m29: 0.8533906399,
        t59: 0.2603342128,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.3488316643,
        f2: 0.3282650014,
        k14: 0.04389096926,
        m29: 0.8123736353,
        t59: 0.2701840491,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '38',
        evl2: 0.3514727908,
        f2: 0.2920594634,
        k14: 0.0593107809,
        m29: 0.7720251716,
        t59: 0.3131008483,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '31',
        evl2: 0.3677451223,
        f2: 0.2998060405,
        k14: 0.01677331782,
        m29: 0.8531317495,
        t59: 0.2852339591,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '30',
        evl2: 0.352297593,
        f2: 0.2262662255,
        k14: 0.03411306043,
        m29: 0.7896551724,
        t59: 0.2230119947,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '30',
        evl2: 0.3667455933,
        f2: 0.304696449,
        k14: 0.03311140701,
        m29: 0.8656774697,
        t59: 0.2761314655,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '31',
        evl2: 0.3217791411,
        f2: 0.2687562521,
        k14: 0.03162713275,
        m29: 0.7580347248,
        t59: 0.2826019297,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.4093054619,
        f2: 0.3403263403,
        k14: 0.04840764331,
        m29: 0.7909090909,
        t59: 0.2808282922,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.4244453746,
        f2: 0.3081238779,
        k14: 0.05767894371,
        m29: 0.6576489533,
        t59: 0.3226857888,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '45',
        evl2: 0.4438787318,
        f2: 0.3718564566,
        k14: 0.06824871229,
        m29: 0.7390691114,
        t59: 0.3259462151,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '48',
        evl2: 0.4159256265,
        f2: 0.3790511977,
        k14: 0.05180921053,
        m29: 0.7792998478,
        t59: 0.3192853871,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '45',
        evl2: 0.3743863394,
        f2: 0.3075476134,
        k14: 0.04737099137,
        m29: 0.8000589797,
        t59: 0.3103218646,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '49',
        evl2: 0.471339779,
        f2: 0.4656584751,
        k14: 0.08888609505,
        m29: 0.6920214003,
        t59: 0.3579281184,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '46',
        evl2: 0.4337016575,
        f2: 0.3199179698,
        k14: 0.04622705642,
        m29: 0.7270471464,
        t59: 0.3321581466,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.5270502362,
        f2: 0.3930041152,
        k14: 0.06951327133,
        m29: 0.5750255189,
        t59: 0.386717108,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.46360501,
        f2: 0.3996366939,
        k14: 0.08450071839,
        m29: 0.6444794953,
        t59: 0.3592342342,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '53',
        evl2: 0.4765921552,
        f2: 0.3225276016,
        k14: 0.04572012854,
        m29: 0.7747163695,
        t59: 0.3185860382,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '53',
        evl2: 0.4870627199,
        f2: 0.3561032864,
        k14: 0.07661069895,
        m29: 0.6902305825,
        t59: 0.2864134234,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '55',
        evl2: 0.4751940601,
        f2: 0.4448542973,
        k14: 0.08124352332,
        m29: 0.6490583372,
        t59: 0.3363052782,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '70',
        evl2: 0.5222997252,
        f2: 0.5693411265,
        k14: 0.08270804911,
        m29: 0.7227919285,
        t59: 0.4067614704,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.4400681286,
        f2: 0.4306688418,
        k14: 0.06347068857,
        m29: 0.7166608937,
        t59: 0.3623804463,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '66',
        evl2: 0.3985507246,
        f2: 0.3696763203,
        k14: 0.1000134789,
        m29: 0.5993476488,
        t59: 0.4269398724,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.2569279493,
        f2: 0.2152119701,
        k14: 0.01849710983,
        m29: 0.8588868941,
        t59: 0.2202189311,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.2515048909,
        f2: 0.1901291129,
        k14: 0.02160635211,
        m29: 0.8418134377,
        t59: 0.2401782098,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '21',
        evl2: 0.2581624905,
        f2: 0.2583843686,
        k14: 0.01784833843,
        m29: 0.8439504062,
        t59: 0.219809322,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.2918814433,
        f2: 0.2971246006,
        k14: 0.03387498925,
        m29: 0.8579288026,
        t59: 0.225101397,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '28',
        evl2: 0.3289597001,
        f2: 0.2583192329,
        k14: 0.02157598499,
        m29: 0.7946393378,
        t59: 0.2726777445,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '19',
        evl2: 0.2668491997,
        f2: 0.1963818139,
        k14: 0.02192907315,
        m29: 0.8829201102,
        t59: 0.1781206171,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '27',
        evl2: 0.3398415393,
        f2: 0.2599219258,
        k14: 0.02759637666,
        m29: 0.787037037,
        t59: 0.2427025254,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '26',
        evl2: 0.3106981177,
        f2: 0.2653174383,
        k14: 0.03821923562,
        m29: 0.8514777404,
        t59: 0.2481634527,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.2952560774,
        f2: 0.3153538813,
        k14: 0.01977796436,
        m29: 0.8685620107,
        t59: 0.2738990333,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '37',
        evl2: 0.3436293436,
        f2: 0.3127106403,
        k14: 0.03239306939,
        m29: 0.7815360127,
        t59: 0.2687263836,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '33',
        evl2: 0.3544949027,
        f2: 0.2736975858,
        k14: 0.03281677302,
        m29: 0.8288381743,
        t59: 0.2675771371,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '39',
        evl2: 0.3791255894,
        f2: 0.3186199899,
        k14: 0.05614949241,
        m29: 0.7140883978,
        t59: 0.2685979143,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '38',
        evl2: 0.4082191781,
        f2: 0.3537474482,
        k14: 0.05019149365,
        m29: 0.7691648822,
        t59: 0.3280091272,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '30',
        evl2: 0.3315899582,
        f2: 0.2811455847,
        k14: 0.02998685508,
        m29: 0.8759618344,
        t59: 0.2415981199,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '38',
        evl2: 0.4114432658,
        f2: 0.4031100478,
        k14: 0.05459731088,
        m29: 0.8403203015,
        t59: 0.2821687667,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '30',
        evl2: 0.3418762089,
        f2: 0.3495603015,
        k14: 0.04233637987,
        m29: 0.8521560575,
        t59: 0.292543021,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '34',
        evl2: 0.3391608392,
        f2: 0.2902446391,
        k14: 0.02799897251,
        m29: 0.7877030162,
        t59: 0.2823021583,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.4161462979,
        f2: 0.3385563833,
        k14: 0.04641423835,
        m29: 0.7524831148,
        t59: 0.2785087719,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '33',
        evl2: 0.3842256004,
        f2: 0.3367269025,
        k14: 0.03715937242,
        m29: 0.8193244305,
        t59: 0.2773809524,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '42',
        evl2: 0.4127469426,
        f2: 0.3940217391,
        k14: 0.03608502224,
        m29: 0.7062279671,
        t59: 0.3084774596,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.3864349505,
        f2: 0.3745338306,
        k14: 0.04013088249,
        m29: 0.7297745358,
        t59: 0.3630116522,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.4427275781,
        f2: 0.3343911146,
        k14: 0.0582409809,
        m29: 0.7541874151,
        t59: 0.3490981964,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '46',
        evl2: 0.4212831744,
        f2: 0.3595505618,
        k14: 0.05966943692,
        m29: 0.7476048893,
        t59: 0.3515306122,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '42',
        evl2: 0.3815879535,
        f2: 0.3181818182,
        k14: 0.05006981847,
        m29: 0.7068902661,
        t59: 0.316323572,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.3928748144,
        f2: 0.4262101535,
        k14: 0.03871499176,
        m29: 0.8020795984,
        t59: 0.3197689682,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.4922784509,
        f2: 0.4294397463,
        k14: 0.07322753148,
        m29: 0.6703332255,
        t59: 0.3993348683,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '53',
        evl2: 0.4095991274,
        f2: 0.4058109834,
        k14: 0.05954267602,
        m29: 0.7139077853,
        t59: 0.3604577241,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '58',
        evl2: 0.4448928904,
        f2: 0.476485497,
        k14: 0.07248891459,
        m29: 0.6537460226,
        t59: 0.3764172336,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.4682517483,
        f2: 0.3701618578,
        k14: 0.06409212516,
        m29: 0.6834504294,
        t59: 0.3699070765,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.5016286645,
        f2: 0.4107572115,
        k14: 0.04788183736,
        m29: 0.6624441133,
        t59: 0.3780174628,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '59',
        evl2: 0.5300792122,
        f2: 0.4359195402,
        k14: 0.08819172113,
        m29: 0.6808312655,
        t59: 0.4052151239,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.4668470907,
        f2: 0.3695652174,
        k14: 0.04422198464,
        m29: 0.6573258607,
        t59: 0.3967223253,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '63',
        evl2: 0.49889989,
        f2: 0.5003272251,
        k14: 0.08948339483,
        m29: 0.6817683595,
        t59: 0.3921927417,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '62',
        evl2: 0.5594603075,
        f2: 0.5100371747,
        k14: 0.1102635229,
        m29: 0.6452959029,
        t59: 0.3538041624,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.474241557,
        f2: 0.4788021534,
        k14: 0.08329035585,
        m29: 0.6707543664,
        t59: 0.3613053613,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.5483784508,
        f2: 0.4858064516,
        k14: 0.05617347416,
        m29: 0.5407204385,
        t59: 0.3370069606,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '64',
        evl2: 0.5556211272,
        f2: 0.4017152659,
        k14: 0.046428867,
        m29: 0.5987770461,
        t59: 0.3512956419,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.5537609599,
        f2: 0.4562726176,
        k14: 0.1213443506,
        m29: 0.6199004975,
        t59: 0.4673764674,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.3140458702,
        f2: 0.2499124343,
        k14: 0.02882784879,
        m29: 0.9213483146,
        t59: 0.2394822006,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.3249133234,
        f2: 0.1902241594,
        k14: 0.02540937324,
        m29: 0.886214442,
        t59: 0.2262118492,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '35',
        evl2: 0.4032913843,
        f2: 0.3522949219,
        k14: 0.04860783388,
        m29: 0.8809693514,
        t59: 0.2582205029,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '46',
        evl2: 0.4001633987,
        f2: 0.3605645851,
        k14: 0.04979514655,
        m29: 0.6844802343,
        t59: 0.2417205938,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '47',
        evl2: 0.4102107857,
        f2: 0.3943035891,
        k14: 0.05921375921,
        m29: 0.7475247525,
        t59: 0.3250697242,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.4297912713,
        f2: 0.3445147679,
        k14: 0.05700035625,
        m29: 0.806577917,
        t59: 0.2574041812,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '58',
        evl2: 0.5351480286,
        f2: 0.3464453266,
        k14: 0.0626911315,
        m29: 0.6784741144,
        t59: 0.2871814378,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.5718298555,
        f2: 0.5011501807,
        k14: 0.10109432,
        m29: 0.6849710983,
        t59: 0.3043852107,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '53',
        evl2: 0.5256926952,
        f2: 0.5450606586,
        k14: 0.07178968655,
        m29: 0.3827225131,
        t59: 0.3503314635,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '58',
        evl2: 0.52245671,
        f2: 0.4682284981,
        k14: 0.09558117195,
        m29: 0.671559633,
        t59: 0.3397014925,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '61',
        evl2: 0.4961965135,
        f2: 0.4502303225,
        k14: 0.070599022,
        m29: 0.6932397959,
        t59: 0.3127930342,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '63',
        evl2: 0.5107697181,
        f2: 0.478493558,
        k14: 0.06367620521,
        m29: 0.7399764151,
        t59: 0.3464027408,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '61',
        evl2: 0.5706600756,
        f2: 0.4973623475,
        k14: 0.07203615098,
        m29: 0.5879828326,
        t59: 0.4694501018,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '69',
        evl2: 0.5104910714,
        f2: 0.4290843806,
        k14: 0.0288248337,
        m29: 0.58492569,
        t59: 0.3669454009,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '65',
        evl2: 0.4725965858,
        f2: 0.5044941671,
        k14: 0.09013980868,
        m29: 0.7037037037,
        t59: 0.4175482265,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '66',
        evl2: 0.5036259065,
        f2: 0.4948875256,
        k14: 0.06615897192,
        m29: 0.5110584518,
        t59: 0.3368816531,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '65',
        evl2: 0.5617391304,
        f2: 0.4292095553,
        k14: 0.1038525963,
        m29: 0.6909952607,
        t59: 0.386577708,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Blood ',
        age: '62',
        evl2: 0.4975358903,
        f2: 0.5136668386,
        k14: 0.09879518072,
        m29: 0.5744360902,
        t59: 0.4387539599,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '21',
        evl2: 0.3569251609,
        f2: 0.07212622089,
        k14: 0.0293875309,
        m29: 0.9166666667,
        t59: 0.3187576624,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '39',
        evl2: 0.4455006337,
        f2: 0.2090301003,
        k14: 0.04282917279,
        m29: 0.7283328081,
        t59: 0.401981387,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.3565708902,
        f2: 0.222699914,
        k14: 0.02602760854,
        m29: 0.8627581612,
        t59: 0.2950017476,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '26',
        evl2: 0.3619277108,
        f2: 0.1542713568,
        k14: 0.02520231214,
        m29: 0.7633663366,
        t59: 0.274702747,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '21',
        evl2: 0.2874385338,
        f2: 0.2284894837,
        k14: 0.02107864515,
        m29: 0.8454894434,
        t59: 0.2257165605,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '21',
        evl2: 0.2782685512,
        f2: 0.1978239367,
        k14: 0.02340119495,
        m29: 0.8486445783,
        t59: 0.2729741965,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '28',
        evl2: 0.3646796302,
        f2: 0.2345234196,
        k14: 0.03435180992,
        m29: 0.8544165758,
        t59: 0.2856703567,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.3907621247,
        f2: 0.1215767635,
        k14: 0.03863995514,
        m29: 0.8554385965,
        t59: 0.3010224949,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '23',
        evl2: 0.3191135734,
        f2: 0.1113153059,
        k14: 0.02964341085,
        m29: 0.8443708609,
        t59: 0.3179252889,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '23',
        evl2: 0.3961558996,
        f2: 0.1372651357,
        k14: 0.03418459682,
        m29: 0.8859259259,
        t59: 0.274705349,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '19',
        evl2: 0.3408320493,
        f2: 0.2256378727,
        k14: 0.03844412717,
        m29: 0.8711204629,
        t59: 0.2724881641,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '24',
        evl2: 0.2951310861,
        f2: 0.24735987,
        k14: 0.02863476815,
        m29: 0.8243335612,
        t59: 0.2906666667,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '23',
        evl2: 0.3318692045,
        f2: 0.1662741404,
        k14: 0.02802577899,
        m29: 0.836565097,
        t59: 0.2463768116,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '22',
        evl2: 0.3665048544,
        f2: 0.1254582485,
        k14: 0.03786672147,
        m29: 0.8714991763,
        t59: 0.2642733564,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '27',
        evl2: 0.3371900826,
        f2: 0.2090108401,
        k14: 0.03531654085,
        m29: 0.8253687316,
        t59: 0.2560251484,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '19',
        evl2: 0.3178045515,
        f2: 0.1613247863,
        k14: 0.03374120628,
        m29: 0.8638768639,
        t59: 0.2487691862,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '24',
        evl2: 0.2794566121,
        f2: 0.1898660454,
        k14: 0.02630932753,
        m29: 0.8533333333,
        t59: 0.2596183922,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.3577235772,
        f2: 0.2478510029,
        k14: 0.03139643861,
        m29: 0.8409475465,
        t59: 0.2639720559,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '30',
        evl2: 0.4139841689,
        f2: 0.1437535653,
        k14: 0.03786713053,
        m29: 0.8675847458,
        t59: 0.3565608317,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.4166403037,
        f2: 0.2588429752,
        k14: 0.03339744711,
        m29: 0.7250351617,
        t59: 0.3155893536,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '38',
        evl2: 0.4693110647,
        f2: 0.2379014021,
        k14: 0.04752591434,
        m29: 0.7937095282,
        t59: 0.3351765206,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '34',
        evl2: 0.3838087248,
        f2: 0.2923519737,
        k14: 0.02656019443,
        m29: 0.7607811229,
        t59: 0.3331918506,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '31',
        evl2: 0.3965183752,
        f2: 0.24,
        k14: 0.02869554577,
        m29: 0.7756232687,
        t59: 0.2926556828,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '31',
        evl2: 0.4279163084,
        f2: 0.2516964837,
        k14: 0.05841032297,
        m29: 0.8513896767,
        t59: 0.3497626871,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '34',
        evl2: 0.4539027982,
        f2: 0.3143507973,
        k14: 0.0362551469,
        m29: 0.7929292929,
        t59: 0.3310116086,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '31',
        evl2: 0.4336642599,
        f2: 0.1891625616,
        k14: 0.03152891471,
        m29: 0.8345323741,
        t59: 0.3360910031,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.447313743,
        f2: 0.3249164501,
        k14: 0.0522179532,
        m29: 0.7664027149,
        t59: 0.3449750535,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '36',
        evl2: 0.4313653137,
        f2: 0.2068673167,
        k14: 0.03665022725,
        m29: 0.6919831224,
        t59: 0.3209016393,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.4094452086,
        f2: 0.2773859897,
        k14: 0.0436111566,
        m29: 0.8449396472,
        t59: 0.3151807229,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '31',
        evl2: 0.3383035933,
        f2: 0.2201128784,
        k14: 0.03168612027,
        m29: 0.8102803738,
        t59: 0.2814893055,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '33',
        evl2: 0.419826814,
        f2: 0.2370424067,
        k14: 0.05819112628,
        m29: 0.8207847296,
        t59: 0.3003676471,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '38',
        evl2: 0.4457865976,
        f2: 0.1656655074,
        k14: 0.04294905234,
        m29: 0.7965271594,
        t59: 0.3546146212,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '34',
        evl2: 0.494583489,
        f2: 0.1844345359,
        k14: 0.05514839649,
        m29: 0.7934336525,
        t59: 0.3693023256,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '31',
        evl2: 0.3763684409,
        f2: 0.3425886373,
        k14: 0.03907944235,
        m29: 0.7709414381,
        t59: 0.2907385698,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '36',
        evl2: 0.3957142857,
        f2: 0.2936315022,
        k14: 0.05958932481,
        m29: 0.7317757009,
        t59: 0.2925373134,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.4788902292,
        f2: 0.2750766535,
        k14: 0.04574767366,
        m29: 0.726744186,
        t59: 0.3411714408,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '39',
        evl2: 0.465,
        f2: 0.3262411348,
        k14: 0.03705853895,
        m29: 0.7517361111,
        t59: 0.3460642757,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '41',
        evl2: 0.4147874307,
        f2: 0.3826550019,
        k14: 0.07208436725,
        m29: 0.6964285714,
        t59: 0.3606415258,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '46',
        evl2: 0.4799235182,
        f2: 0.3256637168,
        k14: 0.04638179733,
        m29: 0.7103882476,
        t59: 0.3799939006,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.4289035258,
        f2: 0.3224222586,
        k14: 0.0390511611,
        m29: 0.6666666667,
        t59: 0.3228114478,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.410743531,
        f2: 0.3266102798,
        k14: 0.05342063167,
        m29: 0.7788404065,
        t59: 0.3393325834,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '47',
        evl2: 0.4059196617,
        f2: 0.3708609272,
        k14: 0.06200429429,
        m29: 0.6935804103,
        t59: 0.3583718003,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.4666902906,
        f2: 0.4005102041,
        k14: 0.05923223033,
        m29: 0.7550312283,
        t59: 0.3601309567,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.4848278213,
        f2: 0.2543703175,
        k14: 0.06365056981,
        m29: 0.7210300429,
        t59: 0.375464684,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '44',
        evl2: 0.4859898944,
        f2: 0.3676865271,
        k14: 0.08415589702,
        m29: 0.6193181818,
        t59: 0.306741573,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '45',
        evl2: 0.3691394659,
        f2: 0.3046163849,
        k14: 0.05040575312,
        m29: 0.7453551913,
        t59: 0.3087079535,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '44',
        evl2: 0.5166816952,
        f2: 0.2880053458,
        k14: 0.06670841314,
        m29: 0.7573570239,
        t59: 0.3822742475,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '46',
        evl2: 0.4752392842,
        f2: 0.3015805211,
        k14: 0.07240767618,
        m29: 0.8056910569,
        t59: 0.3492975734,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '47',
        evl2: 0.4842604571,
        f2: 0.2127659574,
        k14: 0.05545380545,
        m29: 0.7844762622,
        t59: 0.3674732696,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.4229724486,
        f2: 0.2684791844,
        k14: 0.04384545363,
        m29: 0.7840059791,
        t59: 0.3552371542,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.440926276,
        f2: 0.3155267099,
        k14: 0.04525139665,
        m29: 0.6987315011,
        t59: 0.3476909414,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '56',
        evl2: 0.4962006079,
        f2: 0.3480542195,
        k14: 0.09674214297,
        m29: 0.6526748971,
        t59: 0.3712121212,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '57',
        evl2: 0.5197950629,
        f2: 0.409293129,
        k14: 0.09860837365,
        m29: 0.5792682927,
        t59: 0.4080841639,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '53',
        evl2: 0.4648127128,
        f2: 0.4118584635,
        k14: 0.08361944844,
        m29: 0.6526867628,
        t59: 0.3707776905,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '51',
        evl2: 0.5177544809,
        f2: 0.1918117344,
        k14: 0.04447788354,
        m29: 0.7834285714,
        t59: 0.4728652751,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '57',
        evl2: 0.4778028593,
        f2: 0.4354366903,
        k14: 0.09159902013,
        m29: 0.5983167559,
        t59: 0.3854460094,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '58',
        evl2: 0.4857048965,
        f2: 0.4251407129,
        k14: 0.09663742076,
        m29: 0.6494512589,
        t59: 0.4024550636,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '56',
        evl2: 0.497311828,
        f2: 0.4202197802,
        k14: 0.06125490586,
        m29: 0.5694347523,
        t59: 0.3645983646,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.5104347826,
        f2: 0.416500994,
        k14: 0.06693356687,
        m29: 0.6084275437,
        t59: 0.3700552956,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '55',
        evl2: 0.4987593052,
        f2: 0.4153400868,
        k14: 0.07292443914,
        m29: 0.4666666667,
        t59: 0.3774373259,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.4647348278,
        f2: 0.3924290221,
        k14: 0.06782259967,
        m29: 0.649262202,
        t59: 0.4067388688,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '53',
        evl2: 0.6210464085,
        f2: 0.1765241128,
        k14: 0.08742550002,
        m29: 0.7302177377,
        t59: 0.5340735069,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '55',
        evl2: 0.4465020576,
        f2: 0.4350746269,
        k14: 0.08209345794,
        m29: 0.592926491,
        t59: 0.3953665021,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.5518154312,
        f2: 0.5017226529,
        k14: 0.110870002,
        m29: 0.5502606106,
        t59: 0.4439481912,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '51',
        evl2: 0.4977296542,
        f2: 0.2920120573,
        k14: 0.07257035966,
        m29: 0.5122739018,
        t59: 0.4057971014,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '58',
        evl2: 0.6334770678,
        f2: 0.4445849136,
        k14: 0.1013656799,
        m29: 0.5685897436,
        t59: 0.4288443171,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '53',
        evl2: 0.5015649452,
        f2: 0.3603029095,
        k14: 0.1288175411,
        m29: 0.632176235,
        t59: 0.4280442804,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.447858473,
        f2: 0.4116766467,
        k14: 0.04382400489,
        m29: 0.6678667867,
        t59: 0.3677359368,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '62',
        evl2: 0.5385896724,
        f2: 0.4405594406,
        k14: 0.1090408903,
        m29: 0.560483871,
        t59: 0.4039653036,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.5628201265,
        f2: 0.2605832549,
        k14: 0.0918231967,
        m29: 0.7008072654,
        t59: 0.4799220273,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.5905730129,
        f2: 0.3802816901,
        k14: 0.06868235828,
        m29: 0.5043256997,
        t59: 0.4647398844,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '63',
        evl2: 0.4944649446,
        f2: 0.4475183076,
        k14: 0.07808332121,
        m29: 0.6316770186,
        t59: 0.4076809453,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '65',
        evl2: 0.5702930949,
        f2: 0.3985317252,
        k14: 0.1023255814,
        m29: 0.4005235602,
        t59: 0.493559719,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '69',
        evl2: 0.6817800168,
        f2: 0.1884346959,
        k14: 0.0952637825,
        m29: 0.6760124611,
        t59: 0.5943536404,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '63',
        evl2: 0.6276595745,
        f2: 0.3407550823,
        k14: 0.07415548876,
        m29: 0.6006683375,
        t59: 0.4434611603,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '68',
        evl2: 0.5913325233,
        f2: 0.5255338904,
        k14: 0.08466765602,
        m29: 0.5078447564,
        t59: 0.4589442815,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '62',
        evl2: 0.5901907357,
        f2: 0.3168316832,
        k14: 0.1306094034,
        m29: 0.6400797607,
        t59: 0.4315326633,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '70',
        evl2: 0.5758241758,
        f2: 0.503208758,
        k14: 0.10546875,
        m29: 0.4899569584,
        t59: 0.4302874303,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.6884942656,
        f2: 0.1832091016,
        k14: 0.1121929299,
        m29: 0.8203883495,
        t59: 0.488115942,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.5582706767,
        f2: 0.3561215371,
        k14: 0.08368999422,
        m29: 0.601519337,
        t59: 0.4428571429,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '65',
        evl2: 0.5141467728,
        f2: 0.4587114338,
        k14: 0.08209399167,
        m29: 0.5856269113,
        t59: 0.4027484144,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '62',
        evl2: 0.5576559546,
        f2: 0.4242236025,
        k14: 0.09388515137,
        m29: 0.5123966942,
        t59: 0.3739076155,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '70',
        evl2: 0.5397948355,
        f2: 0.4133802817,
        k14: 0.1119362201,
        m29: 0.3514070007,
        t59: 0.4372881356,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.5872756933,
        f2: 0.3293478261,
        k14: 0.1021522146,
        m29: 0.6501128668,
        t59: 0.474537037,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.2030888031,
        f2: 0.2019660411,
        k14: 0.02398255814,
        m29: 0.8276119403,
        t59: 0.1645616642,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '26',
        evl2: 0.2218024825,
        f2: 0.1705263158,
        k14: 0.03029144768,
        m29: 0.7962451684,
        t59: 0.1843305608,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.2371194379,
        f2: 0.1960049938,
        k14: 0.02041789906,
        m29: 0.8463316002,
        t59: 0.1733591803,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '18',
        evl2: 0.1836158192,
        f2: 0.1757977122,
        k14: 0.01336329234,
        m29: 0.8565759637,
        t59: 0.1519188075,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '25',
        evl2: 0.2584507042,
        f2: 0.2128892107,
        k14: 0.02123955432,
        m29: 0.8025559105,
        t59: 0.1679338843,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '21',
        evl2: 0.2253052164,
        f2: 0.1779026217,
        k14: 0.03068171221,
        m29: 0.8846899225,
        t59: 0.2105561862,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '37',
        evl2: 0.3109721433,
        f2: 0.2729528536,
        k14: 0.03581693566,
        m29: 0.8367346939,
        t59: 0.1992859461,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.2748597654,
        f2: 0.2619577308,
        k14: 0.0409494001,
        m29: 0.7766233766,
        t59: 0.2018204645,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '38',
        evl2: 0.2748304747,
        f2: 0.2294015611,
        k14: 0.051197539,
        m29: 0.7300319489,
        t59: 0.2518101368,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '31',
        evl2: 0.2793439262,
        f2: 0.234006734,
        k14: 0.01146853147,
        m29: 0.8291174817,
        t59: 0.212745098,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '30',
        evl2: 0.2842587876,
        f2: 0.1743327655,
        k14: 0.02823784958,
        m29: 0.7564599483,
        t59: 0.1762168823,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '30',
        evl2: 0.2860665845,
        f2: 0.2398500937,
        k14: 0.03062214216,
        m29: 0.8473541384,
        t59: 0.2209510189,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '31',
        evl2: 0.2434456929,
        f2: 0.2040816327,
        k14: 0.02663679363,
        m29: 0.7277588721,
        t59: 0.2289081886,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.3150485437,
        f2: 0.2646420824,
        k14: 0.04470139468,
        m29: 0.768044417,
        t59: 0.2187028658,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.3337136338,
        f2: 0.2418447694,
        k14: 0.05448524985,
        m29: 0.6173913043,
        t59: 0.2570601014,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '45',
        evl2: 0.3786407767,
        f2: 0.3138010794,
        k14: 0.05725065617,
        m29: 0.7040682415,
        t59: 0.2579797221,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '48',
        evl2: 0.3325666973,
        f2: 0.3023133544,
        k14: 0.04536993951,
        m29: 0.7563195147,
        t59: 0.2544378698,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '45',
        evl2: 0.2931206381,
        f2: 0.2398720682,
        k14: 0.03956103509,
        m29: 0.7712418301,
        t59: 0.2412708399,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '49',
        evl2: 0.3773790103,
        f2: 0.38125,
        k14: 0.07681867535,
        m29: 0.6471827278,
        t59: 0.27701955,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '46',
        evl2: 0.3379469435,
        f2: 0.2497034401,
        k14: 0.04076156402,
        m29: 0.6877133106,
        t59: 0.2622891999,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.4279319606,
        f2: 0.3111222445,
        k14: 0.05934447183,
        m29: 0.5464942815,
        t59: 0.3009192066,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.3714502083,
        f2: 0.3196976434,
        k14: 0.07123394317,
        m29: 0.5964912281,
        t59: 0.2915947035,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '53',
        evl2: 0.3824,
        f2: 0.2433679354,
        k14: 0.0355227882,
        m29: 0.7405721717,
        t59: 0.2446292446,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '53',
        evl2: 0.3958333333,
        f2: 0.2839425587,
        k14: 0.07155295235,
        m29: 0.6544502618,
        t59: 0.2271859488,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '55',
        evl2: 0.3805093046,
        f2: 0.3688092729,
        k14: 0.07267718032,
        m29: 0.6168976308,
        t59: 0.2690655209,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '70',
        evl2: 0.4236499069,
        f2: 0.4764908257,
        k14: 0.07216626291,
        m29: 0.6740088106,
        t59: 0.3137595552,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.3558984569,
        f2: 0.3429625077,
        k14: 0.0548578028,
        m29: 0.6838854576,
        t59: 0.2842142107,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '66',
        evl2: 0.3071593533,
        f2: 0.2881754111,
        k14: 0.09252752401,
        m29: 0.5699404762,
        t59: 0.3415617128,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.186009539,
        f2: 0.1547502449,
        k14: 0.01568243078,
        m29: 0.8376993166,
        t59: 0.1707060063,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.1789124118,
        f2: 0.1393554244,
        k14: 0.01861494402,
        m29: 0.8101879927,
        t59: 0.1867973479,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '21',
        evl2: 0.193790686,
        f2: 0.1996517702,
        k14: 0.01698168931,
        m29: 0.8202702703,
        t59: 0.1832444305,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '23',
        evl2: 0.2236194642,
        f2: 0.2370049505,
        k14: 0.02897011982,
        m29: 0.838878017,
        t59: 0.1761499148,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '28',
        evl2: 0.2469184891,
        f2: 0.1937413714,
        k14: 0.01828393881,
        m29: 0.7642857143,
        t59: 0.2136752137,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '19',
        evl2: 0.1960171808,
        f2: 0.14806958,
        k14: 0.01859088218,
        m29: 0.8632404181,
        t59: 0.1413418191,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '27',
        evl2: 0.263134058,
        f2: 0.2055449331,
        k14: 0.02471678682,
        m29: 0.7744680851,
        t59: 0.2021586931,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '26',
        evl2: 0.2337790986,
        f2: 0.1940382452,
        k14: 0.03325569358,
        m29: 0.8288060644,
        t59: 0.2012429713,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.2248003043,
        f2: 0.244765007,
        k14: 0.01703061331,
        m29: 0.8420229406,
        t59: 0.2143036386,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '37',
        evl2: 0.2570409982,
        f2: 0.2310821806,
        k14: 0.02641993796,
        m29: 0.7461318052,
        t59: 0.2227848101,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '33',
        evl2: 0.2690815006,
        f2: 0.2039532794,
        k14: 0.03094728897,
        m29: 0.7992904207,
        t59: 0.21021611,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '39',
        evl2: 0.2948851775,
        f2: 0.2509270705,
        k14: 0.05123730964,
        m29: 0.6962264151,
        t59: 0.2175989086,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '38',
        evl2: 0.3346388164,
        f2: 0.291,
        k14: 0.0437770143,
        m29: 0.743814433,
        t59: 0.2551905388,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '30',
        evl2: 0.2540885521,
        f2: 0.218110579,
        k14: 0.02521670607,
        m29: 0.8555767398,
        t59: 0.1930614407,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '38',
        evl2: 0.3337742504,
        f2: 0.3268828452,
        k14: 0.0479638009,
        m29: 0.8161196911,
        t59: 0.2299942429,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '30',
        evl2: 0.2596322242,
        f2: 0.2654216186,
        k14: 0.0404475043,
        m29: 0.8308733087,
        t59: 0.2352941176,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '34',
        evl2: 0.2625,
        f2: 0.2161051767,
        k14: 0.02414409811,
        m29: 0.7670311186,
        t59: 0.2307878788,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '36',
        evl2: 0.3241301059,
        f2: 0.2618135377,
        k14: 0.0393907563,
        m29: 0.7090425532,
        t59: 0.2181911088,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '33',
        evl2: 0.300551081,
        f2: 0.2590744102,
        k14: 0.03150271575,
        m29: 0.8010625738,
        t59: 0.2251981416,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '42',
        evl2: 0.3339160839,
        f2: 0.3205128205,
        k14: 0.03022251744,
        m29: 0.6698895028,
        t59: 0.2539619651,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.2986645083,
        f2: 0.2917414722,
        k14: 0.03432691273,
        m29: 0.6918833727,
        t59: 0.2886766712,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '41',
        evl2: 0.3594570928,
        f2: 0.2578380908,
        k14: 0.04852130326,
        m29: 0.7226399332,
        t59: 0.2857142857,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '46',
        evl2: 0.332902113,
        f2: 0.2791011236,
        k14: 0.05514919308,
        m29: 0.7356828194,
        t59: 0.2854881266,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '42',
        evl2: 0.2952268709,
        f2: 0.2485515643,
        k14: 0.04624771202,
        m29: 0.6808803301,
        t59: 0.2569612591,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '40',
        evl2: 0.2955870108,
        f2: 0.344727973,
        k14: 0.03401147626,
        m29: 0.7702456779,
        t59: 0.2544747082,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.3925152306,
        f2: 0.3408450704,
        k14: 0.06414674819,
        m29: 0.6374885426,
        t59: 0.3278781038,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '53',
        evl2: 0.3245485603,
        f2: 0.3269441402,
        k14: 0.05497344625,
        m29: 0.6814516129,
        t59: 0.28332239,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '58',
        evl2: 0.3562138728,
        f2: 0.3968253968,
        k14: 0.06727056727,
        m29: 0.6272270443,
        t59: 0.2971679298,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.3697974217,
        f2: 0.2920086393,
        k14: 0.05707244948,
        m29: 0.643982808,
        t59: 0.2934266433,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '52',
        evl2: 0.4012932674,
        f2: 0.3223740393,
        k14: 0.0404136972,
        m29: 0.6275773196,
        t59: 0.3043057571,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '59',
        evl2: 0.4362801378,
        f2: 0.3525983487,
        k14: 0.0755588994,
        m29: 0.6636136553,
        t59: 0.3287276914,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '57',
        evl2: 0.375524895,
        f2: 0.2976022567,
        k14: 0.03991416309,
        m29: 0.6379421222,
        t59: 0.3262295082,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '63',
        evl2: 0.4086918349,
        f2: 0.4064875824,
        k14: 0.07574037767,
        m29: 0.6480195998,
        t59: 0.3131672598,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '62',
        evl2: 0.4592085979,
        f2: 0.4223404255,
        k14: 0.09731738474,
        m29: 0.5970088925,
        t59: 0.2841403305,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.3733333333,
        f2: 0.3811121764,
        k14: 0.07251308901,
        m29: 0.6396503774,
        t59: 0.2866145702,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.4481349004,
        f2: 0.3951807229,
        k14: 0.05024177379,
        m29: 0.5232438017,
        t59: 0.2694497154,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '64',
        evl2: 0.4573708526,
        f2: 0.3190045249,
        k14: 0.04054458408,
        m29: 0.5698248765,
        t59: 0.2779833488,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '60',
        evl2: 0.4462809917,
        f2: 0.373549884,
        k14: 0.1068562722,
        m29: 0.5774989883,
        t59: 0.3841149363,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '22',
        evl2: 0.2305924413,
        f2: 0.1825629023,
        k14: 0.02319044273,
        m29: 0.8976674191,
        t59: 0.2263690269,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '24',
        evl2: 0.2369655172,
        f2: 0.1373337457,
        k14: 0.01743406348,
        m29: 0.87,
        t59: 0.2065299924,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '35',
        evl2: 0.3075253256,
        f2: 0.2695393759,
        k14: 0.04327301338,
        m29: 0.8703457447,
        t59: 0.236631016,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '46',
        evl2: 0.308287796,
        f2: 0.272246696,
        k14: 0.03743654822,
        m29: 0.6374269006,
        t59: 0.2254869594,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '47',
        evl2: 0.3123432012,
        f2: 0.3089972736,
        k14: 0.0464811784,
        m29: 0.7124289196,
        t59: 0.3102564103,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '44',
        evl2: 0.3270178098,
        f2: 0.2618955513,
        k14: 0.04958294717,
        m29: 0.7732749179,
        t59: 0.2218664227,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '58',
        evl2: 0.4320987654,
        f2: 0.2685636856,
        k14: 0.0554517134,
        m29: 0.6459671663,
        t59: 0.2667871123,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '56',
        evl2: 0.4591754244,
        f2: 0.3966428349,
        k14: 0.0826446281,
        m29: 0.6422190879,
        t59: 0.2931529315,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '53',
        evl2: 0.4196090794,
        f2: 0.4427211843,
        k14: 0.05916473318,
        m29: 0.3343666962,
        t59: 0.3162583519,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '58',
        evl2: 0.4118408282,
        f2: 0.3736263736,
        k14: 0.08336657359,
        m29: 0.6433172303,
        t59: 0.2867459643,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '61',
        evl2: 0.3970790851,
        f2: 0.3600830163,
        k14: 0.06400295967,
        m29: 0.6484947111,
        t59: 0.2712579058,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '63',
        evl2: 0.4039240776,
        f2: 0.3780900338,
        k14: 0.05199004975,
        m29: 0.7014829142,
        t59: 0.2979734009,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '61',
        evl2: 0.4691642651,
        f2: 0.4037441498,
        k14: 0.06011883957,
        m29: 0.5538461538,
        t59: 0.4331983806,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '69',
        evl2: 0.4042651831,
        f2: 0.335594573,
        k14: 0.02417061611,
        m29: 0.551558753,
        t59: 0.3071113908,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '65',
        evl2: 0.3735047847,
        f2: 0.4143716366,
        k14: 0.08125502816,
        m29: 0.6919753086,
        t59: 0.3552271483,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '66',
        evl2: 0.389101862,
        f2: 0.4000545405,
        k14: 0.05462822458,
        m29: 0.4670363524,
        t59: 0.3021496815,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '65',
        evl2: 0.4581800343,
        f2: 0.3408630177,
        k14: 0.09158530916,
        m29: 0.6590909091,
        t59: 0.3432219302,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Blood ',
        age: '62',
        evl2: 0.3930578512,
        f2: 0.4141176471,
        k14: 0.07320099256,
        m29: 0.5387293299,
        t59: 0.3659160696,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '21',
        evl2: 0.302679979,
        f2: 0.04780010864,
        k14: 0.02586696987,
        m29: 0.9073783359,
        t59: 0.2388868811,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '39',
        evl2: 0.3979156798,
        f2: 0.1772658988,
        k14: 0.03780068729,
        m29: 0.6899260628,
        t59: 0.3094248094,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.2716568545,
        f2: 0.1596701649,
        k14: 0.02426688189,
        m29: 0.8564356436,
        t59: 0.2392363932,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '26',
        evl2: 0.2652116402,
        f2: 0.1104575163,
        k14: 0.02256894742,
        m29: 0.7389830508,
        t59: 0.2257709251,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '21',
        evl2: 0.2075782537,
        f2: 0.1726555653,
        k14: 0.01822186624,
        m29: 0.8033946252,
        t59: 0.1952135148,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '21',
        evl2: 0.2048984468,
        f2: 0.1378640777,
        k14: 0.0214550734,
        m29: 0.8536977492,
        t59: 0.2138109306,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '28',
        evl2: 0.2707889126,
        f2: 0.168592794,
        k14: 0.03284379172,
        m29: 0.8315118397,
        t59: 0.2383627608,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.2920989625,
        f2: 0.07945736434,
        k14: 0.03427280146,
        m29: 0.831627907,
        t59: 0.2369747899,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '23',
        evl2: 0.243373494,
        f2: 0.07501720578,
        k14: 0.02654016283,
        m29: 0.8023133544,
        t59: 0.2532555007,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '23',
        evl2: 0.3070507961,
        f2: 0.09825033647,
        k14: 0.03355410539,
        m29: 0.8709912536,
        t59: 0.2248603352,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '19',
        evl2: 0.2471671388,
        f2: 0.1631299735,
        k14: 0.03366370472,
        m29: 0.8692682927,
        t59: 0.2234779439,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '24',
        evl2: 0.2157258065,
        f2: 0.1747292419,
        k14: 0.02501805765,
        m29: 0.7926315789,
        t59: 0.2270916335,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '23',
        evl2: 0.2456839309,
        f2: 0.1238390093,
        k14: 0.0239450672,
        m29: 0.8425110132,
        t59: 0.1987878788,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '22',
        evl2: 0.2723059096,
        f2: 0.08869659275,
        k14: 0.0332590789,
        m29: 0.8667271079,
        t59: 0.2124677241,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '27',
        evl2: 0.2517385257,
        f2: 0.1518644068,
        k14: 0.0316824072,
        m29: 0.8110091743,
        t59: 0.2090534979,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '19',
        evl2: 0.2330022918,
        f2: 0.1138790036,
        k14: 0.02750477268,
        m29: 0.8399122807,
        t59: 0.2024048096,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '24',
        evl2: 0.204964539,
        f2: 0.1468634686,
        k14: 0.02224961079,
        m29: 0.8350730689,
        t59: 0.2077431539,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '25',
        evl2: 0.269698911,
        f2: 0.1737408036,
        k14: 0.02815108939,
        m29: 0.814497272,
        t59: 0.2124425592,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '30',
        evl2: 0.3166069295,
        f2: 0.1001737116,
        k14: 0.03407091442,
        m29: 0.8542056075,
        t59: 0.2901234568,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.3172690763,
        f2: 0.1823416507,
        k14: 0.02968216443,
        m29: 0.6863691194,
        t59: 0.2562166963,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '38',
        evl2: 0.3633144476,
        f2: 0.1759717314,
        k14: 0.0400417222,
        m29: 0.7549751244,
        t59: 0.2756382518,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '34',
        evl2: 0.2901002506,
        f2: 0.2141990291,
        k14: 0.02365740741,
        m29: 0.7349056604,
        t59: 0.2726583239,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '31',
        evl2: 0.2995923913,
        f2: 0.1700996678,
        k14: 0.02383261036,
        m29: 0.7522750253,
        t59: 0.232503888,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '31',
        evl2: 0.3402674591,
        f2: 0.1818181818,
        k14: 0.05148921602,
        m29: 0.8199767712,
        t59: 0.2938596491,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '34',
        evl2: 0.36066713,
        f2: 0.2374558304,
        k14: 0.03274912931,
        m29: 0.7674858223,
        t59: 0.2790301999,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '31',
        evl2: 0.3278795812,
        f2: 0.1359223301,
        k14: 0.02729235197,
        m29: 0.819023569,
        t59: 0.2777777778,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '37',
        evl2: 0.3365676167,
        f2: 0.2373004354,
        k14: 0.04734182074,
        m29: 0.7419354839,
        t59: 0.2843842573,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '36',
        evl2: 0.3189113747,
        f2: 0.1479553903,
        k14: 0.03272676953,
        m29: 0.6564039409,
        t59: 0.2653061224,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '35',
        evl2: 0.3071786311,
        f2: 0.2059299191,
        k14: 0.03791445949,
        m29: 0.802559415,
        t59: 0.2617474302,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '31',
        evl2: 0.2565714286,
        f2: 0.1627906977,
        k14: 0.02980009545,
        m29: 0.7892156863,
        t59: 0.2344139651,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '33',
        evl2: 0.3151553583,
        f2: 0.1743849493,
        k14: 0.05380081608,
        m29: 0.798816568,
        t59: 0.249306198,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '38',
        evl2: 0.337860781,
        f2: 0.1135986733,
        k14: 0.03649583007,
        m29: 0.7768508863,
        t59: 0.2877574371,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '34',
        evl2: 0.3974358974,
        f2: 0.1316916488,
        k14: 0.04970849954,
        m29: 0.7983630952,
        t59: 0.3213338386,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '31',
        evl2: 0.2744158179,
        f2: 0.2590815627,
        k14: 0.03652718418,
        m29: 0.7560344828,
        t59: 0.2382142857,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '36',
        evl2: 0.2925420168,
        f2: 0.2114597544,
        k14: 0.05387572069,
        m29: 0.702991453,
        t59: 0.2434695244,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.3764450867,
        f2: 0.2014184397,
        k14: 0.04148348551,
        m29: 0.6954177898,
        t59: 0.2833491012,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '39',
        evl2: 0.3653225806,
        f2: 0.2459935897,
        k14: 0.03360998293,
        m29: 0.7011494253,
        t59: 0.287147635,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '41',
        evl2: 0.3109935332,
        f2: 0.2890673255,
        k14: 0.05646544332,
        m29: 0.6653027823,
        t59: 0.3020030817,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '46',
        evl2: 0.3720930233,
        f2: 0.2449725777,
        k14: 0.04204055994,
        m29: 0.6788194444,
        t59: 0.3104406511,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.3307632999,
        f2: 0.2552552553,
        k14: 0.03461244263,
        m29: 0.6257378985,
        t59: 0.2558906692,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.3178082192,
        f2: 0.2481435644,
        k14: 0.04456272177,
        m29: 0.7481203008,
        t59: 0.2741246798,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '47',
        evl2: 0.3052138254,
        f2: 0.28101983,
        k14: 0.05555290867,
        m29: 0.6391437309,
        t59: 0.2984965163,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.3518631643,
        f2: 0.3029661017,
        k14: 0.05256447407,
        m29: 0.7150442478,
        t59: 0.2982269504,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.3737446198,
        f2: 0.1820580475,
        k14: 0.05347964193,
        m29: 0.6980854197,
        t59: 0.2948328267,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '44',
        evl2: 0.3847595253,
        f2: 0.2747625509,
        k14: 0.07607681352,
        m29: 0.5761589404,
        t59: 0.2607516466,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '45',
        evl2: 0.2714196373,
        f2: 0.2230720204,
        k14: 0.0425104993,
        m29: 0.7039529016,
        t59: 0.2491493384,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '44',
        evl2: 0.4147997284,
        f2: 0.2122905028,
        k14: 0.05863845945,
        m29: 0.7360627178,
        t59: 0.3212172443,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '46',
        evl2: 0.3813682678,
        f2: 0.2232746955,
        k14: 0.05221187427,
        m29: 0.7376873662,
        t59: 0.2903959945,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '47',
        evl2: 0.383365822,
        f2: 0.1571014493,
        k14: 0.05016406483,
        m29: 0.7384131972,
        t59: 0.2884615385,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '40',
        evl2: 0.3245283019,
        f2: 0.1947728687,
        k14: 0.03804575987,
        m29: 0.7570168404,
        t59: 0.2881286068,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '42',
        evl2: 0.3323761665,
        f2: 0.2215277778,
        k14: 0.04169793118,
        m29: 0.6595744681,
        t59: 0.2862610027,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '56',
        evl2: 0.3853904282,
        f2: 0.2588726514,
        k14: 0.085540627,
        m29: 0.6095132743,
        t59: 0.2952924394,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '57',
        evl2: 0.4194097616,
        f2: 0.3268265477,
        k14: 0.0861575179,
        m29: 0.5534150613,
        t59: 0.3474214812,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '53',
        evl2: 0.3643574828,
        f2: 0.2991990847,
        k14: 0.06660579975,
        m29: 0.61998362,
        t59: 0.3015294975,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '51',
        evl2: 0.4016152717,
        f2: 0.1396203307,
        k14: 0.04131929971,
        m29: 0.7504288165,
        t59: 0.3989412897,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '57',
        evl2: 0.3668639053,
        f2: 0.3341584158,
        k14: 0.08060936239,
        m29: 0.5405405405,
        t59: 0.3109756098,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '58',
        evl2: 0.3788461538,
        f2: 0.3319644079,
        k14: 0.08481430244,
        m29: 0.6151202749,
        t59: 0.3287731686,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '56',
        evl2: 0.3950877193,
        f2: 0.3308439134,
        k14: 0.05741827326,
        m29: 0.5382631126,
        t59: 0.2987689394,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.3925710124,
        f2: 0.3144704931,
        k14: 0.06089543644,
        m29: 0.5734597156,
        t59: 0.307271963,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '55',
        evl2: 0.3889490791,
        f2: 0.3176895307,
        k14: 0.06579427321,
        m29: 0.407951599,
        t59: 0.3017084696,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '52',
        evl2: 0.3564223268,
        f2: 0.2977487291,
        k14: 0.05753859902,
        m29: 0.6194690265,
        t59: 0.3342140026,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '53',
        evl2: 0.5134078212,
        f2: 0.119869403,
        k14: 0.07539851675,
        m29: 0.7113333333,
        t59: 0.4377035831,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '55',
        evl2: 0.3423376623,
        f2: 0.3402625821,
        k14: 0.07473145189,
        m29: 0.5637037037,
        t59: 0.3231926726,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4432989691,
        f2: 0.4017784964,
        k14: 0.09792128311,
        m29: 0.5045408678,
        t59: 0.3739711934,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '51',
        evl2: 0.3992120814,
        f2: 0.2164502165,
        k14: 0.06251317731,
        m29: 0.4642276423,
        t59: 0.333475117,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '58',
        evl2: 0.5208667737,
        f2: 0.347254447,
        k14: 0.09380059539,
        m29: 0.5274027006,
        t59: 0.3609095307,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '53',
        evl2: 0.3875562219,
        f2: 0.2695340502,
        k14: 0.1140548666,
        m29: 0.6111111111,
        t59: 0.3632212536,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '50',
        evl2: 0.3418803419,
        f2: 0.3167366527,
        k14: 0.03709525307,
        m29: 0.6443123939,
        t59: 0.30078125,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '62',
        evl2: 0.4263224181,
        f2: 0.3497813866,
        k14: 0.09824742752,
        m29: 0.5192156863,
        t59: 0.3300395257,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4517994859,
        f2: 0.1854304636,
        k14: 0.08494806233,
        m29: 0.6679389313,
        t59: 0.3832535885,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4802110818,
        f2: 0.2876712329,
        k14: 0.06142475878,
        m29: 0.4720180893,
        t59: 0.3803526448,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '63',
        evl2: 0.3792857143,
        f2: 0.3476923077,
        k14: 0.07068113244,
        m29: 0.5942519019,
        t59: 0.332261522,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '65',
        evl2: 0.4670999188,
        f2: 0.3124504362,
        k14: 0.09092878419,
        m29: 0.3660426081,
        t59: 0.423454367,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '69',
        evl2: 0.5850066934,
        f2: 0.1226597805,
        k14: 0.09186652763,
        m29: 0.6495726496,
        t59: 0.5083487941,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '63',
        evl2: 0.5189681335,
        f2: 0.2541229385,
        k14: 0.06752270286,
        m29: 0.5564971751,
        t59: 0.3627586207,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '68',
        evl2: 0.4794520548,
        f2: 0.4293785311,
        k14: 0.07607255102,
        m29: 0.4663461538,
        t59: 0.3747117602,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '62',
        evl2: 0.4831168831,
        f2: 0.245017585,
        k14: 0.1208791209,
        m29: 0.6025104603,
        t59: 0.3570822731,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '70',
        evl2: 0.4745529574,
        f2: 0.4031271244,
        k14: 0.09542119909,
        m29: 0.4681818182,
        t59: 0.353202847,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.6052463212,
        f2: 0.1412726211,
        k14: 0.1022462815,
        m29: 0.7946084724,
        t59: 0.4100978877,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4437819421,
        f2: 0.2620638456,
        k14: 0.07429989365,
        m29: 0.5761947701,
        t59: 0.3712418301,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '65',
        evl2: 0.4118065434,
        f2: 0.3568953569,
        k14: 0.07219670077,
        m29: 0.5620316403,
        t59: 0.3565995526,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '62',
        evl2: 0.4562118126,
        f2: 0.3308480895,
        k14: 0.08696454948,
        m29: 0.470260223,
        t59: 0.3143759874,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '70',
        evl2: 0.4267053701,
        f2: 0.3180306055,
        k14: 0.09801094194,
        m29: 0.3101851852,
        t59: 0.3591138749,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Saliva ',
        age: '60',
        evl2: 0.4803921569,
        f2: 0.2503467406,
        k14: 0.09010728286,
        m29: 0.6195005945,
        t59: 0.3982706002,
    },
];

export const DATA_SNAP_SEMEN = [
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 19,
        ttc7b: 0.7670349908,
        cg: 0.8609189723,
        nox4: 0.5243243243,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 20,
        ttc7b: 0.7037037037,
        cg: 0.7730150999,
        nox4: 0.3628808864,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 20,
        ttc7b: 0.7350714886,
        cg: 0.7907351924,
        nox4: 0.4509803922,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 21,
        ttc7b: 0.4700761697,
        cg: 0.6178451178,
        nox4: 0.408839779,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 21,
        ttc7b: 0.7746848739,
        cg: 0.7721612808,
        nox4: 0.4423076923,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 22,
        ttc7b: 0.6451539339,
        cg: 0.6594041796,
        nox4: 0.3847376789,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 22,
        ttc7b: 0.6937669377,
        cg: 0.6926761126,
        nox4: 0.6217228464,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 23,
        ttc7b: 0.6908471276,
        cg: 0.6629213483,
        nox4: 0.6470588235,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 23,
        ttc7b: 0.7650402653,
        cg: 0.7828805941,
        nox4: 0.4730538922,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 24,
        ttc7b: 0.6385542169,
        cg: 0.7218027371,
        nox4: 0.5288753799,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 24,
        ttc7b: 0.7128820961,
        cg: 0.7529076147,
        nox4: 0.6254826255,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 24,
        ttc7b: 0.7413219211,
        cg: 0.8320610687,
        nox4: 0.6080178174,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 25,
        ttc7b: 0.725398313,
        cg: 0.6985028394,
        nox4: 0.5402684564,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 25,
        ttc7b: 0.7013469577,
        cg: 0.7309931507,
        nox4: 0.4276315789,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 25,
        ttc7b: 0.6638616176,
        cg: 0.6422279793,
        nox4: 0.5138888889,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 26,
        ttc7b: 0.8030482977,
        cg: 0.6967596928,
        nox4: 0.5160818713,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 26,
        ttc7b: 0.6194651469,
        cg: 0.7061037473,
        nox4: 0.4899135447,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 27,
        ttc7b: 0.7501992032,
        cg: 0.692200799,
        nox4: 0.4344023324,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 27,
        ttc7b: 0.5993975904,
        cg: 0.6407986826,
        nox4: 0.5263157895,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 28,
        ttc7b: 0.5946076071,
        cg: 0.6386438332,
        nox4: 0.4466666667,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 29,
        ttc7b: 0.6180197091,
        cg: 0.6680080483,
        nox4: 0.3883161512,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 29,
        ttc7b: 0.664893617,
        cg: 0.7898368883,
        nox4: 0.3546099291,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.7419891802,
        cg: 0.6155555556,
        nox4: 0.7670807453,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.5074492099,
        cg: 0.6195899772,
        nox4: 0.4907161804,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.5844155844,
        cg: 0.6718556956,
        nox4: 0.5555555556,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.7150837989,
        cg: 0.6357469761,
        nox4: 0.6640826873,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.6884650318,
        cg: 0.66442247,
        nox4: 0.5876777251,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.7382888115,
        cg: 0.749219313,
        nox4: 0.5543278085,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.6265772871,
        cg: 0.6900201613,
        nox4: 0.6444043321,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.572519084,
        cg: 0.618982743,
        nox4: 0.4604651163,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.6247169201,
        cg: 0.6469710534,
        nox4: 0.5031055901,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.6585204756,
        cg: 0.7886581965,
        nox4: 0.6433408578,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.5559633028,
        cg: 0.7334433184,
        nox4: 0.5105263158,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 32,
        ttc7b: 0.7138169887,
        cg: 0.7642576772,
        nox4: 0.7958115183,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 32,
        ttc7b: 0.4776271186,
        cg: 0.5660144377,
        nox4: 0.5945945946,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 33,
        ttc7b: 0.5271127481,
        cg: 0.7596175228,
        nox4: 0.4562899787,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 34,
        ttc7b: 0.6980101444,
        cg: 0.6833855799,
        nox4: 0.4735849057,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 34,
        ttc7b: 0.6883191981,
        cg: 0.6648637821,
        nox4: 0.5531914894,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 34,
        ttc7b: 0.6602357985,
        cg: 0.6117070699,
        nox4: 0.6138613861,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 35,
        ttc7b: 0.8783372761,
        cg: 0.663884832,
        nox4: 0.7457337884,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 35,
        ttc7b: 0.582548558,
        cg: 0.5598053638,
        nox4: 0.7604373757,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 35,
        ttc7b: 0.5634549423,
        cg: 0.4710647019,
        nox4: 0.6635687732,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 36,
        ttc7b: 0.6596834264,
        cg: 0.6811166591,
        nox4: 0.8584070796,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 37,
        ttc7b: 0.5156682028,
        cg: 0.4896585849,
        nox4: 0.8535735038,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 38,
        ttc7b: 0.6610576923,
        cg: 0.6621719239,
        nox4: 0.6950959488,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 39,
        ttc7b: 0.6408094435,
        cg: 0.6573275862,
        nox4: 0.5826086957,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 39,
        ttc7b: 0.5767805681,
        cg: 0.6879633668,
        nox4: 0.6754658385,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 40,
        ttc7b: 0.7746967071,
        cg: 0.6637572735,
        nox4: 0.7917981073,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 40,
        ttc7b: 0.5356083086,
        cg: 0.5717197645,
        nox4: 0.569391635,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 40,
        ttc7b: 0.5619383825,
        cg: 0.615085389,
        nox4: 0.7777777778,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 41,
        ttc7b: 0.597942224,
        cg: 0.5135331781,
        nox4: 0.6654040404,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 42,
        ttc7b: 0.5586616454,
        cg: 0.5224760501,
        nox4: 0.7228835979,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 44,
        ttc7b: 0.6551524508,
        cg: 0.5944767442,
        nox4: 0.8200795229,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 45,
        ttc7b: 0.4110139447,
        cg: 0.4399877993,
        nox4: 0.5826086957,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 45,
        ttc7b: 0.6986678916,
        cg: 0.3760095012,
        nox4: 0.6886363636,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 45,
        ttc7b: 0.4842975207,
        cg: 0.5963938974,
        nox4: 0.6981132075,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 47,
        ttc7b: 0.4895544463,
        cg: 0.490288679,
        nox4: 0.7605779154,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 48,
        ttc7b: 0.4717712177,
        cg: 0.5608064166,
        nox4: 0.7063492063,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 49,
        ttc7b: 0.5056098444,
        cg: 0.4863698558,
        nox4: 0.6831683168,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 49,
        ttc7b: 0.676952485,
        cg: 0.4977523138,
        nox4: 0.7789855072,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 52,
        ttc7b: 0.4027018334,
        cg: 0.4876886418,
        nox4: 0.8477306003,
    },
    {
        g_analyzer: '3130',
        bf_type: 'Semen',
        age: 55,
        ttc7b: 0.4533057851,
        cg: 0.5550300946,
        nox4: 0.9154929577,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 19,
        ttc7b: 0.6740905716,
        cg: 0.7931520645,
        nox4: 0.4089219331,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 20,
        ttc7b: 0.6041108132,
        cg: 0.6809498304,
        nox4: 0.2795698925,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 20,
        ttc7b: 0.6348387097,
        cg: 0.703949797,
        nox4: 0.3607594937,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 21,
        ttc7b: 0.3469387755,
        cg: 0.5123398938,
        nox4: 0.3536585366,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 21,
        ttc7b: 0.6759517177,
        cg: 0.6796246649,
        nox4: 0.3518518519,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 22,
        ttc7b: 0.5306784661,
        cg: 0.5472051056,
        nox4: 0.3159557662,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 22,
        ttc7b: 0.5792079208,
        cg: 0.5816059569,
        nox4: 0.5212765957,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 23,
        ttc7b: 0.5947983415,
        cg: 0.5584455798,
        nox4: 0.5215517241,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 23,
        ttc7b: 0.6737621472,
        cg: 0.6957183635,
        nox4: 0.355721393,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 24,
        ttc7b: 0.5298284862,
        cg: 0.6290386917,
        nox4: 0.4272517321,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 24,
        ttc7b: 0.6155890168,
        cg: 0.6635984717,
        nox4: 0.4985994398,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 24,
        ttc7b: 0.6357440363,
        cg: 0.7550147978,
        nox4: 0.4937106918,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 25,
        ttc7b: 0.6232697344,
        cg: 0.5962274467,
        nox4: 0.443324937,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 25,
        ttc7b: 0.6023424428,
        cg: 0.6328044509,
        nox4: 0.3401360544,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 25,
        ttc7b: 0.556384743,
        cg: 0.5218554862,
        nox4: 0.3688811189,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 26,
        ttc7b: 0.717057101,
        cg: 0.5878967275,
        nox4: 0.4623655914,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 26,
        ttc7b: 0.5100158983,
        cg: 0.601068999,
        nox4: 0.3937621832,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 27,
        ttc7b: 0.6504365346,
        cg: 0.5931866572,
        nox4: 0.3191056911,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 27,
        ttc7b: 0.4860406091,
        cg: 0.5255066387,
        nox4: 0.4090909091,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 28,
        ttc7b: 0.4865134865,
        cg: 0.5336347197,
        nox4: 0.369047619,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 29,
        ttc7b: 0.5055694476,
        cg: 0.5640227636,
        nox4: 0.3328947368,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 29,
        ttc7b: 0.5665434381,
        cg: 0.7200736648,
        nox4: 0.3114754098,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.6565008026,
        cg: 0.5047163545,
        nox4: 0.6827586207,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.4039692702,
        cg: 0.5108071597,
        nox4: 0.3607476636,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.4884836852,
        cg: 0.587773549,
        nox4: 0.4822335025,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.5994962217,
        cg: 0.5151079137,
        nox4: 0.5055555556,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.5947556615,
        cg: 0.5547108336,
        nox4: 0.4293785311,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.6385193754,
        cg: 0.6497185081,
        nox4: 0.4487632509,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.5093632959,
        cg: 0.5825878246,
        nox4: 0.5464231355,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.4786036036,
        cg: 0.52,
        nox4: 0.3366834171,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.5012690355,
        cg: 0.5242907112,
        nox4: 0.3969465649,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.538996139,
        cg: 0.6964847849,
        nox4: 0.5235602094,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.43001443,
        cg: 0.6373407643,
        nox4: 0.4029850746,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 32,
        ttc7b: 0.6128286622,
        cg: 0.6644493718,
        nox4: 0.6990553306,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 32,
        ttc7b: 0.3572120039,
        cg: 0.4386627123,
        nox4: 0.4855491329,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 33,
        ttc7b: 0.403371463,
        cg: 0.6630434783,
        nox4: 0.3534743202,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 34,
        ttc7b: 0.5916201117,
        cg: 0.5747734139,
        nox4: 0.3718592965,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 34,
        ttc7b: 0.5756747523,
        cg: 0.5476912706,
        nox4: 0.4423791822,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 34,
        ttc7b: 0.5420765027,
        cg: 0.4906159769,
        nox4: 0.4814814815,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 35,
        ttc7b: 0.8152557319,
        cg: 0.5511849499,
        nox4: 0.6447638604,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 35,
        ttc7b: 0.4624605678,
        cg: 0.4410366144,
        nox4: 0.6428571429,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 35,
        ttc7b: 0.4424778761,
        cg: 0.3606698154,
        nox4: 0.5462184874,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 36,
        ttc7b: 0.5449529374,
        cg: 0.5653409091,
        nox4: 0.7940379404,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 37,
        ttc7b: 0.400273224,
        cg: 0.3723930338,
        nox4: 0.7885802469,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 38,
        ttc7b: 0.54667998,
        cg: 0.5451137216,
        nox4: 0.5952380952,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 39,
        ttc7b: 0.5264124943,
        cg: 0.5476722533,
        nox4: 0.4913793103,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 39,
        ttc7b: 0.460755814,
        cg: 0.57542051,
        nox4: 0.5452793834,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 40,
        ttc7b: 0.691,
        cg: 0.5628893753,
        nox4: 0.7005208333,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 40,
        ttc7b: 0.4336882865,
        cg: 0.4664318732,
        nox4: 0.4222797927,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 40,
        ttc7b: 0.4388217523,
        cg: 0.5047909408,
        nox4: 0.6546184739,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 41,
        ttc7b: 0.486163522,
        cg: 0.4111798498,
        nox4: 0.58203125,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 42,
        ttc7b: 0.4369230769,
        cg: 0.4030748663,
        nox4: 0.6002460025,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 44,
        ttc7b: 0.5532786885,
        cg: 0.4859183673,
        nox4: 0.7297297297,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 45,
        ttc7b: 0.3085516179,
        cg: 0.3323792932,
        nox4: 0.4711934156,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 45,
        ttc7b: 0.5941807044,
        cg: 0.2764396759,
        nox4: 0.5791411043,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 45,
        ttc7b: 0.3649579189,
        cg: 0.4748563218,
        nox4: 0.5802919708,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 47,
        ttc7b: 0.370281892,
        cg: 0.3774567445,
        nox4: 0.6493902439,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 48,
        ttc7b: 0.3625673689,
        cg: 0.4446196295,
        nox4: 0.6226415094,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 49,
        ttc7b: 0.3868739206,
        cg: 0.3600297545,
        nox4: 0.5693779904,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 49,
        ttc7b: 0.5684931507,
        cg: 0.3815270936,
        nox4: 0.6931818182,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 52,
        ttc7b: 0.29986053,
        cg: 0.3701657459,
        nox4: 0.7794117647,
    },
    {
        g_analyzer: '3500',
        bf_type: 'Semen',
        age: 55,
        ttc7b: 0.3471113199,
        cg: 0.4500708933,
        nox4: 0.8575063613,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 19,
        ttc7b: 0.6219376953,
        cg: 0.7612479089,
        nox4: 0.3561643836,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 19,
        ttc7b: 0.5610068259,
        cg: 0.644568956,
        nox4: 0.222962963,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 20,
        ttc7b: 0.5927665184,
        cg: 0.6758543102,
        nox4: 0.296868807,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 21,
        ttc7b: 0.3216258879,
        cg: 0.4795597484,
        nox4: 0.2591170825,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 21,
        ttc7b: 0.6540909091,
        cg: 0.6514155868,
        nox4: 0.2960526316,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 22,
        ttc7b: 0.4785367304,
        cg: 0.508890448,
        nox4: 0.2396567576,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 22,
        ttc7b: 0.5487207779,
        cg: 0.549155082,
        nox4: 0.4683402023,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 23,
        ttc7b: 0.5286607608,
        cg: 0.5087912694,
        nox4: 0.4627749577,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 23,
        ttc7b: 0.6335450131,
        cg: 0.6623386721,
        nox4: 0.3050153531,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 24,
        ttc7b: 0.4873309202,
        cg: 0.5942618563,
        nox4: 0.3883495146,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 24,
        ttc7b: 0.5561679365,
        cg: 0.6195382809,
        nox4: 0.4653148346,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 24,
        ttc7b: 0.5933696079,
        cg: 0.7148135962,
        nox4: 0.4571696785,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 25,
        ttc7b: 0.5825242718,
        cg: 0.5569229211,
        nox4: 0.3699731903,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 25,
        ttc7b: 0.5581064879,
        cg: 0.573556797,
        nox4: 0.2854834366,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 25,
        ttc7b: 0.5135492399,
        cg: 0.4939809242,
        nox4: 0.3574357063,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 26,
        ttc7b: 0.6758787634,
        cg: 0.550163778,
        nox4: 0.3595017381,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 26,
        ttc7b: 0.4612679114,
        cg: 0.5573637384,
        nox4: 0.3488624052,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 27,
        ttc7b: 0.6103014882,
        cg: 0.5483205608,
        nox4: 0.2789651294,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 27,
        ttc7b: 0.4470876586,
        cg: 0.493348093,
        nox4: 0.3514109347,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 28,
        ttc7b: 0.4238601371,
        cg: 0.4911164894,
        nox4: 0.3132192846,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 29,
        ttc7b: 0.4521681393,
        cg: 0.5155125237,
        nox4: 0.2442338073,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 29,
        ttc7b: 0.5225285171,
        cg: 0.6858815972,
        nox4: 0.2458172458,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.6019289579,
        cg: 0.4702076237,
        nox4: 0.6253128352,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.3603395062,
        cg: 0.467330309,
        nox4: 0.322386679,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.4542378763,
        cg: 0.5499705389,
        nox4: 0.420204978,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.5676171547,
        cg: 0.4867089842,
        nox4: 0.5106086957,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 30,
        ttc7b: 0.5416492256,
        cg: 0.517867026,
        nox4: 0.4249363868,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.5947426068,
        cg: 0.6100717644,
        nox4: 0.4033095393,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.4663030216,
        cg: 0.5543822106,
        nox4: 0.5263332229,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.4387261871,
        cg: 0.4889727679,
        nox4: 0.3112998523,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.4551558355,
        cg: 0.491030574,
        nox4: 0.3236641221,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.5022880439,
        cg: 0.6661884266,
        nox4: 0.4867156586,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 31,
        ttc7b: 0.3910863116,
        cg: 0.5998854829,
        nox4: 0.3512861736,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 32,
        ttc7b: 0.5672400563,
        cg: 0.6316783365,
        nox4: 0.6770700637,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 32,
        ttc7b: 0.3333651369,
        cg: 0.4135448681,
        nox4: 0.4251533742,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 33,
        ttc7b: 0.3694866233,
        cg: 0.6276846335,
        nox4: 0.2970027248,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 34,
        ttc7b: 0.5417260757,
        cg: 0.5376632821,
        nox4: 0.3124218052,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 34,
        ttc7b: 0.538457646,
        cg: 0.5197675001,
        nox4: 0.4000288517,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 34,
        ttc7b: 0.5027141846,
        cg: 0.457827087,
        nox4: 0.4324324324,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 35,
        ttc7b: 0.7945009954,
        cg: 0.5273047679,
        nox4: 0.6046793761,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 35,
        ttc7b: 0.4141494436,
        cg: 0.4097969362,
        nox4: 0.6124977872,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 35,
        ttc7b: 0.4092751743,
        cg: 0.3324985268,
        nox4: 0.5096605744,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 36,
        ttc7b: 0.5025361238,
        cg: 0.5380056686,
        nox4: 0.7844469611,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 37,
        ttc7b: 0.358081,
        cg: 0.3429438543,
        nox4: 0.7609213662,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 38,
        ttc7b: 0.5060716568,
        cg: 0.5167128277,
        nox4: 0.5579420579,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 39,
        ttc7b: 0.4818154364,
        cg: 0.5033392738,
        nox4: 0.4117980715,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 39,
        ttc7b: 0.4119704281,
        cg: 0.536149595,
        nox4: 0.5110750945,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 40,
        ttc7b: 0.6437889633,
        cg: 0.5153849146,
        nox4: 0.6790163934,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 40,
        ttc7b: 0.3751488161,
        cg: 0.4241894535,
        nox4: 0.4080762478,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 40,
        ttc7b: 0.3992654774,
        cg: 0.4629980415,
        nox4: 0.6389083275,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 41,
        ttc7b: 0.4306955026,
        cg: 0.3723306695,
        nox4: 0.5176706827,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 42,
        ttc7b: 0.3975146798,
        cg: 0.3733243213,
        nox4: 0.5706806283,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 44,
        ttc7b: 0.4953922131,
        cg: 0.4469377965,
        nox4: 0.6967725794,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 45,
        ttc7b: 0.2622649599,
        cg: 0.3114636362,
        nox4: 0.4236918605,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 45,
        ttc7b: 0.5457423235,
        cg: 0.2489558284,
        nox4: 0.5370511841,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 45,
        ttc7b: 0.3472670231,
        cg: 0.4534039494,
        nox4: 0.5417488494,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 47,
        ttc7b: 0.3246927803,
        cg: 0.3383130015,
        nox4: 0.6259079903,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 48,
        ttc7b: 0.3256036963,
        cg: 0.4205783626,
        nox4: 0.5480565371,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 49,
        ttc7b: 0.3402896893,
        cg: 0.3392957116,
        nox4: 0.5264797508,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 49,
        ttc7b: 0.5179052125,
        cg: 0.3592846609,
        nox4: 0.6477577616,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 52,
        ttc7b: 0.2615013988,
        cg: 0.353826677,
        nox4: 0.7447519084,
    },
    {
        g_analyzer: 'SeqStudio',
        bf_type: 'Semen',
        age: 55,
        ttc7b: 0.2963734254,
        cg: 0.4053794648,
        nox4: 0.860328275,
    },
];

export const DATA_SNAP_BF = [
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.58,
        bl3: 0.46,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.56,
        bl3: 0.41,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.54,
        bl3: 0.48,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.52,
        bl3: 0.38,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.53,
        bl3: 0.45,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.57,
        bl3: 0.39,
        vf1: 0.08,
        vf2: 0.06,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.68,
        bl3: 0.57,
        vf1: 0.09,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.69,
        bl3: 0.51,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.39,
        bl3: 0.29,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.62,
        bl3: 0.48,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.54,
        bl3: 0.39,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0.39,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.65,
        bl3: 0.54,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.38,
        bl3: 0.29,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.62,
        bl3: 0.45,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.59,
        bl3: 0.43,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.47,
        bl3: 0.39,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.63,
        bl3: 0.58,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.51,
        bl3: 0.43,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.7,
        bl3: 0.43,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.5,
        bl3: 0.44,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.49,
        bl3: 0.33,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.63,
        bl3: 0.41,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.56,
        bl3: 0.38,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.57,
        bl3: 0.51,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.5,
        bl3: 0.35,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.64,
        bl3: 0.46,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.56,
        bl3: 0.37,
        vf1: 0.06,
        vf2: 0.07,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.72,
        bl3: 0.51,
        vf1: 0.06,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.63,
        bl3: 0.48,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.54,
        bl3: 0.49,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.64,
        bl3: 0.5,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.61,
        bl3: 0.36,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.58,
        bl3: 0.53,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.65,
        bl3: 0.5,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.57,
        bl3: 0.4,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.63,
        bl3: 0.53,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.63,
        bl3: 0.52,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.55,
        bl3: 0.49,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.54,
        bl3: 0.51,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.58,
        bl3: 0.51,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.62,
        bl3: 0.48,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.59,
        bl3: 0.5,
        vf1: 0.13,
        vf2: 0.08,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.59,
        bl3: 0.42,
        vf1: 0.07,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.37,
        bl3: 0.28,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.51,
        bl3: 0.43,
        vf1: 0,
        vf2: 0.07,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.7,
        bl3: 0.56,
        vf1: 0.07,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.69,
        bl3: 0.43,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.53,
        bl3: 0.37,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.55,
        bl3: 0.57,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.49,
        bl3: 0.46,
        vf1: 0.06,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.6,
        bl3: 0.5,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.44,
        bl3: 0.43,
        vf1: 0.07,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.4,
        bl3: 0.32,
        vf1: 0.05,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.58,
        bl3: 0.53,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.64,
        bl3: 0.51,
        vf1: 0.06,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.6,
        bl3: 0.49,
        vf1: 0.05,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.6,
        bl3: 0.5,
        vf1: 0.07,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.66,
        bl3: 0.52,
        vf1: 0.08,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.66,
        bl3: 0.58,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.65,
        bl3: 0.56,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.56,
        bl3: 0.43,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.66,
        bl3: 0.57,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.77,
        bl3: 0.65,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.59,
        bl3: 0.36,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.66,
        bl3: 0.6,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.73,
        bl3: 0.63,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.73,
        bl3: 0.46,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.67,
        bl3: 0.35,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.66,
        bl3: 0.6,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.77,
        bl3: 0.67,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.67,
        bl3: 0.54,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.72,
        bl3: 0.54,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.74,
        bl3: 0.52,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.64,
        bl3: 0.54,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.75,
        bl3: 0.57,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.62,
        bl3: 0.36,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.73,
        bl3: 0.6,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.61,
        bl3: 0.49,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.61,
        bl3: 0.49,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.87,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.96,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.91,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.3,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.88,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.94,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.13,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.81,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.75,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.91,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.92,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.86,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.16,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.94,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.26,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.95,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.91,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.22,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.93,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.23,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.91,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.17,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.95,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.23,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.93,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.4,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.71,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.27,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.84,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.2,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.54,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.19,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.77,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.24,
        bl3: 0,
        vf1: 0.17,
        vf2: 0,
        sa1: 0.95,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.87,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.83,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.82,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.13,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.84,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.97,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.15,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.9,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.5,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.78,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.34,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.77,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.12,
        vf2: 0,
        sa1: 0.95,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.2,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.95,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.83,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.31,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.91,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.25,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.84,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.19,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.84,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.2,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.92,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.17,
        bl3: 0,
        vf1: 0.14,
        vf2: 0,
        sa1: 0.92,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.2,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.88,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.31,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 1,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.28,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.9,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.19,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.91,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.15,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.85,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.18,
        bl3: 0,
        vf1: 0.11,
        vf2: 0,
        sa1: 0.95,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0.18,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.82,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Buccal',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.18,
        vf2: 0.14,
        sa1: 0.96,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.72,
        bl3: 0.47,
        vf1: 0.17,
        vf2: 0.13,
        sa1: 0,
        mb1: 0.46,
        mb2: 0.35,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.43,
        bl3: 0.37,
        vf1: 0.14,
        vf2: 0.21,
        sa1: 0,
        mb1: 0.72,
        mb2: 0.64,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.39,
        bl3: 0.21,
        vf1: 0.31,
        vf2: 0.23,
        sa1: 0,
        mb1: 0.3,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.43,
        bl3: 0.24,
        vf1: 0.16,
        vf2: 0.2,
        sa1: 0,
        mb1: 0.45,
        mb2: 0.44,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.42,
        bl3: 0,
        vf1: 0.76,
        vf2: 0.79,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.38,
        bl3: 0,
        vf1: 0.78,
        vf2: 0.74,
        sa1: 0,
        mb1: 0.43,
        mb2: 0.75,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.47,
        bl3: 0,
        vf1: 0.72,
        vf2: 0.71,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.57,
        bl3: 0,
        vf1: 0.66,
        vf2: 0.43,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.16,
        bl3: 0,
        vf1: 0.69,
        vf2: 0.61,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.2,
        bl3: 0.15,
        vf1: 0.2,
        vf2: 0.15,
        sa1: 0,
        mb1: 0.28,
        mb2: 0.3,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.63,
        bl3: 0.37,
        vf1: 0.25,
        vf2: 0.24,
        sa1: 0,
        mb1: 0.37,
        mb2: 0.32,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.54,
        bl3: 0.28,
        vf1: 0.56,
        vf2: 0.64,
        sa1: 0,
        mb1: 0.45,
        mb2: 0.43,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.32,
        bl3: 0,
        vf1: 0,
        vf2: 0.11,
        sa1: 0,
        mb1: 0.25,
        mb2: 0.21,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.31,
        bl3: 0.32,
        vf1: 0.11,
        vf2: 0.17,
        sa1: 0,
        mb1: 0.53,
        mb2: 0.47,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.29,
        bl3: 0.23,
        vf1: 0.29,
        vf2: 0.33,
        sa1: 0,
        mb1: 0.55,
        mb2: 0.48,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.56,
        bl3: 0.27,
        vf1: 0,
        vf2: 0.28,
        sa1: 0,
        mb1: 0.37,
        mb2: 0.34,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.43,
        bl3: 0.24,
        vf1: 0.15,
        vf2: 0,
        sa1: 0,
        mb1: 0.27,
        mb2: 0.25,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.41,
        bl3: 0,
        vf1: 0.54,
        vf2: 0.6,
        sa1: 0,
        mb1: 0.21,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.3,
        bl3: 0.32,
        vf1: 0.17,
        vf2: 0.23,
        sa1: 0,
        mb1: 0.52,
        mb2: 0.47,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.3,
        bl3: 0.12,
        vf1: 0.32,
        vf2: 0.21,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.27,
        bl3: 0,
        vf1: 0.56,
        vf2: 0.38,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.41,
        bl3: 0.21,
        vf1: 0.67,
        vf2: 0.61,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.64,
        bl3: 0.49,
        vf1: 0,
        vf2: 0.5,
        sa1: 0,
        mb1: 0.73,
        mb2: 0.71,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.54,
        bl3: 0,
        vf1: 0.34,
        vf2: 0.31,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.53,
        bl3: 0,
        vf1: 0.48,
        vf2: 0.44,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.46,
        bl3: 0,
        vf1: 0.26,
        vf2: 0.3,
        sa1: 0,
        mb1: 0.34,
        mb2: 0.35,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0.34,
        vf1: 0.6,
        vf2: 0.75,
        sa1: 0,
        mb1: 0.72,
        mb2: 0.63,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.27,
        bl3: 0.15,
        vf1: 0.63,
        vf2: 0.56,
        sa1: 0,
        mb1: 0.25,
        mb2: 0.17,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.39,
        bl3: 0,
        vf1: 0.7,
        vf2: 0.74,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.25,
        bl3: 0.35,
        vf1: 0.29,
        vf2: 0.32,
        sa1: 0,
        mb1: 0.64,
        mb2: 0.54,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.46,
        bl3: 0,
        vf1: 0.4,
        vf2: 0.28,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.73,
        vf2: 0.84,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.41,
        bl3: 0,
        vf1: 0.81,
        vf2: 0.84,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.67,
        bl3: 0,
        vf1: 0.6,
        vf2: 0.54,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.45,
        bl3: 0,
        vf1: 0.81,
        vf2: 0.83,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.51,
        bl3: 0,
        vf1: 0.7,
        vf2: 0.74,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.32,
        bl3: 0,
        vf1: 0,
        vf2: 0.2,
        sa1: 0,
        mb1: 0.18,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.38,
        bl3: 0.26,
        vf1: 0.29,
        vf2: 0.45,
        sa1: 0,
        mb1: 0.63,
        mb2: 0.58,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.44,
        bl3: 0.34,
        vf1: 0.18,
        vf2: 0.24,
        sa1: 0,
        mb1: 0.41,
        mb2: 0.39,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.34,
        bl3: 0.16,
        vf1: 0.19,
        vf2: 0.23,
        sa1: 0,
        mb1: 0.2,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.43,
        bl3: 0.17,
        vf1: 0.37,
        vf2: 0.38,
        sa1: 0,
        mb1: 0.17,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.16,
        bl3: 0,
        vf1: 0.79,
        vf2: 0.88,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.29,
        bl3: 0,
        vf1: 0.61,
        vf2: 0.41,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.27,
        bl3: 0,
        vf1: 0.72,
        vf2: 0.77,
        sa1: 0,
        mb1: 0.14,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.17,
        bl3: 0,
        vf1: 0.45,
        vf2: 0.35,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.28,
        bl3: 0.22,
        vf1: 0.51,
        vf2: 0.47,
        sa1: 0,
        mb1: 0.44,
        mb2: 0.41,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.53,
        bl3: 0.43,
        vf1: 0.73,
        vf2: 0.7,
        sa1: 0,
        mb1: 0.32,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.49,
        bl3: 0,
        vf1: 0.77,
        vf2: 1,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.42,
        bl3: 0.21,
        vf1: 0.52,
        vf2: 0.59,
        sa1: 0,
        mb1: 0.33,
        mb2: 0.32,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.17,
        bl3: 0,
        vf1: 0.79,
        vf2: 0.79,
        sa1: 0.2,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.51,
        bl3: 0.26,
        vf1: 0.71,
        vf2: 0.76,
        sa1: 0,
        mb1: 0.26,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.36,
        bl3: 0,
        vf1: 0.79,
        vf2: 0.83,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.75,
        vf2: 0.82,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.54,
        bl3: 0.49,
        vf1: 0.14,
        vf2: 0.46,
        sa1: 0,
        mb1: 0.78,
        mb2: 0.77,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.27,
        bl3: 0.27,
        vf1: 0.49,
        vf2: 0.39,
        sa1: 0,
        mb1: 0.6,
        mb2: 0.47,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.77,
        vf2: 0.73,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.36,
        bl3: 0,
        vf1: 0.68,
        vf2: 0.77,
        sa1: 0,
        mb1: 0.29,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.44,
        bl3: 0,
        vf1: 0.46,
        vf2: 0.34,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.43,
        bl3: 0,
        vf1: 0.75,
        vf2: 0.65,
        sa1: 0,
        mb1: 0.28,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.55,
        bl3: 0.35,
        vf1: 0.28,
        vf2: 0.3,
        sa1: 0,
        mb1: 0.46,
        mb2: 0.39,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.59,
        bl3: 0,
        vf1: 0.58,
        vf2: 0.6,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.57,
        bl3: 0,
        vf1: 0.67,
        vf2: 0.68,
        sa1: 0,
        mb1: 0.41,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.41,
        bl3: 0.17,
        vf1: 0.19,
        vf2: 0.17,
        sa1: 0,
        mb1: 0.19,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.66,
        bl3: 0.32,
        vf1: 0.48,
        vf2: 0.47,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.24,
        bl3: 0,
        vf1: 0.13,
        vf2: 0.14,
        sa1: 0,
        mb1: 0.3,
        mb2: 0.27,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.4,
        bl3: 0.25,
        vf1: 0.15,
        vf2: 0.15,
        sa1: 0,
        mb1: 0.43,
        mb2: 0.43,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.3,
        bl3: 0.41,
        vf1: 0.18,
        vf2: 0.35,
        sa1: 0,
        mb1: 0.67,
        mb2: 0.65,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.32,
        bl3: 0.18,
        vf1: 0.2,
        vf2: 0.21,
        sa1: 0,
        mb1: 0.3,
        mb2: 0.29,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.62,
        vf2: 0.63,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.15,
        bl3: 0,
        vf1: 0.8,
        vf2: 0.82,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.48,
        bl3: 0.33,
        vf1: 0.52,
        vf2: 0.44,
        sa1: 0,
        mb1: 0.18,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.52,
        bl3: 0.41,
        vf1: 0.33,
        vf2: 0.33,
        sa1: 0,
        mb1: 0.47,
        mb2: 0.46,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.28,
        bl3: 0,
        vf1: 0.62,
        vf2: 0.62,
        sa1: 0,
        mb1: 0.13,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.27,
        bl3: 0,
        vf1: 0.44,
        vf2: 0.37,
        sa1: 0,
        mb1: 0.25,
        mb2: 0.23,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.36,
        bl3: 0.19,
        vf1: 0.51,
        vf2: 0.63,
        sa1: 0,
        mb1: 0.56,
        mb2: 0.54,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.59,
        bl3: 0,
        vf1: 0.53,
        vf2: 0.4,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.67,
        bl3: 0.58,
        vf1: 0,
        vf2: 0.5,
        sa1: 0,
        mb1: 0.8,
        mb2: 0.76,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.82,
        vf2: 1,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.38,
        bl3: 0.14,
        vf1: 0.66,
        vf2: 0.64,
        sa1: 0,
        mb1: 0.16,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.27,
        bl3: 0,
        vf1: 0.78,
        vf2: 0.81,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.41,
        bl3: 0.16,
        vf1: 0.58,
        vf2: 0.52,
        sa1: 0,
        mb1: 0.15,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.22,
        bl3: 0,
        vf1: 0.81,
        vf2: 0.9,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.59,
        vf2: 0.53,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.64,
        vf2: 0.49,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.78,
        vf2: 0.8,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.31,
        bl3: 0.32,
        vf1: 0.23,
        vf2: 0.2,
        sa1: 0,
        mb1: 0.66,
        mb2: 0.57,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.24,
        bl3: 0,
        vf1: 0.75,
        vf2: 0.77,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.74,
        vf2: 0.83,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.26,
        vf2: 0.2,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.35,
        bl3: 0,
        vf1: 0.8,
        vf2: 0.8,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.47,
        bl3: 0,
        vf1: 0.5,
        vf2: 0.42,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.41,
        bl3: 0,
        vf1: 0.63,
        vf2: 0.53,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.35,
        bl3: 0,
        vf1: 0.34,
        vf2: 0.26,
        sa1: 0,
        mb1: 0.27,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.76,
        vf2: 0.85,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.42,
        bl3: 0,
        vf1: 0.66,
        vf2: 0.72,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.55,
        bl3: 0,
        vf1: 0.8,
        vf2: 0.87,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.53,
        bl3: 0,
        vf1: 0.77,
        vf2: 0.8,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.64,
        bl3: 0.43,
        vf1: 0,
        vf2: 0.18,
        sa1: 0,
        mb1: 0.17,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.31,
        bl3: 0.13,
        vf1: 0.11,
        vf2: 0.19,
        sa1: 0,
        mb1: 0.4,
        mb2: 0.38,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.34,
        bl3: 0.28,
        vf1: 0.22,
        vf2: 0.21,
        sa1: 0,
        mb1: 0.38,
        mb2: 0.33,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.28,
        bl3: 0.14,
        vf1: 0.37,
        vf2: 0.43,
        sa1: 0,
        mb1: 0.36,
        mb2: 0.34,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.16,
        bl3: 0,
        vf1: 0.53,
        vf2: 0.47,
        sa1: 0,
        mb1: 0.13,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.29,
        bl3: 0.21,
        vf1: 0,
        vf2: 0.27,
        sa1: 0,
        mb1: 0.51,
        mb2: 0.44,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.18,
        bl3: 0,
        vf1: 0.69,
        vf2: 0.74,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.42,
        bl3: 0.23,
        vf1: 0.46,
        vf2: 0.4,
        sa1: 0,
        mb1: 0.16,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0,
        vf1: 0.67,
        vf2: 0.69,
        sa1: 0,
        mb1: 0.18,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.22,
        bl3: 0,
        vf1: 0.66,
        vf2: 0.7,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.24,
        bl3: 0.14,
        vf1: 0.34,
        vf2: 0.24,
        sa1: 0,
        mb1: 0.18,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.34,
        bl3: 0,
        vf1: 0.64,
        vf2: 0.73,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.51,
        bl3: 0.32,
        vf1: 0.2,
        vf2: 0.34,
        sa1: 0,
        mb1: 0.59,
        mb2: 0.57,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.55,
        bl3: 0,
        vf1: 0.41,
        vf2: 0.3,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.52,
        bl3: 0.77,
        vf1: 0.83,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.26,
        bl3: 0,
        vf1: 0.6,
        vf2: 0.4,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.25,
        bl3: 0.15,
        vf1: 0.67,
        vf2: 0.74,
        sa1: 0,
        mb1: 0.2,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.46,
        bl3: 0.18,
        vf1: 0.28,
        vf2: 0.29,
        sa1: 0,
        mb1: 0.37,
        mb2: 0.35,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.41,
        bl3: 0,
        vf1: 0.76,
        vf2: 0.83,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Menstrual blood',
        se1: 0,
        se2: 0,
        bl1: 0.13,
        bl3: 0,
        vf1: 0.81,
        vf2: 0.85,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.74,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.63,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.58,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.09,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.36,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.44,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.6,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.07,
        vf2: 0,
        sa1: 0.89,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.08,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.36,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.65,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.66,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.71,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.06,
        vf2: 0,
        sa1: 0.54,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.42,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.36,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.58,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.73,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.16,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.59,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.36,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.11,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.75,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.29,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0.12,
        se2: 0,
        bl1: 0.09,
        bl3: 0,
        vf1: 0.09,
        vf2: 0.09,
        sa1: 0.74,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.2,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.61,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.42,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.06,
        vf2: 0.11,
        sa1: 0.77,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.55,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.22,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.42,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.67,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.23,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.34,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.55,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.5,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.43,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.56,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.11,
        vf2: 0,
        sa1: 0.53,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.4,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.1,
        vf2: 0,
        sa1: 0.77,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.51,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.12,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.69,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.51,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.71,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.81,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.46,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.62,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.76,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.63,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.08,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.12,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.34,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.65,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.76,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.17,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.71,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.58,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.18,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.85,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.43,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.7,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.61,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.14,
        vf2: 0,
        sa1: 0.82,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.36,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.6,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.92,
        bl1: 0.23,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.95,
        bl1: 0.15,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0.14,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0.23,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.96,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.94,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.92,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.94,
        bl1: 0.42,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.87,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.86,
        bl1: 0.12,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.62,
        se2: 0.62,
        bl1: 0.18,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.95,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.8,
        se2: 0.8,
        bl1: 0.12,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.51,
        se2: 0.5,
        bl1: 0.18,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.94,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.92,
        bl1: 0.14,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0.11,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.95,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.56,
        se2: 0.58,
        bl1: 0.16,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.95,
        se2: 0.93,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.94,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.92,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.94,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.94,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.94,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.92,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.9,
        se2: 0.9,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.94,
        se2: 0.93,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.94,
        se2: 0.93,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.94,
        se2: 0.94,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.92,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.92,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.84,
        se2: 0.84,
        bl1: 0.16,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.92,
        se2: 1,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.92,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.94,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.94,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.9,
        bl1: 0.3,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.95,
        bl1: 0.16,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.96,
        se2: 0.94,
        bl1: 0.12,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.89,
        se2: 0.93,
        bl1: 0.14,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.94,
        bl1: 0.17,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.96,
        bl1: 0.16,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0.13,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0.26,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0.21,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0.26,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.95,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0.29,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.85,
        se2: 0.88,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 0.82,
        se2: 0.84,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.93,
        bl1: 0.25,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0.18,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 1,
        bl1: 0.19,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.95,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.31,
        bl3: 0,
        vf1: 0.42,
        vf2: 0.39,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.68,
        vf2: 0.7,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.1,
        bl3: 0,
        vf1: 0.56,
        vf2: 0.62,
        sa1: 0.24,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.63,
        vf2: 0.47,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0,
        vf1: 0.68,
        vf2: 0.69,
        sa1: 0.38,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.75,
        vf2: 0.77,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.65,
        vf2: 0.43,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.56,
        vf2: 0.73,
        sa1: 0.17,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.78,
        vf2: 0.85,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.6,
        vf2: 0.46,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.7,
        vf2: 0.88,
        sa1: 0.52,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.41,
        vf2: 0.34,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.52,
        vf2: 0.9,
        sa1: 0.37,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.74,
        vf2: 0.8,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.7,
        vf2: 0.63,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.26,
        vf2: 0.18,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.74,
        vf2: 0.85,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.1,
        bl3: 0,
        vf1: 0.38,
        vf2: 0.2,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.71,
        vf2: 0.67,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.49,
        vf2: 0.33,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.7,
        vf2: 0.8,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.59,
        vf2: 0.47,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.77,
        vf2: 0.88,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.75,
        vf2: 0.73,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.66,
        vf2: 0.6,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.79,
        vf2: 0.82,
        sa1: 0.17,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.75,
        vf2: 0.84,
        sa1: 0.22,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.73,
        vf2: 0.8,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.8,
        vf2: 0.8,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.72,
        vf2: 0.66,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0,
        vf1: 0.78,
        vf2: 0.79,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.72,
        vf2: 0.59,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.74,
        vf2: 0.53,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.3,
        bl3: 0,
        vf1: 0.8,
        vf2: 0.82,
        sa1: 0.15,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.78,
        vf2: 0.88,
        sa1: 0.24,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.72,
        vf2: 0.83,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.83,
        vf2: 0.87,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.59,
        vf2: 0.38,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.75,
        vf2: 0.71,
        sa1: 0.15,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.12,
        bl3: 0,
        vf1: 0.76,
        vf2: 0.74,
        sa1: 0.38,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.56,
        vf2: 0.4,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.34,
        bl3: 0.27,
        vf1: 0.66,
        vf2: 0.49,
        sa1: 0.2,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.16,
        bl3: 0,
        vf1: 0.65,
        vf2: 0.58,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.65,
        vf2: 0.58,
        sa1: 0.36,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.11,
        bl3: 0,
        vf1: 0.48,
        vf2: 0.45,
        sa1: 0.28,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.22,
        bl3: 0.22,
        vf1: 0.78,
        vf2: 0.78,
        sa1: 0.19,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.22,
        bl3: 0,
        vf1: 0.61,
        vf2: 0.55,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.1,
        bl3: 0,
        vf1: 0.69,
        vf2: 0.74,
        sa1: 0.32,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.83,
        vf2: 0.84,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.17,
        bl3: 0.18,
        vf1: 0.61,
        vf2: 0.42,
        sa1: 0.26,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.84,
        vf2: 0.83,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.79,
        vf2: 0.75,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.67,
        vf2: 0.72,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.25,
        bl3: 0,
        vf1: 0.75,
        vf2: 0.76,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.17,
        bl3: 0,
        vf1: 0.41,
        vf2: 0.44,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.76,
        vf2: 0.82,
        sa1: 0.18,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.15,
        bl3: 0,
        vf1: 0.77,
        vf2: 0.63,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.74,
        vf2: 0.87,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.78,
        vf2: 0.77,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.19,
        bl3: 0,
        vf1: 0.83,
        vf2: 0.89,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.17,
        bl3: 0,
        vf1: 0.83,
        vf2: 0.87,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.84,
        vf2: 0.91,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.27,
        bl3: 0,
        vf1: 0.74,
        vf2: 0.72,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3130,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.3,
        bl3: 0,
        vf1: 0.74,
        vf2: 0.91,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.44,
        bl3: 0.29,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.36,
        bl3: 0.2,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.46,
        bl3: 0.25,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.3,
        bl3: 0.19,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.21,
        bl3: 0.16,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.4,
        bl3: 0.34,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.43,
        bl3: 0.33,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.53,
        bl3: 0.52,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.31,
        bl3: 0.25,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.5,
        bl3: 0.41,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.44,
        bl3: 0.31,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.32,
        bl3: 0.21,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.43,
        bl3: 0.29,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.39,
        bl3: 0.3,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.43,
        bl3: 0.28,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.46,
        bl3: 0.32,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.42,
        bl3: 0.32,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.36,
        bl3: 0.26,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.49,
        bl3: 0.41,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.36,
        bl3: 0.33,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.45,
        bl3: 0.35,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Blood',
        se1: 0,
        se2: 0,
        bl1: 0.38,
        bl3: 0.26,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.13,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.35,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.2,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.45,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.11,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.73,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.17,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.52,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.13,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.55,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.42,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.13,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.82,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.19,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.24,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.17,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.25,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.05,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.57,
        mb1: 0.04,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.25,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.27,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.11,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.46,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.21,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.32,
        mb1: 0.08,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.1,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.41,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.29,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.26,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.3,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.46,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.2,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.34,
        mb1: 0.09,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.23,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.31,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.26,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.59,
        mb1: 0.1,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.18,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.28,
        mb1: 0.07,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Saliva',
        se1: 0,
        se2: 0,
        bl1: 0.21,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0.6,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.95,
        se2: 0.91,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.67,
        se2: 0.9,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.92,
        se2: 0.88,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.93,
        se2: 0.83,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.92,
        se2: 0.89,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.94,
        se2: 0.92,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.62,
        se2: 0.79,
        bl1: 0.06,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.93,
        se2: 0.9,
        bl1: 0.03,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.92,
        se2: 0.9,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.94,
        se2: 0.92,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.92,
        se2: 0.87,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.92,
        se2: 0.89,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.93,
        se2: 0.92,
        bl1: 0.02,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 1,
        se2: 0.85,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.95,
        se2: 0.85,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.86,
        se2: 0.86,
        bl1: 0.03,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.94,
        se2: 0.9,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.95,
        se2: 0.88,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.93,
        se2: 0.89,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.93,
        se2: 0.88,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.9,
        se2: 0.86,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.83,
        se2: 0.88,
        bl1: 0,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Semen',
        se1: 0.78,
        se2: 0.89,
        bl1: 0.06,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.51,
        vf2: 0.55,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.31,
        vf2: 0.3,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.42,
        vf2: 0.38,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.05,
        bl3: 0,
        vf1: 0.15,
        vf2: 0.24,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.64,
        vf2: 0.68,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.2,
        bl3: 0,
        vf1: 0.18,
        vf2: 0.17,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.59,
        vf2: 0.46,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.04,
        bl3: 0,
        vf1: 0.41,
        vf2: 0.31,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.62,
        vf2: 0.55,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.61,
        vf2: 0.58,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.66,
        vf2: 0.85,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.04,
        bl3: 0,
        vf1: 0.57,
        vf2: 0.53,
        sa1: 0,
        mb1: 0.03,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.67,
        vf2: 0.71,
        sa1: 0.16,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.67,
        vf2: 0.7,
        sa1: 0.09,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.08,
        bl3: 0,
        vf1: 0,
        vf2: 0,
        sa1: 0,
        mb1: 0.03,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.46,
        vf2: 0.4,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.25,
        bl3: 0.13,
        vf1: 0.69,
        vf2: 0.72,
        sa1: 0.15,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.04,
        bl3: 0,
        vf1: 0.63,
        vf2: 0.68,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.7,
        vf2: 0.84,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0,
        bl3: 0,
        vf1: 0.7,
        vf2: 0.84,
        sa1: 0.16,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.04,
        bl3: 0,
        vf1: 0.61,
        vf2: 0.63,
        sa1: 0.26,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0,
        vf1: 0.34,
        vf2: 0.34,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.18,
        bl3: 0.15,
        vf1: 0.67,
        vf2: 0.63,
        sa1: 0.11,
        mb1: 0.04,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.28,
        bl3: 0.18,
        vf1: 0.51,
        vf2: 0.49,
        sa1: 0,
        mb1: 0,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.12,
        bl3: 0,
        vf1: 0.27,
        vf2: 0.34,
        sa1: 0,
        mb1: 0.09,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.19,
        bl3: 0.25,
        vf1: 0.08,
        vf2: 0.11,
        sa1: 0,
        mb1: 0.29,
        mb2: 0.21,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.54,
        bl3: 0.23,
        vf1: 0.04,
        vf2: 0.07,
        sa1: 0,
        mb1: 0.17,
        mb2: 0.1,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.08,
        bl3: 0.16,
        vf1: 0.15,
        vf2: 0.23,
        sa1: 0,
        mb1: 0.27,
        mb2: 0.18,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.15,
        bl3: 0.1,
        vf1: 0.06,
        vf2: 0.12,
        sa1: 0,
        mb1: 0.22,
        mb2: 0.14,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.04,
        bl3: 0,
        vf1: 0.58,
        vf2: 0.56,
        sa1: 0,
        mb1: 0.06,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.33,
        bl3: 0.15,
        vf1: 0.09,
        vf2: 0.11,
        sa1: 0,
        mb1: 0.41,
        mb2: 0.09,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.12,
        bl3: 0.12,
        vf1: 0.05,
        vf2: 0.1,
        sa1: 0,
        mb1: 0.28,
        mb2: 0.21,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.15,
        bl3: 0.13,
        vf1: 0.05,
        vf2: 0.09,
        sa1: 0,
        mb1: 0.27,
        mb2: 0.21,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.21,
        bl3: 0.11,
        vf1: 0.05,
        vf2: 0.09,
        sa1: 0,
        mb1: 0.2,
        mb2: 0.13,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.23,
        bl3: 0,
        vf1: 0.17,
        vf2: 0.26,
        sa1: 0,
        mb1: 0.25,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.16,
        bl3: 0.23,
        vf1: 0.06,
        vf2: 0.19,
        sa1: 0,
        mb1: 0.41,
        mb2: 0.33,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.2,
        bl3: 0.12,
        vf1: 0.09,
        vf2: 0.14,
        sa1: 0,
        mb1: 0.27,
        mb2: 0.19,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.22,
        bl3: 0.15,
        vf1: 0.07,
        vf2: 0.14,
        sa1: 0,
        mb1: 0.21,
        mb2: 0.14,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.09,
        bl3: 0,
        vf1: 0.06,
        vf2: 0.1,
        sa1: 0,
        mb1: 0.13,
        mb2: 0.09,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.09,
        bl3: 0,
        vf1: 0.03,
        vf2: 0.05,
        sa1: 0,
        mb1: 0.06,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.13,
        bl3: 0.1,
        vf1: 0.04,
        vf2: 0.67,
        sa1: 0,
        mb1: 0.27,
        mb2: 0.21,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.12,
        bl3: 0.14,
        vf1: 0.04,
        vf2: 0.14,
        sa1: 0,
        mb1: 0.32,
        mb2: 0.22,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.1,
        bl3: 0,
        vf1: 0.38,
        vf2: 0.43,
        sa1: 0,
        mb1: 0.07,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.07,
        bl3: 0,
        vf1: 0.55,
        vf2: 0.58,
        sa1: 0,
        mb1: 0.05,
        mb2: 0,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.32,
        bl3: 0.31,
        vf1: 0.07,
        vf2: 0.29,
        sa1: 0,
        mb1: 0.62,
        mb2: 0.55,
    },
    {
        g_analyzer: 3500,
        bf_type: 'Vaginal fluid',
        se1: 0,
        se2: 0,
        bl1: 0.14,
        bl3: 0.1,
        vf1: 0.12,
        vf2: 0.17,
        sa1: 0,
        mb1: 0.21,
        mb2: 0.13,
    },
];

export const DATA_SNAP_OT = [
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Forward',
        epi1: 0.0,
        epi2: 0.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Forward',
        epi1: 0.08,
        epi2: 0.11,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Forward',
        epi1: 0.06,
        epi2: 0.1,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Forward',
        epi1: 0.08,
        epi2: 0.11,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Forward',
        epi1: 0.12,
        epi2: 0.08,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Forward',
        epi1: 0.09,
        epi2: 0.1,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Forward',
        epi1: 0.05,
        epi2: 0.07,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Forward',
        epi1: 0.06,
        epi2: 0.08,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Forward',
        epi1: 0.06,
        epi2: 0.12,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Forward',
        epi1: 0.82,
        epi2: 0.86,
        der1: 0.81,
        der2: 0.81,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Forward',
        epi1: 0.86,
        epi2: 0.84,
        der1: 0.84,
        der2: 0.87,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Forward',
        epi1: 0.79,
        epi2: 0.83,
        der1: 0.82,
        der2: 0.84,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 0.93,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Forward',
        epi1: 0.83,
        epi2: 0.84,
        der1: 0.83,
        der2: 0.82,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Forward',
        epi1: 0.86,
        epi2: 0.88,
        der1: 0.8,
        der2: 0.77,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Forward',
        epi1: 0.88,
        epi2: 0.89,
        der1: 0.89,
        der2: 0.89,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Forward',
        epi1: 0.79,
        epi2: 0.82,
        der1: 0.85,
        der2: 0.81,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Forward',
        epi1: 0.86,
        epi2: 0.87,
        der1: 0.81,
        der2: 0.77,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Forward',
        epi1: 0.85,
        epi2: 0.84,
        der1: 0.82,
        der2: 0.82,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Forward',
        epi1: 0.8,
        epi2: 0.83,
        der1: 0.85,
        der2: 0.82,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.42,
        hrt2: 0.52,
        mcl1: 1.0,
        mcl2: 0.94,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.59,
        hrt2: 0.6,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.97,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.98,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.42,
        hrt2: 0.5,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 0.96,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.45,
        hrt2: 0.51,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.96,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.62,
        hrt2: 0.67,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.4,
        hrt2: 0.53,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.58,
        hrt2: 0.65,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.94,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.49,
        hrt2: 0.46,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.6,
        hrt2: 0.62,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.97,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.5,
        hrt2: 0.6,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 0.46,
        mcl2: 0.42,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 0.85,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 0.46,
        mcl2: 0.62,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 0.49,
        mcl2: 0.47,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.85,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 0.57,
        mcl2: 0.49,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Forward',
        epi1: 0.92,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 0.87,
        mcl1: 0.44,
        mcl2: 0.49,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 0.96,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.94,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 0.87,
        mcl1: 0.41,
        mcl2: 0.46,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 0.41,
        mcl2: 0.48,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 0.5,
        mcl2: 0.49,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 0.44,
        mcl2: 0.49,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 0.44,
        mcl2: 0.6,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.39,
        bl2: 0.24,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.52,
        bl2: 0.34,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.69,
        bl2: 0.51,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.51,
        bl2: 0.33,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.61,
        bl2: 0.46,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.38,
        bl2: 0.23,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.4,
        bl2: 0.25,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.74,
        bl2: 0.53,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.44,
        bl2: 0.22,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.81,
        bl2: 0.64,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.97,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.83,
        kid2: 0.69,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.93,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.86,
        kid2: 0.62,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.82,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.77,
        kid2: 0.6,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.87,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.94,
        der1: 0.96,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.67,
        kid2: 0.51,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.75,
        kid2: 0.6,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.89,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.86,
        kid2: 0.68,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.84,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.81,
        kid2: 0.47,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.74,
        kid2: 0.6,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.92,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.88,
        kid2: 0.74,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.75,
        kid2: 0.64,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.89,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 0.95,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.31,
        br2: 0.33,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.27,
        br2: 0.24,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.93,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 0.93,
        mcl2: 0.94,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 0.97,
        br1: 0.29,
        br2: 0.29,
        lu1: 0.98,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.32,
        br2: 0.3,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.94,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.27,
        br2: 0.24,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.93,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.29,
        br2: 0.35,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.95,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.32,
        br2: 0.32,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.93,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.25,
        br2: 0.37,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.9,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.44,
        br2: 0.5,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.94,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.4,
        br2: 0.4,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.92,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.57,
        lu2: 0.8,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.89,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 0.93,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 0.92,
        lu1: 0.38,
        lu2: 0.77,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.92,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 0.81,
        mcl1: 1.0,
        mcl2: 0.95,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.57,
        lu2: 0.82,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.89,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 0.84,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.51,
        lu2: 0.82,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.91,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.46,
        lu2: 0.71,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.92,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 0.87,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.43,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.92,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 0.91,
        bl2: 0.87,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.59,
        lu2: 0.85,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.92,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 0.93,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.45,
        lu2: 0.77,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.88,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.42,
        lu2: 0.75,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 0.94,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.45,
        lu2: 0.78,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 0.35,
        liv2: 0.52,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 0.22,
        liv2: 0.64,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 0.37,
        liv2: 0.55,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 0.91,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 0.31,
        liv2: 0.45,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 0.1,
        liv2: 0.25,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 0.15,
        liv2: 0.3,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 0.22,
        liv2: 0.35,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 0.09,
        liv2: 0.24,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Forward',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 1.0,
        mcl1: 1.0,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 1.0,
        lu2: 1.0,
        liv1: 0.1,
        liv2: 0.17,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Reverse',
        epi1: 0.07,
        epi2: 0.0,
        der1: 0.91,
        der2: 0.93,
        hrt1: 0.84,
        hrt2: 0.93,
        mcl1: 0.91,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 0.91,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.91,
        lu2: 0.88,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Reverse',
        epi1: 0.08,
        epi2: 0.09,
        der1: 0.91,
        der2: 0.9,
        hrt1: 0.93,
        hrt2: 0.89,
        mcl1: 0.93,
        mcl2: 0.88,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.89,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.88,
        lu2: 0.91,
        liv1: 1.0,
        liv2: 0.88,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Reverse',
        epi1: 0.07,
        epi2: 0.09,
        der1: 0.92,
        der2: 0.89,
        hrt1: 0.92,
        hrt2: 0.85,
        mcl1: 0.93,
        mcl2: 0.89,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.89,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Reverse',
        epi1: 0.08,
        epi2: 0.09,
        der1: 0.91,
        der2: 0.87,
        hrt1: 0.89,
        hrt2: 0.86,
        mcl1: 0.91,
        mcl2: 0.84,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.88,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Reverse',
        epi1: 0.06,
        epi2: 0.06,
        der1: 0.75,
        der2: 0.76,
        hrt1: 0.88,
        hrt2: 0.75,
        mcl1: 0.86,
        mcl2: 0.86,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.82,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.72,
        lu2: 0.83,
        liv1: 1.0,
        liv2: 0.82,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Reverse',
        epi1: 0.15,
        epi2: 0.0,
        der1: 0.87,
        der2: 0.87,
        hrt1: 1.0,
        hrt2: 0.86,
        mcl1: 1.0,
        mcl2: 0.94,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.77,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.88,
        lu2: 0.87,
        liv1: 1.0,
        liv2: 0.85,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Reverse',
        epi1: 0.13,
        epi2: 0.16,
        der1: 0.95,
        der2: 0.95,
        hrt1: 0.96,
        hrt2: 0.95,
        mcl1: 0.96,
        mcl2: 0.92,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.97,
        lu2: 0.93,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Reverse',
        epi1: 0.06,
        epi2: 0.0,
        der1: 1.0,
        der2: 0.9,
        hrt1: 1.0,
        hrt2: 0.94,
        mcl1: 0.88,
        mcl2: 0.78,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.94,
        lu2: 0.82,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Epidermis',
        direction: 'Reverse',
        epi1: 0.06,
        epi2: 0.09,
        der1: 0.94,
        der2: 0.91,
        hrt1: 1.0,
        hrt2: 0.92,
        mcl1: 0.92,
        mcl2: 0.9,
        bl1: 1.0,
        bl2: 0.95,
        kid1: 0.9,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.9,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Reverse',
        epi1: 0.75,
        epi2: 0.79,
        der1: 0.59,
        der2: 0.64,
        hrt1: 0.9,
        hrt2: 0.91,
        mcl1: 0.87,
        mcl2: 0.83,
        bl1: 1.0,
        bl2: 0.94,
        kid1: 0.86,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.86,
        lu2: 0.88,
        liv1: 1.0,
        liv2: 0.88,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Reverse',
        epi1: 0.82,
        epi2: 0.74,
        der1: 0.64,
        der2: 0.74,
        hrt1: 0.9,
        hrt2: 0.9,
        mcl1: 0.88,
        mcl2: 0.83,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 0.96,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.82,
        lu2: 0.91,
        liv1: 1.0,
        liv2: 0.89,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Reverse',
        epi1: 0.75,
        epi2: 0.73,
        der1: 0.6,
        der2: 0.69,
        hrt1: 0.88,
        hrt2: 0.83,
        mcl1: 0.85,
        mcl2: 1.0,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.82,
        lu2: 0.91,
        liv1: 1.0,
        liv2: 0.88,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Reverse',
        epi1: 0.78,
        epi2: 0.75,
        der1: 0.62,
        der2: 0.71,
        hrt1: 1.0,
        hrt2: 0.91,
        mcl1: 0.88,
        mcl2: 0.82,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.87,
        kid2: 1.0,
        br1: 0.96,
        br2: 0.91,
        lu1: 0.84,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 0.87,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Reverse',
        epi1: 0.83,
        epi2: 0.98,
        der1: 0.57,
        der2: 0.61,
        hrt1: 0.81,
        hrt2: 0.9,
        mcl1: 0.85,
        mcl2: 0.84,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.85,
        lu2: 0.87,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Reverse',
        epi1: 0.84,
        epi2: 0.65,
        der1: 0.44,
        der2: 0.59,
        hrt1: 0.88,
        hrt2: 0.73,
        mcl1: 0.81,
        mcl2: 0.87,
        bl1: 1.0,
        bl2: 0.87,
        kid1: 0.88,
        kid2: 1.0,
        br1: 0.94,
        br2: 0.81,
        lu1: 0.74,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 0.84,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Reverse',
        epi1: 0.73,
        epi2: 0.64,
        der1: 0.58,
        der2: 0.63,
        hrt1: 0.89,
        hrt2: 0.83,
        mcl1: 0.89,
        mcl2: 0.87,
        bl1: 1.0,
        bl2: 0.93,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 0.91,
        lu1: 0.82,
        lu2: 0.88,
        liv1: 1.0,
        liv2: 0.86,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Reverse',
        epi1: 0.82,
        epi2: 0.88,
        der1: 0.81,
        der2: 0.79,
        hrt1: 0.93,
        hrt2: 0.96,
        mcl1: 0.91,
        mcl2: 0.81,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 0.94,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.94,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 0.91,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Reverse',
        epi1: 0.79,
        epi2: 0.77,
        der1: 0.68,
        der2: 0.71,
        hrt1: 0.93,
        hrt2: 0.93,
        mcl1: 0.85,
        mcl2: 0.8,
        bl1: 1.0,
        bl2: 0.94,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.85,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 0.86,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Dermis',
        direction: 'Reverse',
        epi1: 0.78,
        epi2: 0.74,
        der1: 0.64,
        der2: 0.68,
        hrt1: 1.0,
        hrt2: 0.91,
        mcl1: 0.88,
        mcl2: 0.88,
        bl1: 1.0,
        bl2: 0.93,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.84,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.87,
        der2: 0.93,
        hrt1: 0.27,
        hrt2: 0.33,
        mcl1: 0.89,
        mcl2: 0.87,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.85,
        kid2: 0.94,
        br1: 0.93,
        br2: 1.0,
        lu1: 0.95,
        lu2: 0.87,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.91,
        der1: 0.88,
        der2: 0.92,
        hrt1: 0.43,
        hrt2: 0.44,
        mcl1: 0.89,
        mcl2: 0.78,
        bl1: 1.0,
        bl2: 0.93,
        kid1: 1.0,
        kid2: 0.96,
        br1: 0.95,
        br2: 0.92,
        lu1: 0.94,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 0.9,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Reverse',
        epi1: 0.94,
        epi2: 0.91,
        der1: 0.87,
        der2: 0.91,
        hrt1: 0.29,
        hrt2: 0.32,
        mcl1: 0.88,
        mcl2: 0.83,
        bl1: 0.93,
        bl2: 1.0,
        kid1: 0.84,
        kid2: 0.97,
        br1: 0.94,
        br2: 1.0,
        lu1: 0.96,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 0.92,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.88,
        der2: 0.93,
        hrt1: 0.28,
        hrt2: 0.3,
        mcl1: 0.87,
        mcl2: 0.84,
        bl1: 0.92,
        bl2: 1.0,
        kid1: 0.83,
        kid2: 0.92,
        br1: 0.95,
        br2: 1.0,
        lu1: 0.94,
        lu2: 0.87,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Reverse',
        epi1: 0.96,
        epi2: 1.0,
        der1: 0.89,
        der2: 0.92,
        hrt1: 0.46,
        hrt2: 0.47,
        mcl1: 0.89,
        mcl2: 0.84,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.86,
        kid2: 0.88,
        br1: 0.95,
        br2: 1.0,
        lu1: 0.88,
        lu2: 0.87,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 0.92,
        hrt1: 0.28,
        hrt2: 0.32,
        mcl1: 0.89,
        mcl2: 0.85,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.95,
        br2: 1.0,
        lu1: 1.0,
        lu2: 0.86,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 0.92,
        der1: 0.88,
        der2: 0.89,
        hrt1: 0.38,
        hrt2: 0.42,
        mcl1: 0.86,
        mcl2: 0.82,
        bl1: 1.0,
        bl2: 0.94,
        kid1: 0.85,
        kid2: 0.95,
        br1: 0.96,
        br2: 0.94,
        lu1: 0.95,
        lu2: 0.82,
        liv1: 1.0,
        liv2: 0.87,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Reverse',
        epi1: 0.93,
        epi2: 0.84,
        der1: 0.88,
        der2: 0.88,
        hrt1: 0.33,
        hrt2: 0.29,
        mcl1: 0.89,
        mcl2: 0.79,
        bl1: 1.0,
        bl2: 0.94,
        kid1: 1.0,
        kid2: 0.94,
        br1: 0.94,
        br2: 1.0,
        lu1: 0.96,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 0.91,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.87,
        der2: 0.9,
        hrt1: 0.44,
        hrt2: 0.41,
        mcl1: 0.88,
        mcl2: 0.81,
        bl1: 0.92,
        bl2: 0.92,
        kid1: 1.0,
        kid2: 0.96,
        br1: 0.94,
        br2: 1.0,
        lu1: 1.0,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 0.91,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Heart',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.92,
        der1: 0.89,
        der2: 0.92,
        hrt1: 0.34,
        hrt2: 0.37,
        mcl1: 0.88,
        mcl2: 0.89,
        bl1: 1.0,
        bl2: 0.94,
        kid1: 0.87,
        kid2: 1.0,
        br1: 0.96,
        br2: 0.93,
        lu1: 0.95,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 0.91,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Reverse',
        epi1: 0.93,
        epi2: 1.0,
        der1: 0.88,
        der2: 0.92,
        hrt1: 0.86,
        hrt2: 0.85,
        mcl1: 0.25,
        mcl2: 0.14,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.92,
        lu2: 0.88,
        liv1: 1.0,
        liv2: 0.83,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Reverse',
        epi1: 0.94,
        epi2: 1.0,
        der1: 0.88,
        der2: 0.92,
        hrt1: 0.9,
        hrt2: 0.86,
        mcl1: 0.25,
        mcl2: 0.26,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.91,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 0.88,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.9,
        der2: 0.93,
        hrt1: 0.89,
        hrt2: 0.85,
        mcl1: 0.28,
        mcl2: 0.16,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.94,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 0.84,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Reverse',
        epi1: 0.94,
        epi2: 0.92,
        der1: 0.89,
        der2: 1.0,
        hrt1: 0.89,
        hrt2: 0.78,
        mcl1: 0.33,
        mcl2: 0.16,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.96,
        br2: 1.0,
        lu1: 0.93,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 0.87,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.84,
        der2: 1.0,
        hrt1: 0.92,
        hrt2: 0.83,
        mcl1: 0.26,
        mcl2: 0.2,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.93,
        lu2: 0.91,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.87,
        der2: 0.93,
        hrt1: 0.9,
        hrt2: 0.82,
        mcl1: 0.23,
        mcl2: 0.18,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.92,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.88,
        der2: 0.9,
        hrt1: 0.89,
        hrt2: 0.75,
        mcl1: 0.17,
        mcl2: 0.15,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.92,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.91,
        der1: 0.92,
        der2: 0.95,
        hrt1: 0.93,
        hrt2: 0.9,
        mcl1: 0.41,
        mcl2: 0.21,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.96,
        lu2: 0.93,
        liv1: 1.0,
        liv2: 0.87,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.91,
        der2: 0.95,
        hrt1: 0.89,
        hrt2: 0.91,
        mcl1: 0.3,
        mcl2: 0.18,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.95,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 0.83,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Skeletal Muscle',
        direction: 'Reverse',
        epi1: 0.93,
        epi2: 1.0,
        der1: 0.88,
        der2: 0.92,
        hrt1: 0.88,
        hrt2: 0.88,
        mcl1: 0.25,
        mcl2: 0.24,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 0.92,
        lu1: 0.91,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 0.86,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.93,
        der2: 1.0,
        hrt1: 0.93,
        hrt2: 0.89,
        mcl1: 0.91,
        mcl2: 0.91,
        bl1: 0.12,
        bl2: 0.15,
        kid1: 1.0,
        kid2: 0.91,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.91,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 0.69,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Reverse',
        epi1: 0.94,
        epi2: 0.9,
        der1: 0.92,
        der2: 1.0,
        hrt1: 0.88,
        hrt2: 0.84,
        mcl1: 0.9,
        mcl2: 0.92,
        bl1: 0.18,
        bl2: 0.24,
        kid1: 1.0,
        kid2: 0.94,
        br1: 1.0,
        br2: 0.91,
        lu1: 0.86,
        lu2: 0.89,
        liv1: 0.93,
        liv2: 0.85,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.91,
        der1: 0.94,
        der2: 0.93,
        hrt1: 0.87,
        hrt2: 0.85,
        mcl1: 0.9,
        mcl2: 0.94,
        bl1: 0.34,
        bl2: 0.39,
        kid1: 1.0,
        kid2: 0.93,
        br1: 1.0,
        br2: 0.92,
        lu1: 0.83,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 0.88,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 0.96,
        hrt1: 0.88,
        hrt2: 0.87,
        mcl1: 0.92,
        mcl2: 0.95,
        bl1: 0.18,
        bl2: 0.23,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 0.91,
        lu1: 0.87,
        lu2: 0.87,
        liv1: 1.0,
        liv2: 0.86,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.89,
        der2: 0.92,
        hrt1: 0.8,
        hrt2: 0.86,
        mcl1: 0.88,
        mcl2: 0.91,
        bl1: 0.25,
        bl2: 0.34,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.8,
        lu2: 0.87,
        liv1: 1.0,
        liv2: 0.85,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Reverse',
        epi1: 0.94,
        epi2: 0.88,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.93,
        hrt2: 0.86,
        mcl1: 0.91,
        mcl2: 0.92,
        bl1: 0.25,
        bl2: 0.3,
        kid1: 1.0,
        kid2: 0.94,
        br1: 0.97,
        br2: 1.0,
        lu1: 0.9,
        lu2: 0.86,
        liv1: 1.0,
        liv2: 0.89,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.9,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.9,
        hrt2: 0.87,
        mcl1: 0.91,
        mcl2: 0.93,
        bl1: 0.14,
        bl2: 0.15,
        kid1: 1.0,
        kid2: 0.92,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.88,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 0.86,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 0.92,
        der1: 1.0,
        der2: 0.94,
        hrt1: 0.82,
        hrt2: 0.86,
        mcl1: 0.9,
        mcl2: 1.0,
        bl1: 0.19,
        bl2: 0.24,
        kid1: 1.0,
        kid2: 0.92,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.84,
        lu2: 0.91,
        liv1: 1.0,
        liv2: 0.88,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Reverse',
        epi1: 0.93,
        epi2: 1.0,
        der1: 1.0,
        der2: 0.94,
        hrt1: 0.91,
        hrt2: 0.9,
        mcl1: 0.91,
        mcl2: 0.93,
        bl1: 0.13,
        bl2: 0.15,
        kid1: 1.0,
        kid2: 0.92,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.91,
        lu2: 0.86,
        liv1: 0.92,
        liv2: 0.83,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Blood',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 1.0,
        der1: 0.92,
        der2: 0.92,
        hrt1: 0.76,
        hrt2: 0.88,
        mcl1: 0.88,
        mcl2: 0.93,
        bl1: 0.5,
        bl2: 0.52,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.82,
        lu2: 0.9,
        liv1: 0.87,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Reverse',
        epi1: 0.92,
        epi2: 0.87,
        der1: 0.92,
        der2: 0.92,
        hrt1: 0.91,
        hrt2: 0.91,
        mcl1: 0.85,
        mcl2: 0.91,
        bl1: 1.0,
        bl2: 0.94,
        kid1: 0.77,
        kid2: 0.59,
        br1: 0.96,
        br2: 1.0,
        lu1: 0.83,
        lu2: 0.86,
        liv1: 1.0,
        liv2: 0.79,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 1.0,
        der1: 0.91,
        der2: 0.93,
        hrt1: 1.0,
        hrt2: 0.9,
        mcl1: 0.92,
        mcl2: 0.86,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.84,
        kid2: 0.52,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.66,
        lu2: 1.0,
        liv1: 1.0,
        liv2: 0.91,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.92,
        der2: 0.92,
        hrt1: 0.89,
        hrt2: 0.9,
        mcl1: 0.92,
        mcl2: 0.92,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.74,
        kid2: 0.55,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.75,
        lu2: 0.88,
        liv1: 1.0,
        liv2: 0.89,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 0.9,
        der1: 0.9,
        der2: 0.95,
        hrt1: 0.93,
        hrt2: 0.9,
        mcl1: 0.9,
        mcl2: 0.89,
        bl1: 1.0,
        bl2: 0.94,
        kid1: 0.62,
        kid2: 0.43,
        br1: 0.96,
        br2: 0.94,
        lu1: 0.84,
        lu2: 0.88,
        liv1: 1.0,
        liv2: 0.88,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Reverse',
        epi1: 0.92,
        epi2: 1.0,
        der1: 0.88,
        der2: 0.89,
        hrt1: 0.88,
        hrt2: 0.92,
        mcl1: 0.92,
        mcl2: 0.92,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.68,
        kid2: 0.49,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.77,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 0.88,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 1.0,
        der1: 0.91,
        der2: 0.93,
        hrt1: 0.88,
        hrt2: 0.92,
        mcl1: 0.86,
        mcl2: 0.89,
        bl1: 0.77,
        bl2: 0.94,
        kid1: 0.79,
        kid2: 0.61,
        br1: 0.96,
        br2: 0.94,
        lu1: 0.68,
        lu2: 0.88,
        liv1: 1.0,
        liv2: 0.8,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 1.0,
        der1: 0.9,
        der2: 0.94,
        hrt1: 0.91,
        hrt2: 1.0,
        mcl1: 0.9,
        mcl2: 0.9,
        bl1: 1.0,
        bl2: 0.94,
        kid1: 0.75,
        kid2: 0.4,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.84,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 0.67,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 0.91,
        der2: 0.91,
        hrt1: 0.89,
        hrt2: 0.91,
        mcl1: 0.93,
        mcl2: 0.88,
        bl1: 1.0,
        bl2: 0.94,
        kid1: 0.67,
        kid2: 0.48,
        br1: 0.96,
        br2: 1.0,
        lu1: 0.76,
        lu2: 0.92,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.84,
        der1: 0.89,
        der2: 0.89,
        hrt1: 0.91,
        hrt2: 0.9,
        mcl1: 0.89,
        mcl2: 0.87,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.82,
        kid2: 0.64,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.76,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 0.81,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Kidney',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.88,
        der1: 0.91,
        der2: 0.91,
        hrt1: 0.88,
        hrt2: 1.0,
        mcl1: 0.91,
        mcl2: 0.89,
        bl1: 1.0,
        bl2: 0.93,
        kid1: 0.7,
        kid2: 0.57,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.75,
        lu2: 0.93,
        liv1: 1.0,
        liv2: 0.85,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.93,
        hrt2: 0.92,
        mcl1: 0.91,
        mcl2: 0.88,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.81,
        kid2: 1.0,
        br1: 0.13,
        br2: 0.3,
        lu1: 0.96,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 0.91,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 0.93,
        mcl1: 0.89,
        mcl2: 0.87,
        bl1: 0.93,
        bl2: 1.0,
        kid1: 0.78,
        kid2: 0.97,
        br1: 0.11,
        br2: 0.23,
        lu1: 0.95,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 0.89,
        der1: 1.0,
        der2: 1.0,
        hrt1: 1.0,
        hrt2: 0.94,
        mcl1: 0.91,
        mcl2: 0.86,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.82,
        kid2: 0.97,
        br1: 0.13,
        br2: 0.29,
        lu1: 0.95,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.93,
        hrt2: 0.93,
        mcl1: 0.9,
        mcl2: 0.88,
        bl1: 0.89,
        bl2: 1.0,
        kid1: 0.84,
        kid2: 0.96,
        br1: 0.14,
        br2: 0.24,
        lu1: 0.95,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.91,
        hrt2: 0.93,
        mcl1: 0.9,
        mcl2: 0.89,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.78,
        kid2: 1.0,
        br1: 0.11,
        br2: 0.18,
        lu1: 1.0,
        lu2: 0.88,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 0.89,
        der1: 1.0,
        der2: 0.93,
        hrt1: 1.0,
        hrt2: 0.94,
        mcl1: 0.9,
        mcl2: 0.88,
        bl1: 0.82,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 0.93,
        br1: 0.13,
        br2: 0.31,
        lu1: 1.0,
        lu2: 0.85,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 1.0,
        hrt1: 0.92,
        hrt2: 0.92,
        mcl1: 0.93,
        mcl2: 0.9,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.75,
        kid2: 1.0,
        br1: 0.14,
        br2: 0.32,
        lu1: 0.95,
        lu2: 0.84,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 0.89,
        der1: 0.92,
        der2: 0.92,
        hrt1: 1.0,
        hrt2: 0.93,
        mcl1: 0.91,
        mcl2: 0.87,
        bl1: 1.0,
        bl2: 0.92,
        kid1: 0.82,
        kid2: 0.97,
        br1: 0.1,
        br2: 0.34,
        lu1: 0.93,
        lu2: 0.91,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 0.84,
        der1: 0.9,
        der2: 1.0,
        hrt1: 0.94,
        hrt2: 0.92,
        mcl1: 0.92,
        mcl2: 0.85,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.77,
        kid2: 1.0,
        br1: 0.21,
        br2: 0.52,
        lu1: 0.92,
        lu2: 0.9,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Brain',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 0.87,
        der1: 1.0,
        der2: 0.93,
        hrt1: 0.93,
        hrt2: 0.93,
        mcl1: 0.92,
        mcl2: 0.83,
        bl1: 0.92,
        bl2: 0.93,
        kid1: 0.82,
        kid2: 0.96,
        br1: 0.18,
        br2: 0.39,
        lu1: 0.93,
        lu2: 0.89,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Reverse',
        epi1: 0.9,
        epi2: 0.86,
        der1: 1.0,
        der2: 0.89,
        hrt1: 0.85,
        hrt2: 0.78,
        mcl1: 0.88,
        mcl2: 1.0,
        bl1: 0.82,
        bl2: 0.83,
        kid1: 0.84,
        kid2: 0.92,
        br1: 0.96,
        br2: 0.84,
        lu1: 0.38,
        lu2: 0.67,
        liv1: 1.0,
        liv2: 0.84,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Reverse',
        epi1: 0.96,
        epi2: 0.81,
        der1: 0.9,
        der2: 0.9,
        hrt1: 0.74,
        hrt2: 0.74,
        mcl1: 0.89,
        mcl2: 0.9,
        bl1: 0.84,
        bl2: 0.85,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 0.89,
        lu1: 0.23,
        lu2: 0.65,
        liv1: 1.0,
        liv2: 0.89,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Reverse',
        epi1: 0.94,
        epi2: 0.87,
        der1: 0.92,
        der2: 0.92,
        hrt1: 0.87,
        hrt2: 0.67,
        mcl1: 0.86,
        mcl2: 0.9,
        bl1: 0.87,
        bl2: 0.86,
        kid1: 1.0,
        kid2: 0.95,
        br1: 1.0,
        br2: 0.91,
        lu1: 0.37,
        lu2: 0.72,
        liv1: 0.95,
        liv2: 0.88,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.83,
        der1: 0.91,
        der2: 0.93,
        hrt1: 0.86,
        hrt2: 0.83,
        mcl1: 0.9,
        mcl2: 0.91,
        bl1: 0.75,
        bl2: 0.76,
        kid1: 0.9,
        kid2: 0.95,
        br1: 0.96,
        br2: 0.85,
        lu1: 0.31,
        lu2: 0.7,
        liv1: 1.0,
        liv2: 0.83,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.87,
        der1: 0.9,
        der2: 0.9,
        hrt1: 0.82,
        hrt2: 0.81,
        mcl1: 0.89,
        mcl2: 0.92,
        bl1: 0.88,
        bl2: 0.86,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 0.9,
        lu1: 0.28,
        lu2: 0.55,
        liv1: 1.0,
        liv2: 1.0,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 0.86,
        der1: 0.89,
        der2: 0.94,
        hrt1: 0.83,
        hrt2: 0.86,
        mcl1: 0.88,
        mcl2: 0.92,
        bl1: 0.84,
        bl2: 0.81,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 0.89,
        lu1: 0.25,
        lu2: 0.64,
        liv1: 1.0,
        liv2: 0.81,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Reverse',
        epi1: 0.94,
        epi2: 0.86,
        der1: 0.89,
        der2: 0.89,
        hrt1: 0.9,
        hrt2: 0.85,
        mcl1: 0.87,
        mcl2: 0.9,
        bl1: 0.73,
        bl2: 0.79,
        kid1: 0.82,
        kid2: 0.94,
        br1: 1.0,
        br2: 0.87,
        lu1: 0.36,
        lu2: 0.74,
        liv1: 1.0,
        liv2: 0.86,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.85,
        der1: 0.91,
        der2: 0.91,
        hrt1: 0.89,
        hrt2: 0.81,
        mcl1: 0.89,
        mcl2: 0.9,
        bl1: 0.91,
        bl2: 0.9,
        kid1: 1.0,
        kid2: 1.0,
        br1: 0.95,
        br2: 0.91,
        lu1: 0.28,
        lu2: 0.66,
        liv1: 1.0,
        liv2: 0.86,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.8,
        der1: 1.0,
        der2: 0.89,
        hrt1: 0.86,
        hrt2: 0.79,
        mcl1: 0.89,
        mcl2: 0.92,
        bl1: 1.0,
        bl2: 0.9,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.25,
        lu2: 0.64,
        liv1: 1.0,
        liv2: 0.82,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Lung',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 0.89,
        der1: 0.93,
        der2: 0.91,
        hrt1: 0.85,
        hrt2: 0.86,
        mcl1: 0.9,
        mcl2: 0.92,
        bl1: 0.9,
        bl2: 0.91,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 0.89,
        lu1: 0.28,
        lu2: 0.67,
        liv1: 1.0,
        liv2: 0.86,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 1.0,
        der1: 0.93,
        der2: 0.93,
        hrt1: 0.77,
        hrt2: 0.79,
        mcl1: 0.92,
        mcl2: 0.9,
        bl1: 1.0,
        bl2: 0.93,
        kid1: 0.86,
        kid2: 0.86,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.83,
        lu2: 0.88,
        liv1: 0.28,
        liv2: 0.26,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 1.0,
        der1: 1.0,
        der2: 0.95,
        hrt1: 0.8,
        hrt2: 0.8,
        mcl1: 0.92,
        mcl2: 0.92,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 0.93,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.76,
        lu2: 0.86,
        liv1: 0.22,
        liv2: 0.42,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Reverse',
        epi1: 0.92,
        epi2: 1.0,
        der1: 0.91,
        der2: 0.92,
        hrt1: 0.76,
        hrt2: 0.68,
        mcl1: 0.91,
        mcl2: 0.89,
        bl1: 0.91,
        bl2: 0.9,
        kid1: 0.92,
        kid2: 0.92,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.82,
        lu2: 0.92,
        liv1: 0.34,
        liv2: 0.34,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Reverse',
        epi1: 0.94,
        epi2: 1.0,
        der1: 1.0,
        der2: 0.94,
        hrt1: 0.76,
        hrt2: 0.76,
        mcl1: 0.92,
        mcl2: 0.91,
        bl1: 1.0,
        bl2: 0.92,
        kid1: 0.85,
        kid2: 0.92,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.78,
        lu2: 0.89,
        liv1: 0.29,
        liv2: 0.24,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Reverse',
        epi1: 0.93,
        epi2: 1.0,
        der1: 0.93,
        der2: 0.94,
        hrt1: 0.85,
        hrt2: 0.75,
        mcl1: 0.92,
        mcl2: 0.92,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 0.88,
        br1: 0.95,
        br2: 1.0,
        lu1: 0.8,
        lu2: 0.84,
        liv1: 0.08,
        liv2: 0.12,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Reverse',
        epi1: 0.94,
        epi2: 1.0,
        der1: 0.92,
        der2: 0.95,
        hrt1: 0.81,
        hrt2: 0.78,
        mcl1: 0.92,
        mcl2: 0.94,
        bl1: 1.0,
        bl2: 0.94,
        kid1: 0.92,
        kid2: 0.81,
        br1: 0.94,
        br2: 1.0,
        lu1: 0.77,
        lu2: 0.86,
        liv1: 0.13,
        liv2: 0.15,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 1.0,
        der1: 0.93,
        der2: 0.94,
        hrt1: 0.78,
        hrt2: 0.78,
        mcl1: 0.92,
        mcl2: 0.89,
        bl1: 0.92,
        bl2: 0.92,
        kid1: 0.84,
        kid2: 0.94,
        br1: 0.95,
        br2: 0.95,
        lu1: 0.83,
        lu2: 0.91,
        liv1: 0.2,
        liv2: 0.18,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Reverse',
        epi1: 1.0,
        epi2: 1.0,
        der1: 1.0,
        der2: 0.95,
        hrt1: 0.85,
        hrt2: 0.77,
        mcl1: 1.0,
        mcl2: 0.89,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 1.0,
        kid2: 1.0,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.82,
        lu2: 1.0,
        liv1: 0.07,
        liv2: 0.12,
    },
    {
        g_analyzer: '3500',
        ot_type: 'Liver',
        direction: 'Reverse',
        epi1: 0.95,
        epi2: 1.0,
        der1: 0.92,
        der2: 1.0,
        hrt1: 0.84,
        hrt2: 0.84,
        mcl1: 0.94,
        mcl2: 0.91,
        bl1: 1.0,
        bl2: 1.0,
        kid1: 0.91,
        kid2: 0.95,
        br1: 1.0,
        br2: 1.0,
        lu1: 0.83,
        lu2: 0.92,
        liv1: 0.09,
        liv2: 0.08,
    },
];

export const DATA_MPS_SEMEN = [
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 25,
        marker2: 0.8097345133,
        marker3: 0.8075221239,
        cg15810474: 0.71875,
        cg13372258: 0.02580178442,
        cg14446584: 0.6574733096,
        cg00061679: 0.8750694059,
        marker1: 0.9501096491,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 19,
        marker2: 0.9044117647,
        marker3: 0.9099264706,
        cg15810474: 0.8834080717,
        cg13372258: 0.01056930098,
        cg14446584: 0.7583364556,
        cg00061679: 0.9201108289,
        marker1: 0.96277803,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 19,
        marker2: 0.9076212471,
        marker3: 0.9330254042,
        cg15810474: 0.8773584906,
        cg13372258: 0.00644028103,
        cg14446584: 0.759051186,
        cg00061679: 0.9002803364,
        marker1: 0.9717670286,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 19,
        marker2: 0.8565965583,
        marker3: 0.7571701721,
        cg15810474: 0.7993754879,
        cg13372258: 0.007518796992,
        cg14446584: 0.6722792608,
        cg00061679: 0.8514923211,
        marker1: 0.9504810413,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 23,
        marker2: 0.8896551724,
        marker3: 0.8657487091,
        cg15810474: 0.790237467,
        cg13372258: 0.03587051619,
        cg14446584: 0.719002201,
        cg00061679: 0.9005758808,
        marker1: 0.9658958576,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 23,
        marker2: 0.9146919431,
        marker3: 0.8747044917,
        cg15810474: 0.808411215,
        cg13372258: 0.01215733015,
        cg14446584: 0.7199656136,
        cg00061679: 0.9067603887,
        marker1: 0.9575116894,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 24,
        marker2: 0.937007874,
        marker3: 0.9278215223,
        cg15810474: 0.8665458937,
        cg13372258: 0.02982785069,
        cg14446584: 0.6871058634,
        cg00061679: 0.8825838103,
        marker1: 0.9550633927,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 26,
        marker2: 0.8099630996,
        marker3: 0.7245841035,
        cg15810474: 0.7725437416,
        cg13372258: 0.0293040293,
        cg14446584: 0.6301724138,
        cg00061679: 0.8242473556,
        marker1: 0.9524661893,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 19,
        marker2: 0.856097561,
        marker3: 0.8804878049,
        cg15810474: 0.8114254021,
        cg13372258: 0.009113693324,
        cg14446584: 0.6693895099,
        cg00061679: 0.8246892413,
        marker1: 0.9698618669,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 24,
        marker2: 0.9087719298,
        marker3: 0.9315789474,
        cg15810474: 0.8327091136,
        cg13372258: 0.01908302354,
        cg14446584: 0.732017823,
        cg00061679: 0.8735976066,
        marker1: 0.9485142689,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 23,
        marker2: 0.9582689335,
        marker3: 0.8564814815,
        cg15810474: 0.788774003,
        cg13372258: 0.2509253848,
        cg14446584: 0.4808502489,
        cg00061679: 0.7782165488,
        marker1: 0.8023839398,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 23,
        marker2: 0.9316037736,
        marker3: 0.9056603774,
        cg15810474: 0.8426229508,
        cg13372258: 0.003792298716,
        cg14446584: 0.7326732673,
        cg00061679: 0.8824860724,
        marker1: 0.9628120224,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 28,
        marker2: 0.862962963,
        marker3: 0.8460111317,
        cg15810474: 0.7909738717,
        cg13372258: 0.02002529511,
        cg14446584: 0.7592361976,
        cg00061679: 0.9107470696,
        marker1: 0.9648199446,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 29,
        marker2: 0.869379015,
        marker3: 0.8436830835,
        cg15810474: 0.7033639144,
        cg13372258: 0.02516021837,
        cg14446584: 0.6996524602,
        cg00061679: 0.8957521034,
        marker1: 0.9516908213,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 22,
        marker2: 0.8870673953,
        marker3: 0.8724954463,
        cg15810474: 0.824635637,
        cg13372258: 0.01072041166,
        cg14446584: 0.704984205,
        cg00061679: 0.9069641893,
        marker1: 0.9640134728,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 25,
        marker2: 0.9205834684,
        marker3: 0.8980582524,
        cg15810474: 0.7999188312,
        cg13372258: 0.02321565618,
        cg14446584: 0.7731397459,
        cg00061679: 0.9199356266,
        marker1: 0.9554190608,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 23,
        marker2: 0.8303886926,
        marker3: 0.7915194346,
        cg15810474: 0.7316647264,
        cg13372258: 0.01112816577,
        cg14446584: 0.6617041495,
        cg00061679: 0.8648874062,
        marker1: 0.9589601046,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 18,
        marker2: 0.8837209302,
        marker3: 0.8924418605,
        cg15810474: 0.835125448,
        cg13372258: 0.03529411765,
        cg14446584: 0.6601475382,
        cg00061679: 0.8231099735,
        marker1: 0.9505533738,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 25,
        marker2: 0.8955916473,
        marker3: 0.8515081206,
        cg15810474: 0.7719626168,
        cg13372258: 0.01631404537,
        cg14446584: 0.5781418409,
        cg00061679: 0.8031358885,
        marker1: 0.9472122112,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 22,
        marker2: 0.8360655738,
        marker3: 0.829228243,
        cg15810474: 0.8248847926,
        cg13372258: 0.02177554439,
        cg14446584: 0.6881245196,
        cg00061679: 0.8810336421,
        marker1: 0.9635646876,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 22,
        marker2: 0.9638554217,
        marker3: 0.8027108434,
        cg15810474: 0.8179287305,
        cg13372258: 0.1687625296,
        cg14446584: 0.2344877345,
        cg00061679: 0.6745365512,
        marker1: 0.7340136054,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 21,
        marker2: 0.8740740741,
        marker3: 0.862962963,
        cg15810474: 0.8351187704,
        cg13372258: 0.0131147541,
        cg14446584: 0.7152194211,
        cg00061679: 0.9099729601,
        marker1: 0.9610876699,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 23,
        marker2: 0.8897338403,
        marker3: 0.9125475285,
        cg15810474: 0.8194650817,
        cg13372258: 0.007781124498,
        cg14446584: 0.7070504239,
        cg00061679: 0.8910944935,
        marker1: 0.9575854275,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 29,
        marker2: 0.8108108108,
        marker3: 0.7407407407,
        cg15810474: 0.6248715313,
        cg13372258: 0.01728395062,
        cg14446584: 0.6881481481,
        cg00061679: 0.853968254,
        marker1: 0.9482071713,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 22,
        marker2: 0.8386243386,
        marker3: 0.8333333333,
        cg15810474: 0.7971014493,
        cg13372258: 0.006399212405,
        cg14446584: 0.6836472603,
        cg00061679: 0.8592454153,
        marker1: 0.9561734752,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 24,
        marker2: 0.6997792494,
        marker3: 0.6607929515,
        cg15810474: 0.7335038363,
        cg13372258: 0.01769911504,
        cg14446584: 0.6852329039,
        cg00061679: 0.8846494247,
        marker1: 0.9519722249,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 24,
        marker2: 0.8692307692,
        marker3: 0.8307692308,
        cg15810474: 0.8101879927,
        cg13372258: 0.01179941003,
        cg14446584: 0.6827202323,
        cg00061679: 0.8572599148,
        marker1: 0.9579980689,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 26,
        marker2: 0.8460076046,
        marker3: 0.7813688213,
        cg15810474: 0.7485351563,
        cg13372258: 0.03029617906,
        cg14446584: 0.6419561421,
        cg00061679: 0.8692818495,
        marker1: 0.9556946785,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 24,
        marker2: 0.8947368421,
        marker3: 0.8566243194,
        cg15810474: 0.7960725076,
        cg13372258: 0.01791691278,
        cg14446584: 0.7424593968,
        cg00061679: 0.8966867049,
        marker1: 0.960417812,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 22,
        marker2: 0.8230452675,
        marker3: 0.8065843621,
        cg15810474: 0.8018867925,
        cg13372258: 0.02460202605,
        cg14446584: 0.6545703526,
        cg00061679: 0.8652941176,
        marker1: 0.9532467532,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 21,
        marker2: 0.8794167134,
        marker3: 0.8637128435,
        cg15810474: 0.8261886204,
        cg13372258: 0.03462050599,
        cg14446584: 0.7022288262,
        cg00061679: 0.8717587704,
        marker1: 0.9458899378,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 24,
        marker2: 0.8607068607,
        marker3: 0.8274428274,
        cg15810474: 0.7370423205,
        cg13372258: 0.03919670941,
        cg14446584: 0.7486832205,
        cg00061679: 0.9149574787,
        marker1: 0.9597679872,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 27,
        marker2: 0.8978224456,
        marker3: 0.8760469012,
        cg15810474: 0.8260619977,
        cg13372258: 0.01534344335,
        cg14446584: 0.6681959449,
        cg00061679: 0.8372517033,
        marker1: 0.9606699285,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 29,
        marker2: 0.9195205479,
        marker3: 0.8578767123,
        cg15810474: 0.7938570585,
        cg13372258: 0.05547254389,
        cg14446584: 0.5916219972,
        cg00061679: 0.8708457711,
        marker1: 0.8747197036,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 27,
        marker2: 0.8556701031,
        marker3: 0.8474226804,
        cg15810474: 0.7635169277,
        cg13372258: 0.01343813387,
        cg14446584: 0.7046941131,
        cg00061679: 0.9287790698,
        marker1: 0.9624749355,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 23,
        marker2: 0.9150485437,
        marker3: 0.8762135922,
        cg15810474: 0.8346456693,
        cg13372258: 0.1135371179,
        cg14446584: 0.3875127248,
        cg00061679: 0.7817406143,
        marker1: 0.8293333333,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 25,
        marker2: 0.8844444444,
        marker3: 0.8711111111,
        cg15810474: 0.7772925764,
        cg13372258: 0.01703632273,
        cg14446584: 0.7314189189,
        cg00061679: 0.9332347348,
        marker1: 0.9599415815,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 22,
        marker2: 0.8910369069,
        marker3: 0.927943761,
        cg15810474: 0.8429693076,
        cg13372258: 0.004649432769,
        cg14446584: 0.6812755025,
        cg00061679: 0.8509760355,
        marker1: 0.9507304942,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 27,
        marker2: 0.9128014842,
        marker3: 0.8791821561,
        cg15810474: 0.7912713472,
        cg13372258: 0.01563820795,
        cg14446584: 0.6884451338,
        cg00061679: 0.9035610041,
        marker1: 0.949477552,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 27,
        marker2: 0.8333333333,
        marker3: 0.748015873,
        cg15810474: 0.7504078303,
        cg13372258: 0.09171075838,
        cg14446584: 0.6311962987,
        cg00061679: 0.8669372373,
        marker1: 0.9414018161,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 19,
        marker2: 0.9069767442,
        marker3: 0.8908765653,
        cg15810474: 0.8465256798,
        cg13372258: 0.005471656818,
        cg14446584: 0.7507810281,
        cg00061679: 0.8956404494,
        marker1: 0.9536464771,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 22,
        marker2: 0.8186046512,
        marker3: 0.8186046512,
        cg15810474: 0.7958801498,
        cg13372258: 0.009049773756,
        cg14446584: 0.6836386877,
        cg00061679: 0.8642028986,
        marker1: 0.959375,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 24,
        marker2: 0.8739669421,
        marker3: 0.817805383,
        cg15810474: 0.727933541,
        cg13372258: 0.013006886,
        cg14446584: 0.7042925278,
        cg00061679: 0.8977660394,
        marker1: 0.9520873891,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 25,
        marker2: 0.8871391076,
        marker3: 0.7952755906,
        cg15810474: 0.7768281102,
        cg13372258: 0.03908431044,
        cg14446584: 0.7351458014,
        cg00061679: 0.8723002726,
        marker1: 0.9503808936,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 25,
        marker2: 0.8211764706,
        marker3: 0.8023529412,
        cg15810474: 0.7461204221,
        cg13372258: 0.02488950919,
        cg14446584: 0.6457929847,
        cg00061679: 0.8470171294,
        marker1: 0.9588065448,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 24,
        marker2: 0.8857545839,
        marker3: 0.9097744361,
        cg15810474: 0.8593421973,
        cg13372258: 0.002525252525,
        cg14446584: 0.7060194175,
        cg00061679: 0.8911653589,
        marker1: 0.9660048564,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 26,
        marker2: 0.8677354709,
        marker3: 0.8714859438,
        cg15810474: 0.7835743802,
        cg13372258: 0.006879299562,
        cg14446584: 0.7454387489,
        cg00061679: 0.9329665852,
        marker1: 0.9620240481,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 27,
        marker2: 0.9076212471,
        marker3: 0.8868360277,
        cg15810474: 0.8165137615,
        cg13372258: 0.008020624463,
        cg14446584: 0.810868407,
        cg00061679: 0.9264604811,
        marker1: 0.9590939824,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 39,
        marker2: 0.8540772532,
        marker3: 0.8240343348,
        cg15810474: 0.6441223833,
        cg13372258: 0.01528101528,
        cg14446584: 0.8310701956,
        cg00061679: 0.9429479419,
        marker1: 0.9594978329,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 35,
        marker2: 0.8584474886,
        marker3: 0.801369863,
        cg15810474: 0.7000650618,
        cg13372258: 0.01016776817,
        cg14446584: 0.7275609756,
        cg00061679: 0.9334383616,
        marker1: 0.9514657348,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 33,
        marker2: 0.8121442125,
        marker3: 0.7855787476,
        cg15810474: 0.7742402315,
        cg13372258: 0.02067736185,
        cg14446584: 0.7433875472,
        cg00061679: 0.9351761252,
        marker1: 0.9607890306,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 34,
        marker2: 0.8786231884,
        marker3: 0.8550724638,
        cg15810474: 0.7588132635,
        cg13372258: 0.008634772462,
        cg14446584: 0.6971358927,
        cg00061679: 0.8950662739,
        marker1: 0.9670276775,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 39,
        marker2: 0.8123827392,
        marker3: 0.8161350844,
        cg15810474: 0.6081424936,
        cg13372258: 0.02304230423,
        cg14446584: 0.7317154255,
        cg00061679: 0.8948619285,
        marker1: 0.955434541,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 36,
        marker2: 0.8459119497,
        marker3: 0.7610062893,
        cg15810474: 0.7174311927,
        cg13372258: 0.01205479452,
        cg14446584: 0.6953094963,
        cg00061679: 0.9139066339,
        marker1: 0.9565722023,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 32,
        marker2: 0.7598343685,
        marker3: 0.77593361,
        cg15810474: 0.7300426569,
        cg13372258: 0.00267073636,
        cg14446584: 0.715833835,
        cg00061679: 0.9013144987,
        marker1: 0.9664804469,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 30,
        marker2: 0.8449781659,
        marker3: 0.8318777293,
        cg15810474: 0.7368035191,
        cg13372258: 0.0107091861,
        cg14446584: 0.7225711893,
        cg00061679: 0.9044577142,
        marker1: 0.9593062952,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 34,
        marker2: 0.916988417,
        marker3: 0.8803088803,
        cg15810474: 0.8367609254,
        cg13372258: 0.005130309871,
        cg14446584: 0.796437659,
        cg00061679: 0.9321808511,
        marker1: 0.9616181442,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 38,
        marker2: 0.8464419476,
        marker3: 0.8520599251,
        cg15810474: 0.6928057554,
        cg13372258: 0.08750835003,
        cg14446584: 0.5805515239,
        cg00061679: 0.8333333333,
        marker1: 0.9116179615,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8375634518,
        marker3: 0.8375634518,
        cg15810474: 0.7493946731,
        cg13372258: 0.01369426752,
        cg14446584: 0.7101522843,
        cg00061679: 0.9089746323,
        marker1: 0.9574155653,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 30,
        marker2: 0.7704626335,
        marker3: 0.7597864769,
        cg15810474: 0.6289222374,
        cg13372258: 0.01226070123,
        cg14446584: 0.7218973359,
        cg00061679: 0.9009061678,
        marker1: 0.9600574713,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 30,
        marker2: 0.9094412331,
        marker3: 0.8921001927,
        cg15810474: 0.8340306834,
        cg13372258: 0.03425774878,
        cg14446584: 0.7795910711,
        cg00061679: 0.9479475243,
        marker1: 0.9581944444,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 30,
        marker2: 0.9041916168,
        marker3: 0.878742515,
        cg15810474: 0.8474576271,
        cg13372258: 0.0250423649,
        cg14446584: 0.6571163079,
        cg00061679: 0.8845987734,
        marker1: 0.9359626802,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 30,
        marker2: 0.9024390244,
        marker3: 0.8797909408,
        cg15810474: 0.8214032601,
        cg13372258: 0.02451095923,
        cg14446584: 0.7582814696,
        cg00061679: 0.9378705852,
        marker1: 0.9525380711,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 36,
        marker2: 0.8454545455,
        marker3: 0.716553288,
        cg15810474: 0.6765175719,
        cg13372258: 0.0118251928,
        cg14446584: 0.7683741648,
        cg00061679: 0.9472387303,
        marker1: 0.9516221648,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8548662493,
        marker3: 0.8810472396,
        cg15810474: 0.7872750643,
        cg13372258: 0.02316602317,
        cg14446584: 0.761652141,
        cg00061679: 0.9225334957,
        marker1: 0.9611290515,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 30,
        marker2: 0.820754717,
        marker3: 0.8443396226,
        cg15810474: 0.7762645914,
        cg13372258: 0.01460071514,
        cg14446584: 0.7240560437,
        cg00061679: 0.9203936714,
        marker1: 0.9516583038,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8581395349,
        marker3: 0.8558139535,
        cg15810474: 0.7672151127,
        cg13372258: 0.007377805103,
        cg14446584: 0.7442557443,
        cg00061679: 0.9270808347,
        marker1: 0.9559066258,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8865784499,
        marker3: 0.8903591682,
        cg15810474: 0.8044515103,
        cg13372258: 0.02068793619,
        cg14446584: 0.6983908046,
        cg00061679: 0.8732065997,
        marker1: 0.9611650485,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 37,
        marker2: 0.937751004,
        marker3: 0.9378757515,
        cg15810474: 0.8731808732,
        cg13372258: 0.08893847625,
        cg14446584: 0.6237224314,
        cg00061679: 0.8663308144,
        marker1: 0.8839278359,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 35,
        marker2: 0.8918367347,
        marker3: 0.787755102,
        cg15810474: 0.7682458387,
        cg13372258: 0.06401766004,
        cg14446584: 0.671133445,
        cg00061679: 0.9038485184,
        marker1: 0.9193275278,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8900203666,
        marker3: 0.8085539715,
        cg15810474: 0.7831491713,
        cg13372258: 0.0308411215,
        cg14446584: 0.6515363128,
        cg00061679: 0.9034400319,
        marker1: 0.937663356,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 32,
        marker2: 0.9060240964,
        marker3: 0.9012048193,
        cg15810474: 0.7963761864,
        cg13372258: 0.01177285319,
        cg14446584: 0.7430227565,
        cg00061679: 0.9304783092,
        marker1: 0.9600219058,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8518518519,
        marker3: 0.7977207977,
        cg15810474: 0.7254264825,
        cg13372258: 0.08993522671,
        cg14446584: 0.6110652353,
        cg00061679: 0.8637522769,
        marker1: 0.8710253662,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8961937716,
        marker3: 0.875432526,
        cg15810474: 0.7827380952,
        cg13372258: 0.01700367647,
        cg14446584: 0.6877022654,
        cg00061679: 0.8889199776,
        marker1: 0.9475862069,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 34,
        marker2: 0.8534653465,
        marker3: 0.8594059406,
        cg15810474: 0.7628705148,
        cg13372258: 0.01518026565,
        cg14446584: 0.7428042763,
        cg00061679: 0.904088586,
        marker1: 0.9530673818,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 34,
        marker2: 0.8804597701,
        marker3: 0.9013761468,
        cg15810474: 0.7582145071,
        cg13372258: 0.03481254782,
        cg14446584: 0.6497064579,
        cg00061679: 0.8758899365,
        marker1: 0.9487906274,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8783783784,
        marker3: 0.8706563707,
        cg15810474: 0.7603031739,
        cg13372258: 0.006014702606,
        cg14446584: 0.7294811321,
        cg00061679: 0.8906830523,
        marker1: 0.9491692481,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 37,
        marker2: 0.8605577689,
        marker3: 0.8031809145,
        cg15810474: 0.8151079137,
        cg13372258: 0.009986504723,
        cg14446584: 0.763024785,
        cg00061679: 0.949106113,
        marker1: 0.9652767895,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 33,
        marker2: 0.8763326226,
        marker3: 0.8208955224,
        cg15810474: 0.6864864865,
        cg13372258: 0.01432748538,
        cg14446584: 0.7833778371,
        cg00061679: 0.9204434301,
        marker1: 0.961822265,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 32,
        marker2: 0.9119638826,
        marker3: 0.8558558559,
        cg15810474: 0.7728375101,
        cg13372258: 0.00877763329,
        cg14446584: 0.7485184231,
        cg00061679: 0.7943104069,
        marker1: 0.9034841248,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 32,
        marker2: 0.8857142857,
        marker3: 0.8583850932,
        cg15810474: 0.8681715576,
        cg13372258: 0.06943914536,
        cg14446584: 0.7092633929,
        cg00061679: 0.9196413466,
        marker1: 0.9243027888,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 33,
        marker2: 0.8587896254,
        marker3: 0.8069164265,
        cg15810474: 0.7972027972,
        cg13372258: 0.01309635173,
        cg14446584: 0.7762532982,
        cg00061679: 0.9460257787,
        marker1: 0.9474940334,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 38,
        marker2: 0.8909512761,
        marker3: 0.879350348,
        cg15810474: 0.7883538634,
        cg13372258: 0.004419346919,
        cg14446584: 0.7892433911,
        cg00061679: 0.941662227,
        marker1: 0.9654631083,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 35,
        marker2: 0.8492366412,
        marker3: 0.82330468,
        cg15810474: 0.7356656948,
        cg13372258: 0.01744004983,
        cg14446584: 0.8130134025,
        cg00061679: 0.9408958389,
        marker1: 0.9565217391,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 34,
        marker2: 0.8567708333,
        marker3: 0.8909090909,
        cg15810474: 0.7751677852,
        cg13372258: 0.04195630586,
        cg14446584: 0.6431283219,
        cg00061679: 0.8930041152,
        marker1: 0.9389752447,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 30,
        marker2: 0.9100968188,
        marker3: 0.8838174274,
        cg15810474: 0.8180186648,
        cg13372258: 0.04134594238,
        cg14446584: 0.7229199372,
        cg00061679: 0.8923459863,
        marker1: 0.9466828971,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8584371461,
        marker3: 0.8629671574,
        cg15810474: 0.7701492537,
        cg13372258: 0.004207119741,
        cg14446584: 0.6865490777,
        cg00061679: 0.8662164335,
        marker1: 0.9583793158,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 33,
        marker2: 0.8995363215,
        marker3: 0.8346213292,
        cg15810474: 0.8156664069,
        cg13372258: 0.005670184281,
        cg14446584: 0.8348982786,
        cg00061679: 0.953443259,
        marker1: 0.962380301,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 36,
        marker2: 0.8721461187,
        marker3: 0.8797564688,
        cg15810474: 0.7932031815,
        cg13372258: 0.00866807611,
        cg14446584: 0.7976744186,
        cg00061679: 0.9420564686,
        marker1: 0.9695904013,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 33,
        marker2: 0.9404388715,
        marker3: 0.8808777429,
        cg15810474: 0.8,
        cg13372258: 0.003825554705,
        cg14446584: 0.7632221318,
        cg00061679: 0.917218543,
        marker1: 0.9636864513,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 39,
        marker2: 0.8986666667,
        marker3: 0.8266666667,
        cg15810474: 0.72,
        cg13372258: 0.00543618949,
        cg14446584: 0.7494650846,
        cg00061679: 0.9347281568,
        marker1: 0.9603845033,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 30,
        marker2: 0.8795811518,
        marker3: 0.8534031414,
        cg15810474: 0.7608261159,
        cg13372258: 0.007389812615,
        cg14446584: 0.7659866614,
        cg00061679: 0.9363160016,
        marker1: 0.969973545,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 36,
        marker2: 0.8810289389,
        marker3: 0.8774193548,
        cg15810474: 0.8443579767,
        cg13372258: 0.01131289074,
        cg14446584: 0.8188261351,
        cg00061679: 0.9352685051,
        marker1: 0.9647947548,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 32,
        marker2: 0.8546712803,
        marker3: 0.7889273356,
        cg15810474: 0.6882494005,
        cg13372258: 0.01454016721,
        cg14446584: 0.6869830213,
        cg00061679: 0.856737898,
        marker1: 0.9560509554,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 30,
        marker2: 0.7636761488,
        marker3: 0.7221006565,
        cg15810474: 0.6891996892,
        cg13372258: 0.02677711424,
        cg14446584: 0.6612239103,
        cg00061679: 0.8750357859,
        marker1: 0.9418294225,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 35,
        marker2: 0.8308702791,
        marker3: 0.8505747126,
        cg15810474: 0.7497225305,
        cg13372258: 0.007147838215,
        cg14446584: 0.7242813918,
        cg00061679: 0.9194426301,
        marker1: 0.9634016219,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8660436137,
        marker3: 0.7881619938,
        cg15810474: 0.7949383517,
        cg13372258: 0.01962361267,
        cg14446584: 0.7165627166,
        cg00061679: 0.9131832797,
        marker1: 0.9540031623,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 34,
        marker2: 0.8850102669,
        marker3: 0.816872428,
        cg15810474: 0.7700729927,
        cg13372258: 0.01462128476,
        cg14446584: 0.7710290127,
        cg00061679: 0.9464015152,
        marker1: 0.9571614826,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 47,
        marker2: 0.8282504013,
        marker3: 0.772070626,
        cg15810474: 0.6672171759,
        cg13372258: 0.01752308785,
        cg14446584: 0.6716630978,
        cg00061679: 0.8997011823,
        marker1: 0.9301672412,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 47,
        marker2: 0.8403508772,
        marker3: 0.8056042032,
        cg15810474: 0.7577413479,
        cg13372258: 0.001982946659,
        cg14446584: 0.7474384325,
        cg00061679: 0.9224217311,
        marker1: 0.9538095777,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 42,
        marker2: 0.849137931,
        marker3: 0.8261494253,
        cg15810474: 0.765625,
        cg13372258: 0.007151140317,
        cg14446584: 0.7944776119,
        cg00061679: 0.9286145791,
        marker1: 0.9563020214,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 40,
        marker2: 0.9115479115,
        marker3: 0.9090909091,
        cg15810474: 0.8537761114,
        cg13372258: 0.002464268112,
        cg14446584: 0.8039045553,
        cg00061679: 0.9395589436,
        marker1: 0.9561897106,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 44,
        marker2: 0.8707964602,
        marker3: 0.8283185841,
        cg15810474: 0.7491856678,
        cg13372258: 0.005708245243,
        cg14446584: 0.7938609252,
        cg00061679: 0.9640619459,
        marker1: 0.9552359968,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 40,
        marker2: 0.8971119134,
        marker3: 0.8267148014,
        cg15810474: 0.7841127482,
        cg13372258: 0.03785569106,
        cg14446584: 0.7141985354,
        cg00061679: 0.9223549488,
        marker1: 0.9205576083,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 44,
        marker2: 0.8737446198,
        marker3: 0.8520114943,
        cg15810474: 0.8245787909,
        cg13372258: 0.007198560288,
        cg14446584: 0.8020108275,
        cg00061679: 0.9562215164,
        marker1: 0.9496255956,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 45,
        marker2: 0.7842465753,
        marker3: 0.7654109589,
        cg15810474: 0.700265252,
        cg13372258: 0.01412952061,
        cg14446584: 0.7538826007,
        cg00061679: 0.9252867928,
        marker1: 0.9524865073,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 48,
        marker2: 0.8471001757,
        marker3: 0.7315789474,
        cg15810474: 0.5995096036,
        cg13372258: 0.01578192253,
        cg14446584: 0.7491297862,
        cg00061679: 0.9364708084,
        marker1: 0.9596640207,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 45,
        marker2: 0.8331273177,
        marker3: 0.803461063,
        cg15810474: 0.7399710005,
        cg13372258: 0.02251844183,
        cg14446584: 0.8079931426,
        cg00061679: 0.955372966,
        marker1: 0.9487301587,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 49,
        marker2: 0.8414634146,
        marker3: 0.7845528455,
        cg15810474: 0.6817785527,
        cg13372258: 0.015625,
        cg14446584: 0.803761533,
        cg00061679: 0.9426873682,
        marker1: 0.9519924099,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 46,
        marker2: 0.880866426,
        marker3: 0.7833935018,
        cg15810474: 0.7084168337,
        cg13372258: 0.03260363298,
        cg14446584: 0.7635977713,
        cg00061679: 0.9193695059,
        marker1: 0.9513795675,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 49,
        marker2: 0.8962264151,
        marker3: 0.9090909091,
        cg15810474: 0.8438287154,
        cg13372258: 0.0191106211,
        cg14446584: 0.7626335547,
        cg00061679: 0.9538875368,
        marker1: 0.9607570978,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 40,
        marker2: 0.9180327869,
        marker3: 0.893442623,
        cg15810474: 0.8611369991,
        cg13372258: 0.0188172043,
        cg14446584: 0.7400556881,
        cg00061679: 0.937446736,
        marker1: 0.9315251967,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 47,
        marker2: 0.8787128713,
        marker3: 0.8044554455,
        cg15810474: 0.8400292184,
        cg13372258: 0.001328727079,
        cg14446584: 0.8598041557,
        cg00061679: 0.9529477197,
        marker1: 0.9566790963,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 47,
        marker2: 0.7439393939,
        marker3: 0.6560606061,
        cg15810474: 0.6971925134,
        cg13372258: 0.01384535005,
        cg14446584: 0.746161078,
        cg00061679: 0.9452302137,
        marker1: 0.9513064133,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 41,
        marker2: 0.8069767442,
        marker3: 0.788863109,
        cg15810474: 0.7151067323,
        cg13372258: 0.006588579795,
        cg14446584: 0.7487948581,
        cg00061679: 0.9275892857,
        marker1: 0.9581211589,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 44,
        marker2: 0.8452830189,
        marker3: 0.8264150943,
        cg15810474: 0.7737478411,
        cg13372258: 0.005528255528,
        cg14446584: 0.8208373905,
        cg00061679: 0.9316437803,
        marker1: 0.9561630219,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 40,
        marker2: 0.8544395924,
        marker3: 0.866084425,
        cg15810474: 0.8090957166,
        cg13372258: 0.005286701911,
        cg14446584: 0.7488014271,
        cg00061679: 0.9335012018,
        marker1: 0.9583427156,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 44,
        marker2: 0.8472400513,
        marker3: 0.8613607189,
        cg15810474: 0.7534612582,
        cg13372258: 0.1772935163,
        cg14446584: 0.5620915033,
        cg00061679: 0.8494051911,
        marker1: 0.8767593244,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 45,
        marker2: 0.8341307815,
        marker3: 0.7783094099,
        cg15810474: 0.6512455516,
        cg13372258: 0.01724829523,
        cg14446584: 0.7282627789,
        cg00061679: 0.9343952802,
        marker1: 0.959226225,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 47,
        marker2: 0.8286558346,
        marker3: 0.7562776957,
        cg15810474: 0.6117647059,
        cg13372258: 0.01029098652,
        cg14446584: 0.8139340344,
        cg00061679: 0.9410993334,
        marker1: 0.9572428648,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 44,
        marker2: 0.852205006,
        marker3: 0.758045292,
        cg15810474: 0.7033820841,
        cg13372258: 0.0140472879,
        cg14446584: 0.784295846,
        cg00061679: 0.9333135744,
        marker1: 0.9536766851,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 50,
        marker2: 0.8789237668,
        marker3: 0.8505231689,
        cg15810474: 0.7250457038,
        cg13372258: 0.009744214373,
        cg14446584: 0.7807975616,
        cg00061679: 0.9324042577,
        marker1: 0.9554332578,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 46,
        marker2: 0.8389937107,
        marker3: 0.8289308176,
        cg15810474: 0.7494727963,
        cg13372258: 0.01063560395,
        cg14446584: 0.8054256314,
        cg00061679: 0.9460625674,
        marker1: 0.9677333805,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 43,
        marker2: 0.788,
        marker3: 0.712,
        cg15810474: 0.7612137203,
        cg13372258: 0.04315253221,
        cg14446584: 0.8231469441,
        cg00061679: 0.954012052,
        marker1: 0.9608702457,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 40,
        marker2: 0.9323308271,
        marker3: 0.8943396226,
        cg15810474: 0.8387379492,
        cg13372258: 0.01126760563,
        cg14446584: 0.8123756906,
        cg00061679: 0.9321260306,
        marker1: 0.9674667438,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 48,
        marker2: 0.8288590604,
        marker3: 0.7348993289,
        cg15810474: 0.7151898734,
        cg13372258: 0.006478658537,
        cg14446584: 0.7718916304,
        cg00061679: 0.9319425951,
        marker1: 0.9552958364,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 42,
        marker2: 0.7910447761,
        marker3: 0.5607675906,
        cg15810474: 0.5873721654,
        cg13372258: 0.01770634704,
        cg14446584: 0.715012205,
        cg00061679: 0.9160347904,
        marker1: 0.9586305278,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 40,
        marker2: 0.836400818,
        marker3: 0.7775510204,
        cg15810474: 0.7205479452,
        cg13372258: 0.01827563965,
        cg14446584: 0.6389308366,
        cg00061679: 0.8753778972,
        marker1: 0.9505983094,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 50,
        marker2: 0.8069883527,
        marker3: 0.7562189055,
        cg15810474: 0.6732026144,
        cg13372258: 0.04182921543,
        cg14446584: 0.8104312939,
        cg00061679: 0.8577777778,
        marker1: 0.9319056383,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 54,
        marker2: 0.868286445,
        marker3: 0.8567774936,
        cg15810474: 0.8396191871,
        cg13372258: 0.002208201893,
        cg14446584: 0.8328598485,
        cg00061679: 0.9409043296,
        marker1: 0.9546744646,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 52,
        marker2: 0.8585657371,
        marker3: 0.7928286853,
        cg15810474: 0.7255936675,
        cg13372258: 0.009323503903,
        cg14446584: 0.7669021191,
        cg00061679: 0.9404121864,
        marker1: 0.9635025754,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 52,
        marker2: 0.879472693,
        marker3: 0.7288135593,
        cg15810474: 0.6913417052,
        cg13372258: 0.003947979563,
        cg14446584: 0.8443188952,
        cg00061679: 0.961858465,
        marker1: 0.9535930895,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 52,
        marker2: 0.8764044944,
        marker3: 0.8507223114,
        cg15810474: 0.5630461923,
        cg13372258: 0.005372877713,
        cg14446584: 0.7518503002,
        cg00061679: 0.9403183024,
        marker1: 0.958306118,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 57,
        marker2: 0.8231780167,
        marker3: 0.7383512545,
        cg15810474: 0.7943376068,
        cg13372258: 0.007516339869,
        cg14446584: 0.7416922647,
        cg00061679: 0.9420751114,
        marker1: 0.945861204,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 50,
        marker2: 0.812716763,
        marker3: 0.7653179191,
        cg15810474: 0.7164647824,
        cg13372258: 0.02387411829,
        cg14446584: 0.7770193402,
        cg00061679: 0.944481101,
        marker1: 0.9539247562,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 52,
        marker2: 0.8184818482,
        marker3: 0.8250825083,
        cg15810474: 0.7322175732,
        cg13372258: 0.01777059774,
        cg14446584: 0.8287417219,
        cg00061679: 0.9573602729,
        marker1: 0.9589590886,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 53,
        marker2: 0.8520114943,
        marker3: 0.7729885057,
        cg15810474: 0.7259649123,
        cg13372258: 0.002188183807,
        cg14446584: 0.8393415638,
        cg00061679: 0.9528633983,
        marker1: 0.9454714274,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 55,
        marker2: 0.8832565284,
        marker3: 0.8282208589,
        cg15810474: 0.8025876461,
        cg13372258: 0.01349527665,
        cg14446584: 0.8582748064,
        cg00061679: 0.9627920675,
        marker1: 0.9621307506,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 52,
        marker2: 0.7619961612,
        marker3: 0.6737044146,
        cg15810474: 0.6729348556,
        cg13372258: 0.03547297297,
        cg14446584: 0.7329056486,
        cg00061679: 0.9338436929,
        marker1: 0.9379993249,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 50,
        marker2: 0.9060283688,
        marker3: 0.8756660746,
        cg15810474: 0.7270114943,
        cg13372258: 0.008507742045,
        cg14446584: 0.8596321394,
        cg00061679: 0.9618797902,
        marker1: 0.9507413673,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 55,
        marker2: 0.7818991098,
        marker3: 0.7225519288,
        cg15810474: 0.6541837332,
        cg13372258: 0.05588543486,
        cg14446584: 0.7066377843,
        cg00061679: 0.9065170259,
        marker1: 0.9322435737,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 63,
        marker2: 0.8453038674,
        marker3: 0.7423822715,
        cg15810474: 0.5916030534,
        cg13372258: 0.01446099912,
        cg14446584: 0.8091187479,
        cg00061679: 0.9783599089,
        marker1: 0.958848662,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 62,
        marker2: 0.7838541667,
        marker3: 0.7473958333,
        cg15810474: 0.561302682,
        cg13372258: 0.004968944099,
        cg14446584: 0.8300934579,
        cg00061679: 0.9625839463,
        marker1: 0.9590034689,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 60,
        marker2: 0.8193832599,
        marker3: 0.7863436123,
        cg15810474: 0.7201986755,
        cg13372258: 0.0537250787,
        cg14446584: 0.8407978241,
        cg00061679: 0.9600107354,
        marker1: 0.9677504626,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 70,
        marker2: 0.8612440191,
        marker3: 0.8277511962,
        cg15810474: 0.6417582418,
        cg13372258: 0.02704121817,
        cg14446584: 0.8513349515,
        cg00061679: 0.9482403686,
        marker1: 0.957496136,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 60,
        marker2: 0.8425925926,
        marker3: 0.7759815242,
        cg15810474: 0.7060288989,
        cg13372258: 0.01445895522,
        cg14446584: 0.8204288754,
        cg00061679: 0.9568173466,
        marker1: 0.9607914322,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 60,
        marker2: 0.8151260504,
        marker3: 0.743697479,
        cg15810474: 0.7112616427,
        cg13372258: 0.01842931937,
        cg14446584: 0.8341855369,
        cg00061679: 0.9563068091,
        marker1: 0.9613747438,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 27,
        marker2: 0.7391304348,
        marker3: 0.7292490119,
        cg15810474: 0.6789317507,
        cg13372258: 0.004859335038,
        cg14446584: 0.633624655,
        cg00061679: 0.8276496155,
        marker1: 0.9503435864,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 28,
        marker2: 0.9341917024,
        marker3: 0.9241773963,
        cg15810474: 0.8630451415,
        cg13372258: 0.006091370558,
        cg14446584: 0.726471625,
        cg00061679: 0.899378633,
        marker1: 0.9648409315,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 22,
        marker2: 0.8300153139,
        marker3: 0.8529862175,
        cg15810474: 0.756954612,
        cg13372258: 0.003469210755,
        cg14446584: 0.7674017258,
        cg00061679: 0.8528997748,
        marker1: 0.9691820871,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 24,
        marker2: 0.8623853211,
        marker3: 0.8532110092,
        cg15810474: 0.7813688213,
        cg13372258: 0.08173952641,
        cg14446584: 0.5642886769,
        cg00061679: 0.8515239478,
        marker1: 0.9004614838,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 23,
        marker2: 0.8805031447,
        marker3: 0.8553459119,
        cg15810474: 0.8363298202,
        cg13372258: 0.02903885481,
        cg14446584: 0.7035903713,
        cg00061679: 0.8976114186,
        marker1: 0.9523673294,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 23,
        marker2: 0.8628719276,
        marker3: 0.8225388601,
        cg15810474: 0.8168035376,
        cg13372258: 0.04968775455,
        cg14446584: 0.6271105826,
        cg00061679: 0.8713337023,
        marker1: 0.9317749388,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 34,
        marker2: 0.808259587,
        marker3: 0.8230088496,
        cg15810474: 0.7574536664,
        cg13372258: 0.01787709497,
        cg14446584: 0.6770280516,
        cg00061679: 0.8997418244,
        marker1: 0.9540363483,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 35,
        marker2: 0.8236151603,
        marker3: 0.7142857143,
        cg15810474: 0.6927413672,
        cg13372258: 0.01241559573,
        cg14446584: 0.7159628526,
        cg00061679: 0.911349149,
        marker1: 0.9487063682,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 31,
        marker2: 0.8027923211,
        marker3: 0.8129370629,
        cg15810474: 0.6585365854,
        cg13372258: 0.008962264151,
        cg14446584: 0.7156193078,
        cg00061679: 0.8767543523,
        marker1: 0.961833785,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        age: 43,
        marker2: 0.8587155963,
        marker3: 0.7926605505,
        cg15810474: 0.766798419,
        cg13372258: 0.02135025967,
        cg14446584: 0.7367006985,
        cg00061679: 0.9167600172,
        marker1: 0.9510044927,
    },
];

export const DATA_MPS_BF = [
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.94,
        se2: 0.9,
        bl1: 0.01,
        bl3: 0.01,
        vf1: 0.05,
        vf2: 0.0,
        sa1: 0.0,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.98,
        se2: 0.92,
        bl1: 0.0,
        bl3: 0.0,
        vf1: 0.01,
        vf2: 0.01,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.96,
        se2: 0.89,
        bl1: 0.08,
        bl3: 0.01,
        vf1: 0.02,
        vf2: 0.01,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.95,
        se2: 0.89,
        bl1: 0.02,
        bl3: 0.0,
        vf1: 0.05,
        vf2: 0.01,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.92,
        se2: 0.88,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.01,
        vf2: 0.01,
        sa1: 0.01,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.97,
        se2: 0.93,
        bl1: 0.0,
        bl3: 0.0,
        vf1: 0.0,
        vf2: 0.01,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.72,
        se2: 0.69,
        bl1: 0.06,
        bl3: 0.02,
        vf1: 0.05,
        vf2: 0.0,
        sa1: 0.01,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.94,
        se2: 0.89,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.02,
        vf2: 0.0,
        sa1: 0.0,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.93,
        se2: 0.87,
        bl1: 0.01,
        bl3: 0.01,
        vf1: 0.0,
        vf2: 0.0,
        sa1: 0.0,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.96,
        se2: 0.91,
        bl1: 0.0,
        bl3: 0.01,
        vf1: 0.0,
        vf2: 0.0,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.93,
        se2: 0.85,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.0,
        vf2: 0.01,
        sa1: 0.0,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.95,
        se2: 0.87,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.02,
        vf2: 0.0,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.95,
        se2: 0.93,
        bl1: 0.01,
        bl3: 0.01,
        vf1: 0.07,
        vf2: 0.01,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.97,
        se2: 0.91,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.0,
        vf2: 0.0,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.95,
        se2: 0.91,
        bl1: 0.01,
        bl3: 0.01,
        vf1: 0.01,
        vf2: 0.0,
        sa1: 0.0,
        mb1: 0.0,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.89,
        se2: 0.83,
        bl1: 0.03,
        bl3: 0.01,
        vf1: 0.01,
        vf2: 0.01,
        sa1: 0.0,
        mb1: 0.0,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.96,
        se2: 0.92,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.01,
        vf2: 0.0,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.94,
        se2: 0.88,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.0,
        vf2: 0.01,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.95,
        se2: 0.9,
        bl1: 0.0,
        bl3: 0.0,
        vf1: 0.0,
        vf2: 0.0,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.96,
        se2: 0.91,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.0,
        vf2: 0.0,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.93,
        se2: 0.87,
        bl1: 0.02,
        bl3: 0.01,
        vf1: 0.02,
        vf2: 0.0,
        sa1: 0.01,
        mb1: 0.0,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.9,
        se2: 0.85,
        bl1: 0.01,
        bl3: 0.01,
        vf1: 0.02,
        vf2: 0.0,
        sa1: 0.0,
        mb1: 0.0,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Semen',
        se1: 0.89,
        se2: 0.85,
        bl1: 0.03,
        bl3: 0.01,
        vf1: 0.04,
        vf2: 0.01,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.35,
        bl3: 0.28,
        vf1: 0.06,
        vf2: 0.02,
        sa1: 0.01,
        mb1: 0.02,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.34,
        bl3: 0.2,
        vf1: 0.15,
        vf2: 0.04,
        sa1: 0.01,
        mb1: 0.02,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.02,
        se2: 0.0,
        bl1: 0.42,
        bl3: 0.28,
        vf1: 0.09,
        vf2: 0.02,
        sa1: 0.03,
        mb1: 0.03,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.2,
        bl3: 0.23,
        vf1: 0.03,
        vf2: 0.02,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.19,
        bl3: 0.15,
        vf1: 0.09,
        vf2: 0.02,
        sa1: 0.02,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.35,
        bl3: 0.35,
        vf1: 0.05,
        vf2: 0.03,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.37,
        bl3: 0.34,
        vf1: 0.11,
        vf2: 0.03,
        sa1: 0.01,
        mb1: 0.02,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.02,
        se2: 0.0,
        bl1: 0.56,
        bl3: 0.51,
        vf1: 0.18,
        vf2: 0.04,
        sa1: 0.02,
        mb1: 0.02,
        mb2: 0.02,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.28,
        bl3: 0.27,
        vf1: 0.19,
        vf2: 0.04,
        sa1: 0.02,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.45,
        bl3: 0.4,
        vf1: 0.11,
        vf2: 0.04,
        sa1: 0.01,
        mb1: 0.02,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.38,
        bl3: 0.31,
        vf1: 0.13,
        vf2: 0.01,
        sa1: 0.02,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.3,
        bl3: 0.24,
        vf1: 0.05,
        vf2: 0.02,
        sa1: 0.01,
        mb1: 0.02,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.42,
        bl3: 0.27,
        vf1: 0.12,
        vf2: 0.02,
        sa1: 0.01,
        mb1: 0.02,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.39,
        bl3: 0.36,
        vf1: 0.15,
        vf2: 0.02,
        sa1: 0.02,
        mb1: 0.02,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.02,
        se2: 0.01,
        bl1: 0.43,
        bl3: 0.32,
        vf1: 0.04,
        vf2: 0.02,
        sa1: 0.04,
        mb1: 0.02,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.42,
        bl3: 0.37,
        vf1: 0.09,
        vf2: 0.02,
        sa1: 0.0,
        mb1: 0.02,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.38,
        bl3: 0.35,
        vf1: 0.06,
        vf2: 0.02,
        sa1: 0.04,
        mb1: 0.02,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.35,
        bl3: 0.29,
        vf1: 0.11,
        vf2: 0.01,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.56,
        bl3: 0.38,
        vf1: 0.16,
        vf2: 0.03,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.02,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.33,
        bl3: 0.35,
        vf1: 0.2,
        vf2: 0.02,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.02,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.47,
        bl3: 0.35,
        vf1: 0.11,
        vf2: 0.04,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.35,
        bl3: 0.22,
        vf1: 0.17,
        vf2: 0.03,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.01,
        bl3: 0.01,
        vf1: 0.62,
        vf2: 0.52,
        sa1: 0.01,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.03,
        bl3: 0.01,
        vf1: 0.59,
        vf2: 0.29,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.04,
        bl3: 0.0,
        vf1: 0.6,
        vf2: 0.26,
        sa1: 0.03,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.04,
        bl3: 0.01,
        vf1: 0.57,
        vf2: 0.17,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.64,
        vf2: 0.64,
        sa1: 0.03,
        mb1: 0.02,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.06,
        bl3: 0.01,
        vf1: 0.59,
        vf2: 0.15,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.02,
        bl3: 0.01,
        vf1: 0.77,
        vf2: 0.43,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.72,
        vf2: 0.29,
        sa1: 0.01,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.01,
        bl3: 0.01,
        vf1: 0.74,
        vf2: 0.52,
        sa1: 0.01,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.02,
        bl3: 0.0,
        vf1: 0.73,
        vf2: 0.53,
        sa1: 0.04,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.0,
        bl3: 0.0,
        vf1: 0.71,
        vf2: 0.83,
        sa1: 0.01,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.02,
        se2: 0.0,
        bl1: 0.04,
        bl3: 0.0,
        vf1: 0.7,
        vf2: 0.44,
        sa1: 0.03,
        mb1: 0.0,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.02,
        bl3: 0.0,
        vf1: 0.71,
        vf2: 0.67,
        sa1: 0.05,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.02,
        bl3: 0.01,
        vf1: 0.77,
        vf2: 0.7,
        sa1: 0.06,
        mb1: 0.0,
        mb2: 0.02,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.05,
        bl3: 0.02,
        vf1: 0.31,
        vf2: 0.03,
        sa1: 0.0,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.65,
        vf2: 0.49,
        sa1: 0.02,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.02,
        se2: 0.01,
        bl1: 0.12,
        bl3: 0.08,
        vf1: 0.75,
        vf2: 0.71,
        sa1: 0.05,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.76,
        vf2: 0.64,
        sa1: 0.02,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.0,
        bl3: 0.0,
        vf1: 0.76,
        vf2: 0.84,
        sa1: 0.01,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.02,
        se2: 0.0,
        bl1: 0.01,
        bl3: 0.01,
        vf1: 0.71,
        vf2: 0.83,
        sa1: 0.05,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.01,
        bl3: 0.0,
        vf1: 0.73,
        vf2: 0.62,
        sa1: 0.05,
        mb1: 0.0,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.02,
        se2: 0.01,
        bl1: 0.09,
        bl3: 0.04,
        vf1: 0.67,
        vf2: 0.39,
        sa1: 0.01,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.12,
        bl3: 0.14,
        vf1: 0.82,
        vf2: 0.68,
        sa1: 0.03,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Vaginal fluid',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.2,
        bl3: 0.13,
        vf1: 0.67,
        vf2: 0.41,
        sa1: 0.02,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.0,
        se2: 0.01,
        bl1: 0.02,
        bl3: 0.0,
        vf1: 0.06,
        vf2: 0.01,
        sa1: 0.12,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.06,
        bl3: 0.0,
        vf1: 0.0,
        vf2: 0.01,
        sa1: 0.1,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.01,
        bl3: 0.02,
        vf1: 0.08,
        vf2: 0.01,
        sa1: 0.36,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.02,
        bl3: 0.02,
        vf1: 0.06,
        vf2: 0.03,
        sa1: 0.29,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.03,
        bl3: 0.01,
        vf1: 0.03,
        vf2: 0.02,
        sa1: 0.19,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.02,
        bl3: 0.01,
        vf1: 0.05,
        vf2: 0.02,
        sa1: 0.2,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.02,
        bl3: 0.01,
        vf1: 0.03,
        vf2: 0.03,
        sa1: 0.72,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.03,
        bl3: 0.0,
        vf1: 0.1,
        vf2: 0.02,
        sa1: 0.15,
        mb1: 0.01,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.01,
        bl3: 0.01,
        vf1: 0.05,
        vf2: 0.01,
        sa1: 0.11,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.04,
        bl3: 0.01,
        vf1: 0.09,
        vf2: 0.02,
        sa1: 0.27,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.03,
        bl3: 0.01,
        vf1: 0.05,
        vf2: 0.01,
        sa1: 0.08,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.04,
        bl3: 0.02,
        vf1: 0.02,
        vf2: 0.01,
        sa1: 0.21,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.02,
        bl3: 0.01,
        vf1: 0.17,
        vf2: 0.02,
        sa1: 0.21,
        mb1: 0.0,
        mb2: 0.01,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.03,
        bl3: 0.02,
        vf1: 0.13,
        vf2: 0.02,
        sa1: 0.22,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.05,
        bl3: 0.01,
        vf1: 0.08,
        vf2: 0.03,
        sa1: 0.12,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.03,
        bl3: 0.01,
        vf1: 0.03,
        vf2: 0.01,
        sa1: 0.14,
        mb1: 0.0,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.03,
        bl3: 0.01,
        vf1: 0.04,
        vf2: 0.01,
        sa1: 0.17,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.02,
        bl3: 0.01,
        vf1: 0.05,
        vf2: 0.01,
        sa1: 0.1,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.04,
        bl3: 0.02,
        vf1: 0.12,
        vf2: 0.03,
        sa1: 0.3,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.05,
        bl3: 0.02,
        vf1: 0.02,
        vf2: 0.03,
        sa1: 0.11,
        mb1: 0.01,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Saliva',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.01,
        bl3: 0.01,
        vf1: 0.05,
        vf2: 0.02,
        sa1: 0.3,
        mb1: 0.02,
        mb2: 0.0,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.09,
        bl3: 0.06,
        vf1: 0.61,
        vf2: 0.37,
        sa1: 0.02,
        mb1: 0.06,
        mb2: 0.05,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.19,
        bl3: 0.15,
        vf1: 0.15,
        vf2: 0.07,
        sa1: 0.02,
        mb1: 0.17,
        mb2: 0.15,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.02,
        bl1: 0.47,
        bl3: 0.27,
        vf1: 0.23,
        vf2: 0.07,
        sa1: 0.01,
        mb1: 0.11,
        mb2: 0.1,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.07,
        bl3: 0.19,
        vf1: 0.51,
        vf2: 0.21,
        sa1: 0.02,
        mb1: 0.21,
        mb2: 0.21,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.08,
        bl3: 0.12,
        vf1: 0.17,
        vf2: 0.12,
        sa1: 0.01,
        mb1: 0.13,
        mb2: 0.12,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.04,
        bl3: 0.02,
        vf1: 0.69,
        vf2: 0.6,
        sa1: 0.05,
        mb1: 0.03,
        mb2: 0.03,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.29,
        bl3: 0.21,
        vf1: 0.45,
        vf2: 0.06,
        sa1: 0.02,
        mb1: 0.06,
        mb2: 0.05,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.0,
        se2: 0.01,
        bl1: 0.13,
        bl3: 0.07,
        vf1: 0.15,
        vf2: 0.1,
        sa1: 0.0,
        mb1: 0.1,
        mb2: 0.09,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.15,
        bl3: 0.18,
        vf1: 0.22,
        vf2: 0.08,
        sa1: 0.01,
        mb1: 0.19,
        mb2: 0.19,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.15,
        bl3: 0.13,
        vf1: 0.22,
        vf2: 0.08,
        sa1: 0.01,
        mb1: 0.14,
        mb2: 0.13,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.1,
        bl3: 0.08,
        vf1: 0.56,
        vf2: 0.29,
        sa1: 0.03,
        mb1: 0.11,
        mb2: 0.1,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.15,
        bl3: 0.19,
        vf1: 0.11,
        vf2: 0.19,
        sa1: 0.02,
        mb1: 0.26,
        mb2: 0.25,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.21,
        bl3: 0.17,
        vf1: 0.44,
        vf2: 0.18,
        sa1: 0.0,
        mb1: 0.13,
        mb2: 0.13,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.0,
        se2: 0.01,
        bl1: 0.25,
        bl3: 0.15,
        vf1: 0.37,
        vf2: 0.13,
        sa1: 0.01,
        mb1: 0.08,
        mb2: 0.08,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.0,
        se2: 0.0,
        bl1: 0.1,
        bl3: 0.08,
        vf1: 0.26,
        vf2: 0.11,
        sa1: 0.01,
        mb1: 0.1,
        mb2: 0.1,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.12,
        bl3: 0.03,
        vf1: 0.29,
        vf2: 0.05,
        sa1: 0.01,
        mb1: 0.03,
        mb2: 0.02,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.11,
        bl3: 0.1,
        vf1: 0.19,
        vf2: 0.19,
        sa1: 0.02,
        mb1: 0.15,
        mb2: 0.15,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.01,
        bl1: 0.1,
        bl3: 0.16,
        vf1: 0.29,
        vf2: 0.14,
        sa1: 0.01,
        mb1: 0.2,
        mb2: 0.18,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.06,
        bl3: 0.04,
        vf1: 0.69,
        vf2: 0.39,
        sa1: 0.01,
        mb1: 0.02,
        mb2: 0.02,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.04,
        bl3: 0.03,
        vf1: 0.69,
        vf2: 0.53,
        sa1: 0.02,
        mb1: 0.02,
        mb2: 0.02,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.35,
        bl3: 0.38,
        vf1: 0.18,
        vf2: 0.31,
        sa1: 0.03,
        mb1: 0.49,
        mb2: 0.48,
    },
    {
        g_analyzer: 'Miseq',
        bf_type: 'Menstrual blood',
        se1: 0.01,
        se2: 0.0,
        bl1: 0.13,
        bl3: 0.09,
        vf1: 0.54,
        vf2: 0.17,
        sa1: 0.02,
        mb1: 0.09,
        mb2: 0.09,
    },
];
