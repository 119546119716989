import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

export default function Portal() {
    return (
        <Box p={4} w={'full'}>
            <Outlet />
        </Box>
    );
}
