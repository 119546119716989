import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image,
    Icon,
    SimpleGrid,
    useColorModeValue,
} from '@chakra-ui/react';

export default function Home() {
    return (
        <Stack>
            <Container maxW={'6xl'}>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, sm: 10, md: 10 }}
                    py={{ base: 10, md: 18 }}
                    px={{ base: 5, md: 18, lg: 18, xl: 0 }}
                    direction={{ base: 'column', md: 'row' }}
                >
                    <Stack flex={1} spacing={{ base: 5, sm: 8, md: 10 }}>
                        <Heading lineHeight={1.1} fontWeight={700}>
                            <Text
                                as={'span'}
                                position={'relative'}
                                fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                            >
                                융복합 지능형
                            </Text>
                            <br />
                            <Text
                                as={'span'}
                                position={'relative'}
                                fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                            >
                                DNA 감식을 통한
                            </Text>
                            <br />
                            <Text
                                as={'span'}
                                position={'relative'}
                                fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}
                            >
                                용의자 추정 시스템 개발
                            </Text>
                        </Heading>
                        <Text color={'gray.500'}>
                            선진기술 기반 DNA 분석 기술 및 인공지능 기반 DNA
                            정보 분석기술을 활용하는 'DNA 융복합 지능형 용의자
                            추정 시스템'을 개발하여 DNA 수사 활용도 제고를 통한
                            신속한 사건 해결로 범죄로부터 안전한 사회 구현
                        </Text>
                        <Stack
                            spacing={{ base: 4, sm: 6 }}
                            direction={{ base: 'column', sm: 'row' }}
                        >
                            <Button
                                as="a"
                                size={'lg'}
                                fontWeight={'normal'}
                                px={6}
                                colorScheme={'red'}
                                bg={'blue.500'}
                                _hover={{ bg: 'blue.600' }}
                                href="/research"
                            >
                                About Research
                            </Button>
                        </Stack>
                    </Stack>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}
                    >
                        <Blob
                            w={'150%'}
                            h={'150%'}
                            position={'absolute'}
                            top={'-20%'}
                            left={0}
                            zIndex={-1}
                            color={useColorModeValue('blue.50', 'blue.600')}
                        />
                        <Box
                            position={'relative'}
                            height={'600px'}
                            width={'full'}
                            overflow={'hidden'}
                            align={'right'}
                        >
                            <Image
                                alt={'Hero Image'}
                                fit={'scale-down'}
                                align={'right'}
                                w={'80%'}
                                h={'100%'}
                                src={'../images/next-gen.png'}
                            />
                        </Box>
                    </Flex>
                </Stack>
                <Box position={'relative'}></Box>
            </Container>
            <Container maxW={'6xl'}>
                <Stack spacing={0} h="440px" mb={20}>
                    <Stack bg="gray.50" align="center" pt={20}>
                        <Box mb={10}>
                            <Heading fontSize={{ base: '3xl', md: '4xl' }}>
                                Core Values - 4I
                            </Heading>
                        </Box>
                    </Stack>
                    <Stack
                        flex={1}
                        bg="gray.50"
                        align={{ lg: 'center' }}
                        pb={10}
                    >
                        <SimpleGrid
                            columns={{ base: 1, md: 2 }}
                            spacing={10}
                            px={{
                                base: 10,
                                sm: 20,
                                md: 20,
                            }}
                        >
                            {stats.map((stat) => (
                                <Box key={stat.title}>
                                    <Text
                                        fontFamily={'heading'}
                                        fontSize={'2xl'}
                                        fontWeight={'500'}
                                        mb={1}
                                    >
                                        {stat.title}
                                    </Text>
                                    <Text fontSize={'md'} color={'gray.600'}>
                                        {stat.content}
                                    </Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Stack>
                </Stack>
                <Stack
                    alignItems="center"
                    pt={{
                        base: 20,
                        sm: 20,
                        md: 20,
                    }}
                    pb="100px"
                >
                    <Box
                        mt={{
                            base: 20,
                            sm: 20,
                            md: 5,
                        }}
                    >
                        <Heading
                            fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                        >
                            Explore with Your Data
                        </Heading>
                    </Box>
                    <Box mb={50}>
                        <Heading
                            fontSize={{ base: '1xl', sm: '2xl', md: '2xl' }}
                            fontWeight={400}
                        >
                            차세대 법유전학 시스템 포털 방문하기
                        </Heading>
                    </Box>
                    <Box mb={50}>
                        <Button
                            as="a"
                            colorScheme="blue"
                            _hover={{ color: 'white', bg: '#2b6cb0' }}
                            height="50px"
                            w="200px"
                            href="/portal/methyl-snapshot-bsb"
                        >
                            <Text fontSize="lg">Visit Portal</Text>
                        </Button>
                    </Box>
                </Stack>
            </Container>
        </Stack>
    );
}

const Blob = (props) => {
    return (
        <Icon
            width={'100%'}
            viewBox="0 0 578 440"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
                fill="currentColor"
            />
        </Icon>
    );
};

const stats = [
    {
        title: 'Intelligent',
        content: <>‘사람+인공’ 지능, Expert, Human ELSI</>,
    },
    {
        title: 'Innovative',
        content: <>DNA methylation, Microhaplotype, MPS system</>,
    },
    {
        title: 'Integrative',
        content: <>Systematic, 시료, Forensic DB</>,
    },
    {
        title: 'Interactive',
        content: <>실험실, 현장, 실무</>,
    },
];
