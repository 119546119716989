import {
    Box,
    VStack,
    Image,
    Text,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from '@chakra-ui/react';

export default function AncesTryInTro() {
    return (
        <Box py={6} px={2} maxWidTh="100%" background="#FFFFFF">
            <VStack align="left" mb={6}>
                {/* Page Title */}
                <VStack align="left" mb={4}>
                    <Breadcrumb>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Ancestry Prediction</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Introduction</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading as="h1" size="lg">
                        Introduction
                    </Heading>
                </VStack>
                {/* Page Content */}
                <VStack
                    justify="flex-start"
                    align="flex-start"
                    spacing="15px"
                    alignSelf="sTretch"
                >
                    <Text lineHeight="1.33" fontSize="2xl" color="black.700">
                        Reference Populations
                    </Text>
                    <Box>
                        <Image
                            alt="Reference Map"
                            fit={'scale-down'}
                            align={'center'}
                            // w={'80%'}
                            h={'100%'}
                            src="../images/reference_map.png"
                        />
                    </Box>

                    {/* Table 섹션  */}
                    <Table size="sm">
                        <Thead>
                            <Tr>
                                <Th
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                    width="auto"
                                >
                                    No.
                                </Th>
                                <Th
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                    width="auto"
                                >
                                    Category
                                </Th>
                                <Th
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                    width="auto"
                                >
                                    Origin
                                </Th>
                                <Th
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                    width="auto"
                                >
                                    Web_ID
                                </Th>
                                <Th
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                    width="auto"
                                >
                                    # of samples
                                </Th>
                                <Th
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                    width="auto"
                                >
                                    Reference
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td rowspan="4">1</Td>
                                <Td
                                    rowspan="4"
                                    style={{ backgroundColor: '#C9E3F4' }}
                                >
                                    Northeast
                                </Td>
                                <Td rowspan="4">Japanese</Td>
                                <Td>SNP_SNU_JAP_001~120</Td>
                                <Td>120</Td>
                                <Td rowspan="2">
                                    Samples from:
                                    https://www.coriell.org/1/NHGRI/Collections/1000-Genomes-Project-Collection/Japanese-in-Tokyo-Japan-JPT
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_JAP_001~061</Td>
                                <Td>61</Td>
                            </Tr>
                            <Tr>
                                <Td>Y_REF_JAP_0001~0161</Td>
                                <Td>161</Td>
                                <Td>
                                    Hara M, Kido A, Takada A, Adachi N, Saito K
                                    (2007) Genetic data for 16 Y-chromosomal STR
                                    loci in Japanese. Legal Med-Tokyo 9: 161-70.
                                    doi: 10.1016/j.legalmed.2006.11.002
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_REF_JAP_0162~1460</Td>
                                <Td>1299</Td>
                                <Td>
                                    Watahiki H, Fujii K, Fukagawa T, Mita Y,
                                    Kitayama T, Mizuno N (2019) Polymorphisms
                                    and microvariant sequences in the Japanese
                                    population for 25 Y-STR markers and their
                                    relationships to Y-chromosome haplogroups.
                                    Forensic Sci Int-Gen 41: E1-E7. doi:
                                    10.1016/j.fsigen.2019.03.004
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>2</Td>
                                <Td style={{ backgroundColor: '#C9E3F4' }}>
                                    Northeast
                                </Td>
                                <Td>Korean</Td>
                                <Td>Y_REF_KOR_0001~0123</Td>
                                <Td>123</Td>
                                <Td>
                                    Jeong KS, Shin H, Lee SJ et al (2018)
                                    Genetic characteristics of Y-chromosome
                                    short tandem repeat haplotypes from
                                    cigarette butt samples presumed to be smoked
                                    by North Korean men. Genes Genom 40: 819-24.
                                    doi: 10.1007/s13258-018-0701-5
                                </Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="3">3</Td>
                                <Td
                                    rowspan="3"
                                    style={{ backgroundColor: '#C9E3F4' }}
                                >
                                    Northeast
                                </Td>
                                <Td rowspan="3">Korean</Td>
                                <Td>Y_REF_KOR_0124~0429</Td>
                                <Td>306</Td>
                                <Td>
                                    Jung JY, Park JH, Oh YL et al (2016)
                                    Forensic genetic study of 29 Y-STRs in
                                    Korean population. Legal Med-Tokyo 23:
                                    17-20. doi: 10.1016/j.legalmed.2016.09.001
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_SNU_KOR_001~344</Td>
                                <Td>344</Td>
                                <Td rowspan="2">
                                    Samples from Department of Forensic
                                    Medicine, Seoul National University College
                                    of Medicine
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_KOR_001~211</Td>
                                <Td>211</Td>
                            </Tr>
                            <Tr>
                                <Td>4</Td>
                                <Td style={{ backgroundColor: '#C9E3F4' }}>
                                    Northeast
                                </Td>
                                <Td>Korean</Td>
                                <Td>Y_REF_KOR_0430~0776</Td>
                                <Td>347</Td>
                                <Td>
                                    Wang YQ, Li SY, Dang Z et al (2019) Genetic
                                    diversity and haplotype structure of 27
                                    Y-STR loci in a Yanbian Korean population
                                    from Jilin Province, Northeast China. Legal
                                    Med-Tokyo 36: 110-2. doi:
                                    10.1016/j.legalmed.2018.11.010
                                </Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="2">5</Td>
                                <Td
                                    rowspan="2"
                                    style={{ backgroundColor: '#C9E3F4' }}
                                >
                                    Northeast
                                </Td>
                                <Td rowspan="2">Northern Chinese</Td>
                                <Td>SNP_SNU_NCHN_001~120</Td>
                                <Td>120</Td>
                                <Td rowspan="2">
                                    Samples from:
                                    https://www.coriell.org/1/NHGRI/Collections/1000-Genomes-Project-Collection/Han-Chinese-in-Beijing-China-CHB
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_NCHN_001~050</Td>
                                <Td>50</Td>
                            </Tr>
                            <Tr>
                                <Td>6</Td>
                                <Td style={{ backgroundColor: '#C9E3F4' }}>
                                    Northeast
                                </Td>
                                <Td>Northern Chinese</Td>
                                <Td>Y_REF_NCHN_0001~1225</Td>
                                <Td>1225</Td>
                                <Td>
                                    Bai R, Liu Y, Zhang J et al (2016) Analysis
                                    of 27 Y-chromosomal STR haplotypes in a Han
                                    population of Henan province, Central China.
                                    Int J Legal Med 130: 1191-4. doi:
                                    10.1007/s00414-016-1326-3
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>7</Td>
                                <Td style={{ backgroundColor: '#C9E3F4' }}>
                                    Northeast
                                </Td>
                                <Td>Northern Chinese</Td>
                                <Td>Y_REF_NCHN_1226~1448</Td>
                                <Td>223</Td>
                                <Td>
                                    Li XB, Zhang JS, Li LL, Zha L, Shi MS, Ding
                                    MX (2020) Genetic polymorphism of 24 Y-STR
                                    loci in Altay Hui and Kazakh populations
                                    from northwest China. Legal Med-Tokyo 47.
                                    doi: 10.1016/j.legalmed.2020.101760
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>8</Td>
                                <Td style={{ backgroundColor: '#C9E3F4' }}>
                                    Northeast
                                </Td>
                                <Td>Mongolian</Td>
                                <Td>Y_REF_MNG_0001~0258</Td>
                                <Td>258</Td>
                                <Td>
                                    Gao TZ, Yun LB, Gao S et al (2016)
                                    Population genetics of 23 Y-STR loci in the
                                    Mongolian minority population in Inner
                                    Mongolia of China. Int J Legal Med 130:
                                    1509-11. doi: 10.1007/s00414-016-1433-1
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>9</Td>
                                <Td style={{ backgroundColor: '#C9E3F4' }}>
                                    Northeast
                                </Td>
                                <Td>Mongolian</Td>
                                <Td>Y_REF_MNG_0259~0556</Td>
                                <Td>298</Td>
                                <Td>
                                    Fu XL, Fu Y, Liu Y et al (2016) Genetic
                                    polymorphisms of 26 Y-STR loci in the
                                    Mongolian minority from Horqin district,
                                    China. Int J Legal Med 130: 941-6. doi:
                                    10.1007/s00414-016-1387-3
                                </Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="2">10</Td>
                                <Td
                                    rowspan="2"
                                    style={{ backgroundColor: '#FCDFD7' }}
                                >
                                    Southeast
                                </Td>
                                <Td rowspan="2">Burmese</Td>
                                <Td>SNP_SNU_MMR_001~200</Td>
                                <Td>200</Td>
                                <Td rowspan="2">
                                    Samples from Department of Forensic
                                    Medicine, Seoul National University College
                                    of Medicine
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_MMR_001~099</Td>
                                <Td>99</Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="2">11</Td>
                                <Td
                                    rowspan="2"
                                    style={{ backgroundColor: '#FCDFD7' }}
                                >
                                    Southeast
                                </Td>
                                <Td rowspan="2">Vietnamese</Td>
                                <Td>SNP_SNU_VNM_001~305</Td>
                                <Td>305</Td>
                                <Td rowspan="2">
                                    Samples from Department of Forensic
                                    Medicine, Seoul National University College
                                    of Medicine
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_VNM_047~106</Td>
                                <Td>60</Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="2">12</Td>
                                <Td
                                    rowspan="2"
                                    style={{ backgroundColor: '#FCDFD7' }}
                                >
                                    Southeast
                                </Td>
                                <Td rowspan="2">Vietnamese</Td>
                                <Td>SNP_SNU_VNM_306~405</Td>
                                <Td>100</Td>
                                <Td rowspan="2">
                                    Samples from:
                                    https://www.coriell.org/1/NHGRI/Collections/1000-Genomes-Project-Collection/Kinh-in-Ho-Chi-Minh-City-Vietnam-KHV
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_VNM_001~046</Td>
                                <Td>46</Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="3">13</Td>
                                <Td
                                    rowspan="3"
                                    style={{ backgroundColor: '#FCDFD7' }}
                                >
                                    Southeast
                                </Td>
                                <Td rowspan="3">Southern Chinese</Td>
                                <Td>SNP_REF_SCHN_001~014</Td>
                                <Td>14</Td>
                                <Td>
                                    <a href="http://mathgene.usc.es/snipper/forensic_mps_aims.html">
                                        http://mathgene.usc.es/snipper/forensic_mps_aims.html
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_SNU_SCHN_001~099</Td>
                                <Td>99</Td>
                                <Td rowspan="2">
                                    Samples from:
                                    https://www.coriell.org/1/NHGRI/Collections/1000-Genomes-Project-Collection/Han-Chinese-South-China-CHS
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_SCHN_001~050</Td>
                                <Td>50</Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="3">14</Td>
                                <Td
                                    rowspan="3"
                                    style={{ backgroundColor: '#FCDFD7' }}
                                >
                                    Southeast
                                </Td>
                                <Td rowspan="3">Southern Chinese</Td>
                                <Td>SNP_REF_SCHN_015~107</Td>
                                <Td>93</Td>
                                <Td>
                                    <a href="http://mathgene.usc.es/snipper/forensic_mps_aims.html">
                                        http://mathgene.usc.es/snipper/forensic_mps_aims.html
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_SNU_SCHN_100~108</Td>
                                <Td>9</Td>
                                <Td rowspan="2">
                                    Samples from:
                                    https://www.coriell.org/1/NHGRI/Collections/1000-Genomes-Project-Collection/Chinese-Dai---Xishuangbanna-CDX
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_SCHN_051~100</Td>
                                <Td>50</Td>
                            </Tr>
                            <Tr>
                                <Td>15</Td>
                                <Td style={{ backgroundColor: '#FCDFD7' }}>
                                    Southeast
                                </Td>
                                <Td>Southern Chinese</Td>
                                <Td>Y_REF_SCHN_0001~0310</Td>
                                <Td>310</Td>
                                <Td>
                                    Jiang W, Gong Z, Rong H et al (2017)
                                    Population genetics of 26 Y-STR loci for the
                                    Han ethnic in Hunan province, China. Int J
                                    Legal Med 131: 115-7. doi:
                                    10.1007/s00414-016-1411-7
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>16</Td>
                                <Td style={{ backgroundColor: '#FCDFD7' }}>
                                    Southeast
                                </Td>
                                <Td>Southern Chinese</Td>
                                <Td>Y_REF_SCHN_0311~0710</Td>
                                <Td>400</Td>
                                <Td>
                                    Luo Y, Wu Y, Qian E et al (2019) Population
                                    genetic analysis of 36 Y-chromosomal STRs
                                    yields comprehensive insights into the
                                    forensic features and phylogenetic
                                    relationship of Chinese Tai-Kadai-speaking
                                    Bouyei. Plos One 14: e0224601. doi:
                                    10.1371/journal.pone.0224601
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>17</Td>
                                <Td style={{ backgroundColor: '#FCDFD7' }}>
                                    Southeast
                                </Td>
                                <Td>Southern Chinese</Td>
                                <Td>Y_REF_SCHN_0711~0811</Td>
                                <Td>101</Td>
                                <Td rowspan="3">
                                    Hwa HL, Tseng LH, Ko TM et al (2010)
                                    Seventeen Y-chromosomal short tandem repeat
                                    haplotypes in seven groups of population
                                    living in Taiwan. Int J Legal Med 124:
                                    295-300. doi: 10.1007/s00414-010-0425-9
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>18</Td>
                                <Td style={{ backgroundColor: '#FCDFD7' }}>
                                    Southeast
                                </Td>
                                <Td>Taiwanese</Td>
                                <Td>Y_REF_TWN_0001~0332</Td>
                                <Td>332</Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="3">19</Td>
                                <Td
                                    rowspan="3"
                                    style={{ backgroundColor: '#FCDFD7' }}
                                >
                                    Southeast
                                </Td>
                                <Td rowspan="3">Filipino</Td>
                                <Td>Y_REF_PHL_0630~0684</Td>
                                <Td>55</Td>
                            </Tr>
                            <Tr>
                                <Td>Y_REF_PHL_0001~0629</Td>
                                <Td>629</Td>
                                <Td>
                                    Nazir M, Alhaddad H, Alenizi M et al (2016)
                                    A genetic overview of 23Y-STR markers in UAE
                                    population. Forensic Sci Int-Gen 23: 150-2.
                                    doi: 10.1016/j.fsigen.2016.04.009
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_SNU_PHL_001~002</Td>
                                <Td>2</Td>
                                <Td>
                                    Samples from Department of Forensic
                                    Medicine, Seoul National University College
                                    of Medicine
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>20</Td>
                                <Td style={{ backgroundColor: '#FCDFD7' }}>
                                    Southeast
                                </Td>
                                <Td rowspan="2">Malaysian</Td>
                                <Td>Y_REF_MYS_0001~0320</Td>
                                <Td>320</Td>
                                <Td>
                                    Chang YM, Swaran Y, Phoon YK et al (2009)
                                    Haplotype diversity of 17 Y-chromosomal STRs
                                    in three native Sarawak populations (Iban,
                                    Bidayuh and Melanau) in East Malaysia.
                                    Forensic Sci Int-Gen 3: E77-E80. doi:
                                    10.1016/j.fsigen.2008.07.007
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>21</Td>
                                <Td style={{ backgroundColor: '#FCDFD7' }}>
                                    Southeast
                                </Td>
                                <Td>Y_REF_MYS_0321~0448</Td>
                                <Td>128</Td>
                                <Td>
                                    Hakim HM, Khan HO, Ismail SA et al (2020)
                                    Population data for 23 Y chromosome STR loci
                                    using the Powerplex® Y23 STR kit for the
                                    Kedayan population in Malaysia. Int J Legal
                                    Med 134: 1335-7. doi:
                                    10.1007/s00414-019-02237-4
                                </Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="5">22</Td>
                                <Td
                                    rowspan="5"
                                    style={{ backgroundColor: '#D4ECDC' }}
                                >
                                    Southwest
                                </Td>
                                <Td rowspan="5">Bangladeshi</Td>
                                <Td>Y_REF_BGD_0001~1187</Td>
                                <Td>1187</Td>
                                <Td>
                                    Hasan M, Sufian A, Momtaz P et al (2018)
                                    Phylogenetic analysis and forensic
                                    evaluation among Rakhine, Marma, Hajong, and
                                    Manipuri tribes from four culturally defined
                                    regions of Bangladesh using 17 Y-chromosomal
                                    STRs. Int J Legal Med 132: 1641-4. doi:
                                    10.1007/s00414-018-1915-4
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_REF_BGD_1188~1446</Td>
                                <Td>259</Td>
                                <Td>
                                    Hasan M, Momtaz P, Hosen I, Das SA,
                                    Akhteruzzaman S (2015) Population genetics
                                    of 17 Y-chromosomal STRs loci in Garo and
                                    Santal tribal populations in Bangladesh. Int
                                    J Legal Med 129: 251-2. doi:
                                    10.1007/s00414-014-0981-5
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_SNU_BGD_001~018</Td>
                                <Td>18</Td>
                                <Td rowspan="2">
                                    Samples from:
                                    https://www.coriell.org/1/NHGRI/Collections/1000-Genomes-Project-Collection/Bengali-in-Bangladesh-BEB
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_BGD_001~050</Td>
                                <Td>50</Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_REF_BGD_001~086</Td>
                                <Td>86</Td>
                                <Td>
                                    <a href="http://mathgene.usc.es/snipper/forensic_mps_aims.html">
                                        http://mathgene.usc.es/snipper/forensic_mps_aims.html
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="11">23</Td>
                                <Td
                                    rowspan="11"
                                    style={{ backgroundColor: '#D4ECDC' }}
                                >
                                    Southwest
                                </Td>
                                <Td rowspan="11">Indian</Td>
                                <Td>SNP_SNU_IND_001~051</Td>
                                <Td>51</Td>
                                <Td rowspan="2">
                                    Samples from Department of Forensic
                                    Medicine, Seoul National University College
                                    of Medicine
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_IND_001~021</Td>
                                <Td>21</Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_SNU_IND_052~056</Td>
                                <Td>5</Td>
                                <Td rowspan="2">
                                    Samples from:
                                    https://www.coriell.org/1/NHGRI/Collections/1000-Genomes-Project-Collection/Gujarati-Indians-in-Houston-TX-USA-GIH
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_IND_086~145</Td>
                                <Td>60</Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_REF_IND_001~103</Td>
                                <Td>103</Td>
                                <Td>
                                    <a href="http://mathgene.usc.es/snipper/forensic_mps_aims.html">
                                        http://mathgene.usc.es/snipper/forensic_mps_aims.html
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_SNU_IND_057~065</Td>
                                <Td>9</Td>
                                <Td rowspan="2">
                                    Samples from:
                                    https://www.coriell.org/1/NHGRI/Collections/1000-Genomes-Project-Collection/Indian-Telugu-in-the-UK-ITU
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_IND_022~085</Td>
                                <Td>64</Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_REF_IND_104~205</Td>
                                <Td>102</Td>
                                <Td>
                                    <a href="http://mathgene.usc.es/snipper/forensic_mps_aims.html">
                                        http://mathgene.usc.es/snipper/forensic_mps_aims.html
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_REF_IND_0001~0750</Td>
                                <Td>750</Td>
                                <Td>
                                    Ghosh T, Kalpana D, Mukerjee S et al (2011)
                                    Genetic diversity of 17 Y-short tandem
                                    repeats in Indian population. Forensic Sci
                                    Int-Gen 5: 363-7. doi:
                                    10.1016/j.fsigen.2010.12.007
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_REF_IND_0751~0929</Td>
                                <Td>179</Td>
                                <Td>
                                    Yadav B, Raina A, Das Dogra T (2011)
                                    Haplotype diversity of 17 Y-chromosomal STRs
                                    in Saraswat Brahmin Community of North
                                    India. Forensic Sci Int-Gen 5: E63-E70. doi:
                                    10.1016/j.fsigen.2010.09.012
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_REF_IND_0930~1185</Td>
                                <Td>256</Td>
                                <Td>
                                    Mohapatra BK, Chauhan K, Shrivastava P,
                                    Sharma A, Dagar S, Kaitholia K (2019)
                                    Haplotype data for 17 Y-STR loci in the
                                    population of Himachal Pradesh, India. Int J
                                    Legal Med 133: 1401-2. doi:
                                    10.1007/s00414-019-02080-7
                                </Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="2">24</Td>
                                <Td
                                    rowspan="2"
                                    style={{ backgroundColor: '#D4ECDC' }}
                                >
                                    Southwest
                                </Td>
                                <Td rowspan="2">Nepalese</Td>
                                <Td>SNP_SNU_NPL_001~148</Td>
                                <Td>148</Td>
                                <Td rowspan="2">
                                    Samples from Department of Forensic
                                    Medicine, Seoul National University College
                                    of Medicine
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_NPL_001~069</Td>
                                <Td>69</Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="5">25</Td>
                                <Td
                                    rowspan="5"
                                    style={{ backgroundColor: '#D4ECDC' }}
                                >
                                    Southwest
                                </Td>
                                <Td rowspan="5">Pakistani</Td>
                                <Td>SNP_SNU_PAK_001~012</Td>
                                <Td>12</Td>
                                <Td rowspan="2">
                                    Samples from:
                                    https://www.coriell.org/1/NHGRI/Collections/1000-Genomes-Project-Collection/Punjabi-in-Lahore-Pakistan-PJL
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_PAK_001~055</Td>
                                <Td>55</Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_REF_PAK_001~096</Td>
                                <Td>96</Td>
                                <Td>
                                    <a href="http://mathgene.usc.es/snipper/forensic_mps_aims.html">
                                        http://mathgene.usc.es/snipper/forensic_mps_aims.html
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_SNU_PAK_013~062</Td>
                                <Td>50</Td>
                                <Td rowspan="2">
                                    Samples from Department of Forensic
                                    Medicine, Seoul National University College
                                    of Medicine
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_SNU_PAK_063~151</Td>
                                <Td>89</Td>
                            </Tr>
                            <Tr>
                                <Td rowspan="4">26</Td>
                                <Td
                                    rowspan="4"
                                    style={{ backgroundColor: '#D4ECDC' }}
                                >
                                    Southwest
                                </Td>
                                <Td rowspan="4">Sri Lankan</Td>
                                <Td>SNP_SNU_LKA_001~009</Td>
                                <Td>9</Td>
                                <Td rowspan="2">
                                    Samples from:
                                    https://www.coriell.org/1/NHGRI/Collections/1000-Genomes-Project-Collection/Sri-Lankan-Tamil-in-the-UK-STU
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Y_SNU_LKA_001~062</Td>
                                <Td>62</Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_REF_LKA_001~102</Td>
                                <Td>102</Td>
                                <Td>
                                    <a href="http://mathgene.usc.es/snipper/forensic_mps_aims.html">
                                        http://mathgene.usc.es/snipper/forensic_mps_aims.html
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>SNP_SNU_LKA_010~016</Td>
                                <Td>7</Td>
                                <Td>
                                    Samples from Department of Forensic
                                    Medicine, Seoul National University College
                                    of Medicine
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </VStack>
            </VStack>
        </Box>
    );
}
