import { useState } from 'react';
import {
    Button,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Stack,
    VStack,
    HStack,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    ListItem,
    UnorderedList,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import axios from 'axios';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const AncestrySnpPretrained = () => {
    const [currentStep, setCurrentStep] = useState(1); // 초기 단계는 1로 설정
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [isPredictionProcessing, setIsPredictionProcessing] = useState(false);
    const [predictionError, setPredictionError] = useState(''); // 오류 메시지 상태
    const [isPredictionFileUploadReady, setIsPredictionFileUploadReady] =
        useState(false); // 예측 파일 업로드 상태
    const [isPredictButtonDisabled, setIsPredictButtonDisabled] =
        useState(true); // Predict 버튼 활성화 여부

    const [deletedPredRowsCount, setDeletedPredRowsCount] = useState(null);
    const [predictFile, setPredictFile] = useState(null);
    const [predictData, setPredictData] = useState([]); // 예측 결과 데이터
    const [unmatchedCols, setUnmatchedCols] = useState([]);
    const [predTableData, setPredTableData] = useState([]);

    // Handle prediction file change
    const handleFileChange = (e) => {
        setPredictFile(e.target.files[0]);
        setCurrentStep(2); // 새로운 추론 파일 선택
    };

    // 샘플 파일 업로드 핸들러
    const handleFileUpload = async (e) => {
        if (!predictFile) {
            alert('Please select a csv or xlsx file first');
            return;
        }

        const formData = new FormData();
        formData.append('file', predictFile);

        try {
            setCurrentStep(3); // 업로드 시작
            setIsFileUploading(true);
            const response = await axios.post(
                'https://forensicdna.snu.ac.kr/api/snp-pretrained-upload/',
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );

            const unmatchedCols = response.data.unmatched_columns;
            const predData = response.data.pred_data;

            if (unmatchedCols.length > 0) {
                // 컬럼 구성 오류 처리
                setPredictionError(
                    `컬럼 구성이 달라 모델 예측을 수행할 수 없습니다. 일치하지 않는 컬럼: ${unmatchedCols.join(
                        ', '
                    )}. 샘플 파일의 컬럼 구성과 일치하도록 수정해주세요.`
                );
                setUnmatchedCols(unmatchedCols);
                setIsPredictButtonDisabled(true); // Predict 버튼 비활성화
                setCurrentStep(500); // 업로드 에러
            } else if (!predData || predData.length === 0) {
                // 예측할 데이터가 없는 경우 처리
                setPredictionError(
                    '예측할 데이터가 없습니다. 모든 행이 비어 있습니다.'
                );
                setIsPredictButtonDisabled(true); // Predict 버튼 비활성화
                setCurrentStep(500); // 업로드 에러
            } else {
                // 데이터가 올바른 경우
                setPredictionError(''); // 오류 메시지 초기화
                setUnmatchedCols([]);
                setPredTableData(predData);
                setDeletedPredRowsCount(response.data.deleted_rows_count);
                setIsPredictionFileUploadReady(true);
                setIsPredictButtonDisabled(false); // Predict 버튼 활성화
                setPredictFile(null); // predict input file 리셋
            }
        } catch (error) {
            console.error('Error checking prediction file columns:', error);
            setPredictionError(
                '파일 업로드 중 오류가 발생했습니다. 다시 시도해 주세요.'
            );
            setCurrentStep(500); // 업로드 에러
        } finally {
            setIsFileUploading(false);
            setCurrentStep(5); // 업로드 완료
        }
    };

    // 모델 예측
    const handlePredict = async () => {
        try {
            setIsPredictionProcessing(true);
            setCurrentStep(7); // Predict 시작
            const response = await axios.post(
                'https://forensicdna.snu.ac.kr/api/snp-pretrained-predict/',
                {
                    data: predTableData,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setPredictData(response.data.prediction);
        } catch (error) {
            console.error('Prediction error:', error);
        } finally {
            setIsPredictionProcessing(false);
            setCurrentStep(8); // Predict 완료
        }
    };

    // 예측 결과 파일로 다운로드
    // CSV
    const downloadCSV = () => {
        const headers = Object.keys(predictData[0]).join(','); // 헤더
        const rows = predictData
            .map((row) => Object.values(row).join(',')) // 데이터 행
            .join('\n');
        const csvContent = [headers, rows].join('\n'); // 전체 CSV 콘텐츠
        const blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;',
        });
        FileSaver.saveAs(blob, 'SNP_prediction_results.csv');
    };
    // XLSX
    const downloadXLSX = () => {
        const worksheet = XLSX.utils.json_to_sheet(predictData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Predictions');
        const xlsxData = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        const blob = new Blob([xlsxData], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, 'SNP_prediction_results.xlsx');
    };

    return (
        <Stack
            justify="flex-start"
            align="flex-start"
            spacing={'50px'}
            overflow="hidden"
            // width="1231px"
            maxWidth="1080px"
            background="#FFFFFF"
        >
            {/* 헤딩 - alert */}
            <VStack
                justify="flex-start"
                align="flex-start"
                spacing="15px"
                alignSelf="stretch"
            >
                <Alert variant="left-accent" alignSelf="stretch">
                    <AlertIcon alignSelf="flex-start" />
                    <Stack
                        justify="flex-start"
                        align="flex-start"
                        spacing="0px"
                        flex="1"
                    >
                        <AlertTitle alignSelf="stretch" fontSize="lg">
                            안내 사항
                        </AlertTitle>
                        <AlertDescription alignSelf="stretch">
                            <UnorderedList>
                                <ListItem>
                                    사전에 학습된 모델로 예측을 진행합니다.
                                </ListItem>
                                <ListItem>
                                    <Text as="b">
                                        반드시 아래 첨부된 예측 데이터 샘플과
                                        동일한 형식의 파일을 업로드해 주새요.
                                    </Text>
                                </ListItem>
                                <ListItem>
                                    업로드 파일 형식은 .csv와 .xlsx를
                                    지원합니다.
                                </ListItem>
                            </UnorderedList>
                        </AlertDescription>
                    </Stack>
                </Alert>

                <HStack justify="flex-start" align="flex" spacing={2}>
                    <Button
                        as="a"
                        leftIcon={<DownloadIcon data-icon="CkDownload" />}
                        variant="outline"
                        colorScheme="blue"
                        href="/documents/SNP_predict_dataset_sample.csv"
                    >
                        예측 데이터 샘플 (.csv)
                    </Button>
                    <Button
                        as="a"
                        leftIcon={<DownloadIcon data-icon="CkDownload" />}
                        variant="outline"
                        colorScheme="blue"
                        href="/documents/SNP_predict_dataset_sample.xlsx"
                    >
                        예측 데이터 샘플 (.xlsx)
                    </Button>
                </HStack>
            </VStack>

            {/* Upload Predict Data */}
            <VStack
                justify="flex-start"
                align="flex-start"
                spacing="15px"
                alignSelf="stretch"
            >
                <HStack
                    justify="flex-start"
                    align="flex-start"
                    spacing={6}
                    alignSelf="stretch"
                >
                    <Text lineHeight="1.33" fontSize="2xl" color="gray.700">
                        Predict Using Pretrained Model
                    </Text>
                    <Button
                        colorScheme="blue"
                        onClick={() => {
                            handlePredict();
                            setCurrentStep(6); // Predict 버튼 클릭
                        }}
                        isDisabled={currentStep !== 5}
                        isLoading={isPredictionProcessing}
                        loadingText="Predict"
                    >
                        Predict
                    </Button>
                </HStack>

                {/* Rounded Box */}
                <VStack
                    paddingX="20px"
                    paddingY="25px"
                    borderRadius="16px"
                    justify="center"
                    align="flex-start"
                    spacing="15px"
                    borderColor="#D7D6D6"
                    borderStartWidth="1px"
                    borderEndWidth="1px"
                    borderTopWidth="1px"
                    borderBottomWidth="1px"
                    alignSelf="stretch"
                >
                    {/* Predict File Upload */}
                    <HStack justify="flex-start" align="flex" spacing={4}>
                        <HStack justify="flex-start" align="flex" spacing={2}>
                            <Button
                                size="md"
                                colorScheme="gray"
                                onClick={() => {
                                    handleFileUpload();
                                    setCurrentStep(3); // 업로드 버튼 클릭
                                }}
                                isDisabled={
                                    !predictFile ||
                                    [3, 6, 7].includes(currentStep) ||
                                    isPredictionProcessing
                                }
                                isLoading={isFileUploading}
                                loadingText="Upload"
                            >
                                Upload
                            </Button>
                            <Input
                                variant="filled"
                                alignContent="center"
                                type="file"
                                accept=".csv,.xlsx"
                                onChange={handleFileChange}
                                isDisabled={[6, 7].includes(currentStep)}
                            />
                        </HStack>
                    </HStack>

                    {/* 전처리 결과 >> Predict 버튼 활성화와 함께 보여주기 */}
                    {currentStep === 5 &&
                        ![10, 11, 500].includes(currentStep) &&
                        !predictionError && (
                            <VStack spacing={0}>
                                <Text as="b" fontSize="sm" color="gray.700">
                                    업로드 및 전처리가 완료되었습니다. Predict
                                    버튼을 눌러 예측을 진행해 주세요.
                                </Text>
                                <HStack
                                    direction="row"
                                    justify="flex-start"
                                    align="center"
                                    spacing="10px"
                                    alignSelf="stretch"
                                >
                                    <Text fontSize="md" color="gray.700">
                                        데이터 전처리 과정에서 삭제된 행의 개수:
                                    </Text>
                                    <Text
                                        fontSize="md"
                                        color="gray.700"
                                        flex="1"
                                    >
                                        {deletedPredRowsCount}
                                    </Text>
                                </HStack>
                            </VStack>
                        )}

                    {/* Alert 렌더링 */}
                    {![2, 3, 4].includes(currentStep) && predictionError && (
                        <Alert status="error" variant="subtle">
                            <AlertIcon />
                            {predictionError}
                        </Alert>
                    )}

                    {/* Predict Result Table 섹션 */}
                    {![3].includes(currentStep) &&
                        Object.keys(predictData).length > 0 && (
                            <VStack spacing={4} align="flex-start">
                                <Text as="b" fontSize="md" color="gray.700">
                                    모델 예측 결과
                                </Text>
                                <Table variant="striped" size="sm">
                                    <Thead>
                                        <Tr>
                                            {Object.keys(predictData[0]).map(
                                                (col) => (
                                                    <Th
                                                        style={{
                                                            backgroundColor:
                                                                '#3182ce',
                                                            color: 'white',
                                                        }}
                                                        textTransform="none"
                                                        key={col}
                                                    >
                                                        {col}
                                                    </Th>
                                                )
                                            )}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {predictData.map((row, index) => (
                                            <Tr key={index}>
                                                {Object.values(row).map(
                                                    (cell, i) => (
                                                        <Td key={i}>{cell}</Td>
                                                    )
                                                )}
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <HStack
                                    justify="flex-start"
                                    align="flex"
                                    spacing={2}
                                >
                                    <Button
                                        onClick={downloadCSV}
                                        size="md"
                                        variant="outline"
                                    >
                                        Export CSV
                                    </Button>
                                    <Button
                                        onClick={downloadXLSX}
                                        size="md"
                                        variant="outline"
                                    >
                                        Export XLSX
                                    </Button>
                                </HStack>
                            </VStack>
                        )}
                </VStack>
            </VStack>
        </Stack>
    );
};
export default AncestrySnpPretrained;
