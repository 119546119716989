import { Heading, Text, VStack, Image } from '@chakra-ui/react';

export default function Preparing() {
    return (
        <VStack justifyContent={'center'} minH="60vh">
            <Image
                src="https://em-content.zobj.net/source/microsoft-3D-fluent/406/dna_1f9ec.png"
                alt="Microsoft 3D Fluent Emoji"
                boxSize="150px"
                mb={4}
            />
            <Heading>Coming Soon</Heading>
            {/* <Text>
                The Prediction Model will be available in November 2024!
            </Text> */}
        </VStack>
    );
}
