import { createBrowserRouter } from 'react-router-dom';
import Root from './components/Root';
import Home from './routes/Home';
import Portal from './routes/Portal';
import Research from './routes/Research';
import Updates from './routes/Updates';
import Contact from './routes/Contact';
import NotFound from './routes/NotFound';

import Preparing from './components/Preparing';
import Protocols from './components/Protocols';

import MethylSnapBsb from './components/MethylSnapBsb';
import MethylSnapSemen from './components/MethylSnapSemen';
import MethylSnapBodyfluid from './components/MethylSnapBodyfluid';
import MethylSnapOrgantissue from './components/MethylSnapOrgantissue';
import MethylMpsSemen from './components/MethylMpsSemen';
import MethylMpsBodyfluid from './components/MethylMpsBodyfluid';

import PredSnapBsb from './components/PredSnapBsb';
import PredSnapSemen from './components/PredSnapSemen';
import PredSnapEpidermis from './components/PredSnapEpidermis';
import PredSnapDermis from './components/PredSnapDermis';
import PredSnapMuscle from './components/PredSnapMuscle';
import PredMpsEpidermis from './components/PredMpsEpidermis';
import PredMpsDermis from './components/PredMpsDermis';
import PredMpsSemen from './components/PredMpsSemen';

import AncestryYSTR from './components/AncestryYSTR';
import AncestryYstrPretrained from './components/AncestryYSTRPretrained';
import AncestryYstrNew from './components/AncestryYSTRNew';
import AncestrySNP from './components/AncestrySNP';
import AncestrySnpPretrained from './components/AncestrySNPPretrained';
import AncestrySnpNew from './components/AncestrySNPNew';
import AncestryIntro from './components/Introduction';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <NotFound />,
        children: [
            { path: '', element: <Home /> },
            { path: 'research', element: <Research /> },
            { path: 'updates', element: <Updates /> },
            { path: 'contact', element: <Contact /> },
            {
                path: 'portal',
                element: <Portal />,
                children: [
                    {
                        path: 'methyl-snapshot-bsb',
                        element: <MethylSnapBsb />,
                    },
                    {
                        path: 'methyl-snapshot-semen',
                        element: <MethylSnapSemen />,
                    },
                    {
                        path: 'methyl-snapshot-bodyfluid',
                        element: <MethylSnapBodyfluid />,
                    },
                    {
                        path: 'methyl-snapshot-organtissue',
                        element: <MethylSnapOrgantissue />,
                    },
                    {
                        path: 'methyl-mps-semen',
                        element: <MethylMpsSemen />,
                    },
                    {
                        path: 'methyl-mps-bodyfluid',
                        element: <MethylMpsBodyfluid />,
                    },

                    {
                        path: 'age-snapshot-bsb',
                        element: <PredSnapBsb />,
                    },
                    {
                        path: 'age-snapshot-semen',
                        element: <PredSnapSemen />,
                    },
                    {
                        path: 'age-snapshot-epidermis',
                        element: <PredSnapEpidermis />,
                    },
                    {
                        path: 'age-snapshot-dermis',
                        element: <PredSnapDermis />,
                    },
                    {
                        path: 'age-snapshot-muscle',
                        element: <PredSnapMuscle />,
                    },
                    {
                        path: 'age-mps-epidermis',
                        element: <PredMpsEpidermis />,
                    },
                    {
                        path: 'age-mps-dermis',
                        element: <PredMpsDermis />,
                    },
                    {
                        path: 'age-mps-semen',
                        element: <PredMpsSemen />,
                    },

                    {
                        path: 'ancestry-intro',
                        element: <AncestryIntro />,
                    },
                    {
                        path: 'ancestry-ystr',
                        element: <AncestryYSTR />,
                        children: [
                            {
                                path: 'pretrained',
                                element: <AncestryYstrPretrained />,
                            },
                            { path: 'new', element: <AncestryYstrNew /> },
                        ],
                    },
                    {
                        path: 'ancestry-mitochondrial',
                        element: <Preparing />,
                    },
                    {
                        path: 'ancestry-snp',
                        element: <AncestrySNP />,
                        children: [
                            {
                                path: 'pretrained',
                                element: <AncestrySnpPretrained />,
                            },
                            { path: 'new', element: <AncestrySnpNew /> },
                        ],
                    },
                    {
                        path: 'ancestry-microhaplotype',
                        element: <Preparing />,
                    },
                    {
                        path: 'protocols',
                        element: <Protocols />,
                    },
                ],
            },
        ],
    },
]);

export default router;
