import React from 'react';
import {
    Box,
    Container,
    SimpleGrid,
    Text,
    VStack,
    Heading,
} from '@chakra-ui/react';

const Contact = () => {
    return (
        <Box>
            <Container maxW="1200px" mt={20} mb={40} align="center">
                <Heading m={10}>Contact Us</Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    <Box mt={20}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1159.0786142110526!2d126.99906494669159!3d37.580744189551915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca329992ab555%3A0x57746e8febe63ee7!2z7ISc7Jq47Yq567OE7IucIOyiheuhnOq1rCDrjIDtlZnroZwgMTAz!5e0!3m2!1sko!2skr!4v1701519211781!5m2!1sko!2skr"
                            width="100%"
                            height="480px"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            // loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Box>
                    <Box>
                        <VStack align="left" spacing={7} mt={20}>
                            <Box textAlign="left">
                                <Text fontWeight="bold">General Address</Text>
                                <Text>
                                    Department of Forensic Medicine, Seoul
                                    National University College of Medicine, 103
                                    Daehak-ro, Jongno-gu, Seoul 03080, Korea
                                </Text>
                                <Text>
                                    (03080) 서울특별시 종로구 대학로 103
                                    서울대학교 의과대학 법의학교실
                                </Text>
                            </Box>
                            <Box textAlign="left">
                                <Text fontWeight="bold">Office Address</Text>
                                <Text>
                                    206 Basic Research Building, Department of
                                    Forensic Medicine, Seoul National University
                                    College of Medicine, 103 Daehak-ro,
                                    Jongno-gu, Seoul 03080, Korea
                                </Text>
                                <Text>
                                    (03080) 서울특별시 종로구 대학로 103
                                    기초연구동 206호 (법의학교실 사무실)
                                </Text>
                            </Box>
                            <Box textAlign="left">
                                <Text fontWeight="bold">TEL. (Office)</Text>
                                <Text>+82-8-740-8359</Text>
                            </Box>
                            <Box textAlign="left">
                                <Text fontWeight="bold">Fax. (Office)</Text>
                                <Text>+82-2-764-8340</Text>
                            </Box>
                            <Box textAlign="left">
                                <Text fontWeight="bold">Email Address</Text>
                                <Text>hylee192@snu.ac.kr</Text>
                            </Box>
                        </VStack>
                    </Box>
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default Contact;
