import { Box, Grid } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import PortalTabs from './PortalTabs';
import LeftNav from './LeftNav';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';

export default function Root() {
    const location = useLocation();
    const isPortal = location.pathname.startsWith('/portal');
    const showLeftNav =
        isPortal && !location.pathname.startsWith('/portal/protocols');

    return (
        <Box>
            <Helmet>
                <meta charSet="utf-8" />
                <title>서울대학교 의과대학 법의학교실 - 차세대 법유전학</title>
                <meta
                    name="description"
                    content="선진기술 및 인공지능 기반의 DNA 정보 분석기술을 활용한 'DNA 융복합 지능형 용의자 추정 시스템' 개발을 통해 범죄로부터 안전한 사회를 구현합니다."
                />
                <meta
                    property="og:title"
                    content="서울대학교 의과대학 법의학교실 - 차세대 법유전학"
                />
                <meta
                    property="og:description"
                    content="선진기술 및 인공지능 기반의 DNA 정보 분석기술을 활용한 'DNA 융복합 지능형 용의자 추정 시스템' 개발을 통해 범죄로부터 안전한 사회를 구현합니다."
                />
                <meta property="og:type" content="website" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin="anonymous"
                />
                <link rel="icon" href="favicon.ico" />

                <link rel="apple-touch-icon" href="images/webclip.png" />
            </Helmet>
            <Header />
            {isPortal ? (
                <Box>
                    <PortalTabs />
                    <Grid
                        templateColumns={showLeftNav ? '280px 1fr' : '1fr'}
                        gap={4}
                    >
                        {showLeftNav && (
                            <Box position="sticky" top="120px">
                                <LeftNav />
                            </Box>
                        )}
                        <Box px={6}>
                            <Outlet />
                        </Box>
                    </Grid>
                </Box>
            ) : (
                <Box>
                    <Box>
                        <Outlet />
                    </Box>
                    <Footer />
                </Box>
            )}
        </Box>
    );
}
