import { Tabs, TabList, Tab } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function PortalTabs() {
    return (
        <Tabs mt={5} px={5}>
            <TabList>
                <Tab as={Link} to="/portal/methyl-snapshot-bsb">
                    Methylation Database
                </Tab>
                <Tab as={Link} to="/portal/age-snapshot-bsb">
                    Age Prediction
                </Tab>
                <Tab as={Link} to="/portal/ancestry-intro">
                    Ancestry Prediction
                </Tab>
                <Tab as={Link} to="/portal/protocols">
                    Protocols
                </Tab>
            </TabList>
        </Tabs>
    );
}
