import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    useColorModeValue,
    useDisclosure,
    Link,
    Image,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
export default function Header() {
    const { isOpen, onToggle } = useDisclosure();
    const linkHoverColor = useColorModeValue('blue.600', 'white');

    return (
        <Box>
            <Flex
                minH={'80px'}
                py={{ base: 2 }}
                px={{ base: 5, sm: 10 }}
                boxShadow={'xs'}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}
            >
                {/* Logo */}
                <Flex flex={{ base: 1 }} justify={{ base: 'start' }}>
                    <Box>
                        <Button
                            as="a"
                            href={'/'}
                            bg="transparent"
                            _hover={{
                                textDecoration: 'none',
                                color: 'transparent',
                            }}
                            _active={{ color: 'transparent' }}
                        >
                            <Image
                                objectFit="scale-down"
                                height={{
                                    base: '30px',
                                    sm: '30px',
                                    md: '35px',
                                    lg: '35px',
                                }}
                                p={{
                                    base: 0,
                                }}
                                src="/images/snucmfm.png"
                            />
                        </Button>
                    </Box>
                </Flex>

                {/* Nax Mobile Btn */}
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    justify={'flex-end'}
                    display={{ base: 'flex', md: 'none' }}
                >
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? (
                                <CloseIcon w={3} h={3} />
                            ) : (
                                <HamburgerIcon w={5} h={5} />
                            )
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>

                <Flex
                    display={{ base: 'none', md: 'flex' }}
                    ml={10}
                    justify={'flex-end'}
                >
                    <Stack direction={'row'} spacing={4}>
                        <Box
                            as="a"
                            p={2}
                            href={'/research'}
                            fontSize={'lg'}
                            fontWeight={500}
                            _hover={{
                                textDecoration: 'none',
                                color: linkHoverColor,
                            }}
                        >
                            Research
                        </Box>
                        <Link
                            as="a"
                            p={2}
                            href={'/portal/methyl-snapshot-bsb'}
                            fontSize={'lg'}
                            fontWeight={500}
                            _hover={{
                                textDecoration: 'none',
                                color: linkHoverColor,
                            }}
                            isExternal
                        >
                            Portal
                            <ExternalLinkIcon mx="2px" m="7px" p="2px" />
                        </Link>
                        <Box
                            as="a"
                            p={2}
                            href={'/updates'}
                            fontSize={'lg'}
                            fontWeight={500}
                            _hover={{
                                textDecoration: 'none',
                                color: linkHoverColor,
                            }}
                        >
                            Updates
                        </Box>
                        <Box
                            as="a"
                            p={2}
                            href={'/contact'}
                            fontSize={'lg'}
                            fontWeight={500}
                            _hover={{
                                textDecoration: 'none',
                                color: linkHoverColor,
                            }}
                        >
                            Contact Us
                        </Box>
                    </Stack>
                </Flex>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
}

const DesktopNav = () => {
    const linkHoverColor = useColorModeValue('blue.600', 'white');

    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Box
                        as="a"
                        p={2}
                        href={navItem.href ?? '#'}
                        fontSize={'lg'}
                        fontWeight={500}
                        _hover={{
                            textDecoration: 'none',
                            color: linkHoverColor,
                        }}
                    >
                        {navItem.label}
                    </Box>
                </Box>
            ))}
        </Stack>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('gray.50', 'gray.800')}
            px={'50px'}
            py={4}
            display={{ md: 'none' }}
            borderBottom="1px"
            borderBottomColor={'gray.100'}
        >
            <Flex direction="column" textAlign={'right'}>
                {NAV_ITEMS.map((navItem) => (
                    <MobileNavItem key={navItem.label} {...navItem} />
                ))}
            </Flex>
        </Stack>
    );
};

const MobileNavItem = ({ label, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={onToggle}>
            <Box
                py={2}
                as="a"
                href={href ?? '#'}
                justifyContent="space-between"
                alignItems="center"
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <Text fontWeight={600}>{label}</Text>
            </Box>
        </Stack>
    );
};

const NAV_ITEMS = [
    {
        label: 'Research',
        href: '/research',
    },
    {
        label: 'Portal',
        href: '/portal/methyl-snapshot-bsb',
    },
    {
        label: 'Updates',
        href: '/updates',
    },
    {
        label: 'Contact Us',
        href: '/contact',
    },
];
