import { useState } from 'react';
import {
    Button,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Stack,
    VStack,
    HStack,
    Progress,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    ListItem,
    UnorderedList,
} from '@chakra-ui/react';
import axios from 'axios';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const AncestryYstrNew = () => {
    const [file, setFile] = useState(null);
    const [isButtonLoading, setIsButtonLoading] = useState(false); // Train 버튼 로딩 상태
    const [trainTableData, setTrainTableData] = useState([]);
    const [predTableData, setPredTableData] = useState([]);
    const [matchingCols, setMatchingCols] = useState([]);
    const [nonMatchingCols, setNonMatchingCols] = useState([]);
    const [deletedRowsCount, setDeletedRowsCount] = useState(null);
    const [deletedPredRowsCount, setDeletedPredRowsCount] = useState(null);
    const [isUploadComplete, setIsUploadComplete] = useState(false);

    const [isTrainUploading, setTrainUploading] = useState(false); // 로딩 상태 추가
    const [isTraining, setIsTraining] = useState(false);
    const [prima_train_time, setPrimaTrainTime] = useState(null);
    const [secondo_train_time, setSecondoTrainTime] = useState(null);
    const [total_train_time, setTotalTrainTime] = useState(null);
    const [classificationReport, setClassificationReport] = useState({});
    const [isSampleDownloadReady, setIsSampleDownloadReady] = useState(false); // 샘플 다운로드 상태
    const [isPredictionFileUploadReady, setIsPredictionFileUploadReady] =
        useState(false); // 예측 파일 업로드 상태
    const [unmatchedCols, setUnmatchedCols] = useState([]);
    const [predictData, setPredictData] = useState([]); // 예측 결과 데이터
    const [predSampleCsvColumns, setPredSampleCsvColumns] = useState([]);
    const [predictFile, setPredictFile] = useState(null);
    const [isPredictionUploading, setIsPredictionUploading] = useState(false);
    const [isPredictionProcessing, setIsPredictionProcessing] = useState(false);
    const [predictionError, setPredictionError] = useState(''); // 오류 메시지 상태
    const [isPredictButtonDisabled, setIsPredictButtonDisabled] =
        useState(true); // Predict 버튼 활성화 여부
    const [currentStep, setCurrentStep] = useState(1); // 초기 단계는 1로 설정

    // Handle Train file change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setCurrentStep(2); // 새로운 학습 파일 선택
    };

    // Handle Train file upload
    const handleTrainUpload = async () => {
        if (!file) {
            alert('Please upload a file first');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            setTrainUploading(true); // 로딩 시작
            setCurrentStep(4); //업로드 시작
            const response = await axios.post(
                'https://forensicdna.snu.ac.kr/api/ystr-new-train-upload/',
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );
            setTrainTableData(response.data.data);
            setMatchingCols(response.data.matching_columns);
            setNonMatchingCols(response.data.non_matching_columns);
            setDeletedRowsCount(response.data.deleted_rows_count);
            setIsUploadComplete(true); // 파일 업로드 및 전처리 완료
        } catch (error) {
            console.error(error);
            alert('Error uploading file');
        } finally {
            setTrainUploading(false); // 로딩 종료
            setCurrentStep(5); // 업로드 및 전처리 완료
        }
    };

    // Handle model training
    const handleTrain = async (modelType) => {
        setIsButtonLoading(true); // Train 버튼 로딩 시작
        setIsTraining(true); // 학습 중 표시
        let total_time = 0;
        try {
            setCurrentStep(7); //train 시작
            const response = await axios.post(
                'https://forensicdna.snu.ac.kr/api/ystr-new-train/',
                {
                    model_type: modelType,
                    data: trainTableData, // final_dataset 데이터를 여기에 포함하여 전송
                }
            );
            const primaTrainTime = response.data?.prima_train_time || 0;
            const secondoTrainTime = response.data?.secondo_train_time || 0;
            setPrimaTrainTime(primaTrainTime.toFixed(2));
            setSecondoTrainTime(secondoTrainTime.toFixed(2));
            total_time = primaTrainTime + secondoTrainTime;
            setTotalTrainTime(total_time.toFixed(2));
            setClassificationReport(response.data.classification_report);
            setPredSampleCsvColumns(response.data.pred_sample_csv_columns);
        } catch (error) {
            console.error('Training error:', error);
        } finally {
            setIsButtonLoading(false); // Train 버튼 로딩 종료
            setIsTraining(false); // 학습 중 표시 종료
            setIsSampleDownloadReady(true); // 예측용 샘플 파일 다운로드 가능
            setCurrentStep(8); //train 완료
        }
    };

    // 모델 학습 결과 report의 행과 열을 전환하고 label을 매핑하여 보여주기
    const transformedReport = {};
    if (classificationReport && Object.keys(classificationReport).length > 0) {
        Object.entries(classificationReport).forEach(([label, metrics]) => {
            Object.entries(metrics).forEach(([metric, value]) => {
                if (!transformedReport[metric]) {
                    transformedReport[metric] = {}; // 새로운 메트릭 행 생성
                }
                transformedReport[metric][label] = value; // 전환된 행과 열에 값 할당
            });
        });
    }

    // 샘플 Predict 파일 다운로드
    const handleSampleDownload = () => {
        if (!predSampleCsvColumns || predSampleCsvColumns.length === 0) return;
        const columns = ['Sample ID', ...predSampleCsvColumns];
        const sampleData = columns.reduce(
            (acc, col) => ({ ...acc, [col]: '' }),
            {}
        );
        const csvContent = [
            columns.join(','), // 첫 행에 컬럼명 추가
            Object.values(sampleData).join(','), // 빈 데이터 행 추가
        ].join('\n');

        const blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;',
        });
        FileSaver.saveAs(blob, 'prediction_input_sample.csv');
    };

    // Handle prediction file change
    const handlePredictionFileChange = (e) => {
        setPredictFile(e.target.files[0]);
        setCurrentStep(9); // 새로운 추론 파일 선택
    };

    // 샘플 파일 업로드 핸들러
    const handlePredictionFileUpload = async (e) => {
        if (!predictFile) {
            alert('Please select a csv or xlsx file first');
            return;
        }

        const formData = new FormData();
        formData.append('file', predictFile);

        try {
            setCurrentStep(11); // 업로드 시작
            setIsPredictionUploading(true);
            const response = await axios.post(
                'https://forensicdna.snu.ac.kr/api/ystr-new-pred-upload/',
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );

            const unmatchedCols = response.data.unmatched_columns;
            const predData = response.data.pred_data;

            if (unmatchedCols.length > 0) {
                // 컬럼 구성 오류 처리
                setPredictionError(
                    `컬럼 구성이 달라 모델 예측을 수행할 수 없습니다. 일치하지 않는 컬럼: ${unmatchedCols.join(
                        ', '
                    )}. 샘플 파일의 컬럼 구성과 일치하도록 수정해주세요.`
                );
                setUnmatchedCols(unmatchedCols);
                setIsPredictButtonDisabled(true); // Predict 버튼 비활성화
                setCurrentStep(500); // 업로드 에러
            } else if (!predData || predData.length === 0) {
                // 예측할 데이터가 없는 경우 처리
                setPredictionError(
                    '예측할 데이터가 없습니다. 모든 행이 비어 있습니다.'
                );
                setIsPredictButtonDisabled(true); // Predict 버튼 비활성화
                setCurrentStep(500); // 업로드 에러
            } else {
                // 데이터가 올바른 경우
                setPredictionError(''); // 오류 메시지 초기화
                setUnmatchedCols([]);
                setPredTableData(predData);
                setDeletedPredRowsCount(response.data.deleted_rows_count);
                setIsPredictionFileUploadReady(true);
                setIsPredictButtonDisabled(false); // Predict 버튼 활성화
                setPredictFile(null); // predict input file 리셋
            }
        } catch (error) {
            console.error('Error checking prediction file columns:', error);
            setPredictionError(
                '파일 업로드 중 오류가 발생했습니다. 다시 시도해 주세요.'
            );
            setCurrentStep(500); // 업로드 에러
        } finally {
            setIsPredictionUploading(false);
            setCurrentStep(12); // 업로드 완료
        }
    };

    // 모델 예측
    const handlePredict = async () => {
        try {
            setIsPredictionProcessing(true);
            setCurrentStep(14); // Predict 시작
            const response = await axios.post(
                'https://forensicdna.snu.ac.kr/api/ystr-new-predict/',
                {
                    data: predTableData,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setPredictData(response.data.prediction);
        } catch (error) {
            console.error('Prediction error:', error);
        } finally {
            setIsPredictionProcessing(false);
            setCurrentStep(15); // Predict 완료
        }
    };

    // 예측 결과 파일로 다운로드
    // CSV
    const downloadCSV = () => {
        const headers = Object.keys(predictData[0]).join(','); // 헤더
        const rows = predictData
            .map((row) => Object.values(row).join(',')) // 데이터 행
            .join('\n');
        const csvContent = [headers, rows].join('\n'); // 전체 CSV 콘텐츠
        const blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;',
        });
        FileSaver.saveAs(blob, 'YSTR_prediction_results.csv');
    };
    // XLSX
    const downloadXLSX = () => {
        const worksheet = XLSX.utils.json_to_sheet(predictData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Predictions');
        const xlsxData = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        const blob = new Blob([xlsxData], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, 'YSTR_prediction_results.xlsx');
    };

    return (
        <Stack
            justify="flex-start"
            align="flex-start"
            spacing="30px"
            overflow="hidden"
            // width="1231px"
            maxWidth="1080px"
            background="#FFFFFF"
        >
            {/* 헤딩 - alert */}
            <Stack
                justify="flex-start"
                align="flex-start"
                spacing="15px"
                alignSelf="stretch"
            >
                <Alert variant="left-accent" alignSelf="stretch">
                    <AlertIcon alignSelf="flex-start" />
                    <Stack
                        justify="flex-start"
                        align="flex-start"
                        spacing="0px"
                        flex="1"
                    >
                        <AlertTitle alignSelf="stretch" fontSize="lg">
                            안내 사항
                        </AlertTitle>
                        <AlertDescription alignSelf="stretch">
                            <UnorderedList>
                                <ListItem>
                                    <Text>
                                        사용자가 모델 학습에 활용할 데이터를
                                        업로드 하면, 레퍼런스 데이터와 병합해
                                        모델 학습에 사용합니다.
                                    </Text>
                                    <Text as="b">
                                        이때, 사용자의 데이터 중 레퍼런스
                                        데이터와 매칭되는 컬럼의 데이터만 학습에
                                        활용됩니다.
                                    </Text>
                                    <VStack
                                        align="flex-start"
                                        spacing={0}
                                        px={4}
                                    >
                                        <Text as="i">
                                            * 예를 들어, 사용자 데이터가
                                            레퍼런스 데이터 A, B 컬럼에 대해서만
                                            매칭된다면, 레퍼런스와 사용자
                                            데이터의 A, B 컬럼 값을 병합해 학습
                                            데이터셋을 생성합니다.
                                        </Text>
                                        <Text>
                                            ** 레퍼런스 데이터 컬럼 구성은 Step
                                            1의 샘플 파일을 참고해 주세요.
                                        </Text>
                                        <Text>
                                            ** 컬럼 일치 여부는 영문 대소문자를
                                            구분하지 않습니다.
                                        </Text>
                                    </VStack>
                                </ListItem>
                                <ListItem>
                                    <Text as="b">
                                        사용자 데이터에는 반드시 'category'
                                        컬럼이 포함되어야 합니다. 'category'
                                        컬럼에는 예측되는 지역명(3가지)을 숫자로
                                        대치하여 기입해 주세요.
                                    </Text>
                                    <VStack
                                        align="flex-start"
                                        spacing={0}
                                        px={4}
                                    >
                                        <Text as="i">
                                            - Northeast : "0"으로 기입
                                        </Text>
                                        <Text as="i">
                                            - Southeast : "1"으로 기입
                                        </Text>
                                        <Text as="i">
                                            - Southwest : "2"으로 기입
                                        </Text>
                                    </VStack>
                                </ListItem>
                                <ListItem>
                                    업로드 파일 형식은 .csv와 .xlsx를
                                    지원합니다.
                                </ListItem>
                            </UnorderedList>
                        </AlertDescription>
                    </Stack>
                </Alert>
            </Stack>

            {/* Step 1 - Upload Training Data */}
            <VStack
                justify="flex-start"
                align="flex-start"
                spacing="15px"
                alignSelf="stretch"
            >
                <Text lineHeight="1.33" fontSize="2xl" color="gray.700">
                    Step 1. Upload Training Data
                </Text>
                {/* Rounded Box */}
                <VStack
                    px={6}
                    py={6}
                    borderRadius="16px"
                    justify="center"
                    align="flex-start"
                    spacing={4}
                    borderColor="#D7D6D6"
                    borderWidth="1px"
                    alignSelf="stretch"
                    background="white"
                >
                    {/* Upload Input File */}
                    <HStack justify="flex-start" align="flex" spacing={4}>
                        <HStack justify="flex-start" align="flex" spacing={2}>
                            <Button
                                onClick={() => {
                                    handleTrainUpload();
                                    setCurrentStep(3);
                                }}
                                colorScheme="blue"
                                isLoading={isTrainUploading}
                                loadingText="Upload"
                                isDisabled={
                                    isTraining ||
                                    !file ||
                                    [6, 9, 10, 11, 13, 14].includes(currentStep)
                                }
                            >
                                Upload
                            </Button>
                            <Input
                                variant="filled"
                                alignContent="center"
                                type="file"
                                accept=".csv,.xlsx"
                                onChange={handleFileChange}
                            />
                        </HStack>
                        {/* 다운로드 파일 생성 및 경로 지정 */}
                        <Button
                            as="a"
                            variant="link"
                            colorScheme="blue"
                            href="/documents/YSTR_train_dataset_sample.csv"
                        >
                            Download Sample File (CSV)
                        </Button>
                    </HStack>

                    {/* Table 섹션 >> Stack을 테이블로 변경 */}
                    {trainTableData.length > 0 ? (
                        <Box
                            maxHeight="700px"
                            maxWidth="100%"
                            overflowY="auto"
                            overflowX="auto"
                            border="1px solid #E1E8ED"
                        >
                            <VStack
                                py={2}
                                paddingX="10px"
                                justify="flex-start"
                                align="flex-start"
                                spacing="7px"
                                alignSelf="stretch"
                            >
                                <HStack
                                    direction="row"
                                    justify="flex-start"
                                    align="center"
                                    spacing="10px"
                                    alignSelf="stretch"
                                >
                                    <Text as="b" fontSize="md" color="gray.700">
                                        레퍼런스와 매칭된 컬럼 |
                                    </Text>
                                    <Text
                                        fontSize="md"
                                        color="gray.700"
                                        flex="1"
                                    >
                                        {matchingCols.length > 0
                                            ? matchingCols.join(', ')
                                            : 'None'}
                                    </Text>
                                </HStack>
                                <HStack
                                    direction="row"
                                    justify="flex-start"
                                    align="center"
                                    spacing="10px"
                                    alignSelf="stretch"
                                >
                                    <Text as="b" fontSize="md" color="gray.700">
                                        레퍼런스와 매칭되지 않은 컬럼 (학습에서
                                        제외) |
                                    </Text>
                                    <Text
                                        fontSize="md"
                                        color="gray.700"
                                        flex="1"
                                    >
                                        {nonMatchingCols.length > 0
                                            ? nonMatchingCols.join(', ')
                                            : 'None'}
                                    </Text>
                                </HStack>
                                <HStack
                                    direction="row"
                                    justify="flex-start"
                                    align="center"
                                    spacing="10px"
                                    alignSelf="stretch"
                                >
                                    <Text as="b" fontSize="md" color="gray.700">
                                        데이터 전처리 과정에서 삭제된 행의 개수
                                        |
                                    </Text>
                                    <Text
                                        fontSize="md"
                                        color="gray.700"
                                        flex="1"
                                    >
                                        {deletedRowsCount}
                                    </Text>
                                </HStack>
                                <VStack py={2} align="flex-start">
                                    <Text as="b" fontSize="md" color="gray.700">
                                        업로드 데이터셋 전처리 결과
                                    </Text>
                                    <Table variant="striped" size="sm">
                                        <Thead>
                                            <Tr>
                                                {Object.keys(
                                                    trainTableData[0]
                                                ).map((col) => (
                                                    <Th
                                                        style={{
                                                            backgroundColor:
                                                                '#3182ce',
                                                            color: 'white',
                                                            position: 'sticky',
                                                            top: 0,
                                                            zIndex: 1,
                                                        }}
                                                        textTransform="none"
                                                        width="auto"
                                                        key={col}
                                                    >
                                                        {col}
                                                    </Th>
                                                ))}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {trainTableData.map(
                                                (row, index) => (
                                                    <Tr key={index}>
                                                        {Object.values(row).map(
                                                            (cell, i) => (
                                                                <Td key={i}>
                                                                    {cell}
                                                                </Td>
                                                            )
                                                        )}
                                                    </Tr>
                                                )
                                            )}
                                        </Tbody>
                                    </Table>
                                </VStack>
                            </VStack>
                        </Box>
                    ) : null}
                </VStack>
            </VStack>

            {/* Step 2 - Train Your Model */}
            <VStack
                justify="flex-start"
                align="flex-start"
                spacing="15px"
                alignSelf="stretch"
            >
                {/* Title & Train Button */}
                <HStack
                    justify="flex-start"
                    align="flex-end"
                    alignSelf="stretch"
                    spacing={6}
                >
                    <Text lineHeight="1.33" fontSize="2xl" color="gray.700">
                        Step 2. Train Your Model
                    </Text>
                    <Button
                        size="md"
                        colorScheme="blue"
                        onClick={() => {
                            handleTrain('basic');
                            setCurrentStep(6); //train 버튼 클릭
                        }}
                        isLoading={isButtonLoading} // 버튼 로딩 상태 적용
                        loadingText="Training..."
                        isDisabled={!isUploadComplete || currentStep !== 5} // 업로드 완료 후 Step 1에서 새 데이터가 업로드되지 않으면 계속 비활성화
                    >
                        Start Training
                    </Button>
                </HStack>
                {/* Rounded Box */}
                <VStack
                    px={6}
                    py={6}
                    justify="center"
                    align="flex-start"
                    // spacing={4}
                    borderRadius="16px"
                    borderColor="#D7D6D6"
                    borderWidth="1px"
                    alignSelf="stretch"
                >
                    {/* Start Training Alert Message */}
                    {currentStep === 5 && (
                        <Text fontSize="lg" color="black">
                            학습 데이터가 준비되었습니다. 'Start Training'
                            버튼을 눌러 학습을 시작해 주세요.
                        </Text>
                    )}
                    {/* Training Progress Bar */}
                    {isTraining && (
                        <VStack
                            justify="flex-start"
                            align="flex-start"
                            spacing="12px"
                            width="50%"
                        >
                            <Progress width="100%" size="md" isIndeterminate />
                            <Text fontSize="lg" color="black">
                                모델 학습중입니다 ...
                            </Text>
                        </VStack>
                    )}

                    {/* Table 섹션 >> transformedReport 데이터를 테이블로 렌더링 */}
                    {![3, 4, 5, 6, 7].includes(currentStep) &&
                        Object.keys(transformedReport).length > 0 && (
                            <VStack align="flex-start">
                                <Text as="b" fontSize="md" color="gray.700">
                                    모델 학습 결과 - 성능 지표
                                </Text>
                                <Table variant="striped" size="sm">
                                    <Thead>
                                        <Tr>
                                            <Th
                                                style={{
                                                    backgroundColor: '#3182ce',
                                                    color: 'white',
                                                }}
                                                textTransform="none"
                                            >
                                                Metric
                                            </Th>
                                            {Object.keys(
                                                transformedReport[
                                                    Object.keys(
                                                        transformedReport
                                                    )[0]
                                                ]
                                            ).map((label) => (
                                                <Th
                                                    style={{
                                                        backgroundColor:
                                                            '#3182ce',
                                                        color: 'white',
                                                    }}
                                                    textTransform="none"
                                                    key={label}
                                                >
                                                    {label}
                                                </Th>
                                            ))}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {Object.entries(transformedReport).map(
                                            ([metric, values], idx) => (
                                                <Tr key={idx}>
                                                    <Td>{metric}</Td>
                                                    {Object.values(values).map(
                                                        (value, valueIdx) => (
                                                            <Td key={valueIdx}>
                                                                {typeof value ===
                                                                'number'
                                                                    ? value.toFixed(
                                                                          4
                                                                      )
                                                                    : value}
                                                            </Td>
                                                        )
                                                    )}
                                                </Tr>
                                            )
                                        )}
                                    </Tbody>
                                </Table>
                            </VStack>
                        )}
                    {total_train_time &&
                        ![3, 4, 5, 6, 7].includes(currentStep) && (
                            <Text mt={4}>
                                학습 소요 시간: {total_train_time} 초
                            </Text>
                        )}
                </VStack>
            </VStack>

            {/* Step 3 - Predict Your Model */}
            <VStack
                justify="flex-start"
                align="flex-start"
                spacing="15px"
                alignSelf="stretch"
            >
                {/* Title & Predict Button */}
                <HStack
                    justify="flex-start"
                    align="flex-end"
                    alignSelf="stretch"
                    spacing={6}
                >
                    <Text lineHeight="1.33" fontSize="2xl" color="gray.700">
                        Step 3. Predict Using Trained Model
                    </Text>
                    <Button
                        colorScheme="blue"
                        onClick={() => {
                            handlePredict();
                            setCurrentStep(13); // Predict 버튼 클릭
                        }}
                        isDisabled={currentStep !== 12}
                        isLoading={isPredictionProcessing}
                        loadingText="Predict"
                    >
                        Predict
                    </Button>
                </HStack>

                {/* Rounded Box */}
                <VStack
                    paddingX="20px"
                    paddingY="25px"
                    borderRadius="16px"
                    justify="center"
                    align="flex-start"
                    spacing="15px"
                    borderColor="#D7D6D6"
                    borderStartWidth="1px"
                    borderEndWidth="1px"
                    borderTopWidth="1px"
                    borderBottomWidth="1px"
                    alignSelf="stretch"
                >
                    {/* Predict File Upload */}
                    <HStack justify="flex-start" align="flex" spacing={4}>
                        <HStack justify="flex-start" align="flex" spacing={2}>
                            <Button
                                size="md"
                                colorScheme="blue"
                                onClick={() => {
                                    handlePredictionFileUpload();
                                    setCurrentStep(10); // 업로드 버튼 클릭
                                }}
                                isDisabled={
                                    !predictFile ||
                                    [3, 4, 5, 6, 7, 10, 13, 14].includes(
                                        currentStep
                                    ) ||
                                    isPredictionProcessing
                                }
                                isLoading={isPredictionUploading}
                                loadingText="Upload"
                            >
                                Upload
                            </Button>
                            <Input
                                variant="filled"
                                alignContent="center"
                                type="file"
                                accept=".csv,.xlsx"
                                onChange={handlePredictionFileChange}
                                isDisabled={
                                    !isSampleDownloadReady ||
                                    [3, 4, 5, 6, 7, 13, 14].includes(
                                        currentStep
                                    )
                                }
                            />
                        </HStack>
                        <Button
                            variant="link"
                            colorScheme="blue"
                            isDisabled={!isSampleDownloadReady}
                            onClick={handleSampleDownload}
                        >
                            Download Sample File (CSV)
                        </Button>
                    </HStack>

                    {/* 전처리 결과 >> Predict 버튼 활성화와 함께 보여주기 */}
                    {currentStep === 12 &&
                        ![10, 11, 500].includes(currentStep) &&
                        !predictionError && (
                            <VStack spacing={0}>
                                <Text as="b" fontSize="sm" color="gray.700">
                                    업로드 및 전처리가 완료되었습니다. Predict
                                    버튼을 눌러 예측을 진행해 주세요.
                                </Text>
                                <HStack
                                    direction="row"
                                    justify="flex-start"
                                    align="center"
                                    spacing="10px"
                                    alignSelf="stretch"
                                >
                                    <Text fontSize="md" color="gray.700">
                                        데이터 전처리 과정에서 삭제된 행의 개수:
                                    </Text>
                                    <Text
                                        fontSize="md"
                                        color="gray.700"
                                        flex="1"
                                    >
                                        {deletedPredRowsCount}
                                    </Text>
                                </HStack>
                            </VStack>
                        )}

                    {/* Alert 렌더링 */}
                    {![3, 4, 5, 6, 7, 8, 9, 10, 11].includes(currentStep) &&
                        predictionError && (
                            <Alert status="error" variant="subtle">
                                <AlertIcon />
                                {predictionError}
                            </Alert>
                        )}

                    {/* Predict Result Table 섹션 */}
                    {![3, 4, 5, 6, 7].includes(currentStep) &&
                        Object.keys(predictData).length > 0 && (
                            <VStack spacing={4} align="flex-start">
                                <Text as="b" fontSize="md" color="gray.700">
                                    모델 예측 결과
                                </Text>
                                <Table variant="striped" size="sm">
                                    <Thead>
                                        <Tr>
                                            {Object.keys(predictData[0]).map(
                                                (col) => (
                                                    <Th
                                                        style={{
                                                            backgroundColor:
                                                                '#3182ce',
                                                            color: 'white',
                                                        }}
                                                        textTransform="none"
                                                        key={col}
                                                    >
                                                        {col}
                                                    </Th>
                                                )
                                            )}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {predictData.map((row, index) => (
                                            <Tr key={index}>
                                                {Object.values(row).map(
                                                    (cell, i) => (
                                                        <Td key={i}>{cell}</Td>
                                                    )
                                                )}
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <HStack
                                    justify="flex-start"
                                    align="flex"
                                    spacing={2}
                                >
                                    <Button
                                        onClick={downloadCSV}
                                        size="md"
                                        variant="outline"
                                    >
                                        Export CSV
                                    </Button>
                                    <Button
                                        onClick={downloadXLSX}
                                        size="md"
                                        variant="outline"
                                    >
                                        Export XLSX
                                    </Button>
                                </HStack>
                            </VStack>
                        )}
                </VStack>
            </VStack>
        </Stack>
    );
};

export default AncestryYstrNew;
